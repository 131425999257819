import React, { useEffect, useState } from 'react'
import { RarityPreset } from '../utilities/rarities'
import { Container, Row, Col, Button, Table, ProgressBar, Form, Card} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Web3Utils from 'web3-utils';
import { fetchOsCollection,fetchContractInfo, sleep, useLocalStorage, fetchCybbRanks  } from '../utilities/helpers';
// import { OpenSeaPort, Network } from 'opensea-js'
import { OpenSeaPort, Network, WyvernProtocol } from '@sdatomic/opensea-js'
import { WalletManager } from '.';
import HDWalletProvider from '@truffle/hdwallet-provider'
import Countdown from 'react-countdown';
import useStateRef from 'react-usestateref';
import axios from "axios";
import * as selenium from 'selenium-webdriver';
import * as chrome from 'selenium-webdriver/chrome.js';
// import * as chromedriver from 'chromedriver';

const TOTAL_EVENTS = 20
const OSNewListings = "https://api.opensea.io/api/v1/events?only_opensea=false&event_type=created"
const OSNewSales = "https://api.opensea.io/api/v1/events?only_opensea=false&event_type=successful"
const ModuleListingsURL = "https://api.modulenft.xyz/api/v1/opensea/listings/listings"

const WETH = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
const version = "0.0.8 beta"

const MAXTARGETS = 10000

function range(start, end) {
    return Array(end - start + 1).fill(0).map((_, idx) => start + idx)
}

type TimeBump = {
    name: string,
    seconds: number
} 

const useForceUpdate = () => {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}


const TIMEBUMPS = [
    {name:"1 hour", seconds: 3600},
    {name:"2 hours", seconds : 7200},
    {name:"3 hours", seconds : 10800},
    {name:"4 hours", seconds : 14400},
    {name:"5 hours", seconds : 18000},
    {name:"6 hours", seconds : 21600},
    {name:"12 hours", seconds : 43200},
    {name:"1 day", seconds: 86400},
    {name:"2 days", seconds: 172800},
    {name:"3 days", seconds: 259200},
    {name:"1 week", seconds: 604800},
    {name:"2 weeks", seconds: 1209600},
    {name:"1 month", seconds: 2630000},
    {name:"2 months", seconds: 5260000},
    {name:"3 months", seconds: 7890000}
]

export const BidBotApp = (props) => {
    const forceUpdate = useForceUpdate();
    const userAccount = props.account;
    const web3 = props.web3;
    const { ethereum } = window as any;
    ethereum.on('accountsChanged', function (accounts) {
        setAddress(userAccount)
      });

    const {keyringController} = props
    const [address, setAddress, getCurrentAddress] = useLocalStorage("address", userAccount)
    const [balance, setBalance, getCurrentBalance] = useLocalStorage("balance", '');
    const [isPk, setIsPk, getCurrentIsPk] = useLocalStorage("isPk", false);
    const [collection, setCollection] = useState("");
    const [contract, setContract] = useState("");
    const [collectionInfo, setCollectionInfo]  = useState({} as any);
    const [collectionRanks, setCollectionRanks] = useState(null);
    const [traitFilters, setTraitFilters] = useState([])
    const [bidPrice, setBidPrice] = useState("");
    const [bidding, setBidding] = useState(false);
    const [signing, setSigning, signingRef] = useStateRef(false);
    const [sending, setSending, sendingRef] = useStateRef(false);
    const [rankOrigin, setRankOrigin, getCurrentRankOrigin] = useLocalStorage('rankOrigin', 'RSniffer')
    const [traitNorm, setTraitNorm, getCurrentTraitNorm] = useLocalStorage('traitNorm', true);
    const [traitCount, setTraitCount, getCurrentTraitCount] = useLocalStorage('traitCount', false);


    const [timeBump, setTimeBump] = useState<TimeBump>({
        name:"3 hours", seconds : 10800
    });
    const [selectionType, setSelectionType] = useState("tokenRange")
    const [resetConfirm, setResetConfirm] = useState(false);
    const [signedBids, setSignedBids] = useState([])
    const [sentBids, setSentBids] = useState([])
    const [tokenCount, setTokenCount] = useState(0);
    const [targetedTokens, setTargetedTokens] = useState([])
    const [showWalletManager, setShowWalletManager] = useState(false);
    const [sigExpirationTime, setSigExpirationTime] = useState(0);
    const [localDelFirst, setLocalDelFirst] = useLocalStorage('localDelFirst', false);

    const getCollectionSlug = async ()=> {
        const contractInfo = await fetchContractInfo(contract)
        if (contractInfo && contractInfo['collection']) {
            return contractInfo['collection']['slug']
        }
        return null
    }

    const submitCollection = async ()=> {
        let slug;
        if (!collection) {
            slug = await getCollectionSlug();
            setCollection(slug);
        } else {
            slug = collection
        }
        const stats = await fetchOsCollection(slug)
        if (stats && stats['collection']) {
            setCollectionInfo(stats['collection'])

        } else {
            toast.error("error fetching collection info")
        }
    }


    const changeSelectionType = async (input) => {
        setSelectionType(input);
        if (input === 'byTrait') {
            if (!collectionRanks) {
                await loadRankingsCYBB(collectionInfo);
            }
            if (traitFilters.length === 0 && collectionRanks) {
                addNewTraitFilter();
            }
            if (!collectionRanks) {
                return
            }
            
        }

        if (input === 'rankRange' && !collectionRanks) {
            await loadRankingsCYBB(collectionInfo);
        } 
    }

    const reloadCollection = async () => {
        const stats = await fetchOsCollection(collection)
        if (stats && stats['collection']) {
            setCollectionInfo(stats['collection'])
            // loadRankings()
        } else {
            toast.error("error fetching collection info")
        }    
        forceUpdate()
    }

    const enterOnSlug = ( key) => {
        if (key.charCode === 13) {
            submitCollection()
        }
    }

    const loadRankingsCYBB = async (info=collectionInfo) => {
        const contract = info.primary_asset_contracts[0].address
        const rankings = await fetchCybbRanks(collection, contract)
        if (rankings.status === 'ok') {
            setCollectionRanks(rankings);
            console.log(rankings);
        } else toast.warn('cybb rarities not available')
    }

    const addNewTraitFilter = () => {
        setTraitFilters((current) => [...current, {trait_type: 'trait_type', value: 'value'}])
        forceUpdate()
    }
    
    const getTokenRank = (tokenId) => {
        let token = null
        switch(rankOrigin) {
            case 'RSniffer':
                token = collectionRanks.rankings.find(t => Number(t[0]) === Number(tokenId))
                return token ?token[1] : undefined;
            case 'CYBB': 
                token = collectionRanks.rankings.find(t => Number(t[0]) === Number(tokenId))
                return token ? token[1]: undefined;
        }
    }

    const modifyTraitFilter = (index, modifiedFilter) => {
        const newFilters = traitFilters
        newFilters[index] = modifiedFilter
        setTraitFilters(newFilters)

        const traitTypeProp = collectionRanks.baseAttributeProps.find(baseAtt => baseAtt.name === modifiedFilter.trait_type)
        const traitTypeIndex = collectionRanks.baseAttributeProps.findIndex(baseAtt => baseAtt.name === modifiedFilter.trait_type)
        const traitValuePropIndex = traitTypeProp.values.findIndex(baseValue => baseValue[0] === modifiedFilter.value)
        const matchingTokens = collectionRanks.rankings.filter(t => t[traitTypeIndex + 2] === traitValuePropIndex).map(t => t[0])
        console.log(matchingTokens)
        setTokenCount(matchingTokens.length)
        setTargetedTokens(matchingTokens);

        forceUpdate()
    }

    const sanitizeRankRange = (input) => {
        if (input === ''){
            return
        }
        const [start, end] = input.split(':')
        if (!start || !end) {
            return
        }
        if (Number(end) > Number(collectionInfo.stats.count)){
            toast.warn('end of range is out of the bounds of the colletion')
            return
        }
        const targetedTokenIds = collectionRanks.rankings.slice(start-1, end).map(t => t[0])
        const tCount = targetedTokenIds.length

        if (tCount > MAXTARGETS){
            toast.warn(`too many tokens targeted, try less than ${MAXTARGETS}`)
            return
        }
        setTokenCount(tCount)
        setTargetedTokens(targetedTokenIds);
        forceUpdate();
    }

    const sanitizeTokenRange = (input) => {
        if (input === ''){
            return
        }
        const [start, end] = input.split(':')
        if (!start || !end || Number.isNaN(Number(start)) || Number.isNaN(Number(end)) || (end-start) < 0) {
            return
        }
        const tCount = Number(end)-Number(start)+1
        if (Number(end) > Number(collectionInfo.stats.count)){
            toast.warn('end of range is out of the bounds of the colletion')
            return
        }
        if (tCount > MAXTARGETS){
            toast.warn(`too many tokens targeted, try less than ${MAXTARGETS}`)
            return
        }
        setTokenCount(tCount)
        setTargetedTokens(range(Number(start), Number(end)));
        forceUpdate();
    }

    const fetchListingInfo = () => {
        return fetchListingInfoModule();
    }

    const fetchListingInfoModule = async() => {
        const toastId = toast.loading('Fetching listing information');
        const url = ModuleListingsURL + "?type=" + collection
        const results = await axios.get(url)
            .then(response => {
                if (response.status !== 200) {
                    throw Error('couldnt fetch url')
                }
                return response
            }).then(response => response.data)
            .catch(error =>  {
                console.log(error)
            })
        console.log(results);
        const listedTokens = results.listings.map(l => l.tokenId)
        setTargetedTokens(listedTokens)
        setTokenCount(results.count)
        forceUpdate()
        toast.update(toastId, { render: "Fetch successful", type: "success", isLoading: false });
        toast.dismiss(toastId)
        
    }

    const fetchListingInfoOS =  async() =>{
        let cursor = ''
        let allListedEvents = []
        let allSoldEvents = []
        const toastId = toast.loading('Fetching latest events...');
        for await (const ind of range(1, 10)) {
            const baseUrl = OSNewListings + `&collection_slug=${collection}`
            const finalUrl = baseUrl + (cursor ? `&cursor=${cursor}` : '')
            const newListings = await axios.get(finalUrl, { withCredentials:true,
                headers: {
                accept: "*/*",
                "accept-language": "en-US,en;q=0.9",
                "content-type": "application/json",
    
                "x-api-key": "2f6f419a083c46de9d83ce3dbe7db601",
                },
            }).then(response => {
                if (response.status !== 200) {
                    throw Error('couldnt fetch url')
                }
                return response
            }).then(response => response.data)
            .catch(error =>  {
                console.log(error)
            })
            cursor = newListings.next;
            allListedEvents.push(...newListings.asset_events)
            await sleep(1000)
            toast.update(toastId, { render : `${ind} out of ${TOTAL_EVENTS} requests sent.`, type:"info", isLoading:true, progress: ind/TOTAL_EVENTS})
        }

        for await (const ind of range(1, 10)) {
            const baseUrl = OSNewSales+ `&collection_slug=${collection}`
            const finalUrl = baseUrl + (cursor ? `&cursor=${cursor}` : '')
            const newSales = await axios.get(finalUrl, { withCredentials:true,
                headers: {
                accept: "*/*",
                "accept-language": "en-US,en;q=0.9",
                "content-type": "application/json",
    
                "x-api-key": "2f6f419a083c46de9d83ce3dbe7db601",
                },
            }).then(response => {
                if (response.status !== 200) {
                    throw Error('couldnt fetch url')
                }
                return response
            }).then(response => response.data)
            .catch(error =>  {
                console.log(error)
            })
            cursor = newSales.next;
            allSoldEvents.push(...newSales.asset_events)
            await sleep(1000)
            toast.update(toastId, { render : `${ind} out of ${TOTAL_EVENTS} requests sent.`, type:"info", isLoading:true, progress: ind/TOTAL_EVENTS})
        }
        const dedupeListings = allListedEvents.filter((event, index) => allListedEvents.findIndex(f=>f.asset.token_id ===event.asset.token_id) === index)
        console.log(dedupeListings)

        const validListings = 
            dedupeListings
            .filter(listing => {
                const lastTokenSale = allSoldEvents.find(sale => sale.asset.token_id === listing.asset.token_id)
                if (lastTokenSale === undefined ) {
                    return true
                } else {
                    console.log(lastTokenSale.created_date)
                    console.log(listing.created_date)
                    console.log(new Date(lastTokenSale.created_date))
                    console.log(new Date(listing.created_date));
                    const listingDate = new Date(lastTokenSale.created_date)
                    const saleDate = new Date(listing.created_date)
                    if (listingDate > saleDate) {
                        return true
                    } else {
                        return false
                    }
                }
            })
        console.log(validListings)
    }

    const resetBids = ()=> {
        if (!resetConfirm){
            setResetConfirm(true);
            return
        }
        setSignedBids([]);
        setSentBids([]);
        setResetConfirm(false);
        setSigExpirationTime(0);
    }

    const signAndSend = async () => { 
        if (signingRef.current|| sendingRef.current) {
            setSigning(false)
            setSending(false)
            return
        }
        if (targetedTokens.length < 1) {
            toast.warn('no tokens selected')
            return
        }
        if (!bidPrice || Number.isNaN(Number(bidPrice))){
            toast.warn('invalid bid price')
            return
        }
        if (address !== userAccount || isPk) {
            const unlocked = await keyringController.fullUpdate().isUnlocked
            if (!unlocked) {
                toast.warn('unlock wallet first')
                return
            }
        }
        const allSignedIds = signedBids.map(sent => sent.metadata.asset.id);
        const allSentIds = sentBids.map(sent => sent.metadata.asset.id);
        const toSign = targetedTokens.filter(token => !allSignedIds.includes(token.toString()))
        const toSend = signedBids.filter(bid => !allSentIds.includes(bid.metadata.asset.id))
        const batchedTargets = toSign.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index/20);
            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []
            }
            resultArray[chunkIndex].push(item)
            return resultArray
        }, [])
        setSigning(true)
        setSending(true)
        await sendBidsForTokens(toSend)
        for (const batch of batchedTargets) {
            if (!signingRef.current|| !sendingRef.current) break
            const signedBatch = await signBidsForTokens(batch)
            await sendBidsForTokens(signedBatch)
        }
        setSigning(false)
        setSending(false)
    }

    const signBidsForTokens = async (tokensToSign : number[])=> {
        let provider;
        if (address !== userAccount || isPk) {
            const unlocked = await keyringController.fullUpdate().isUnlocked
            if (!unlocked) {
                toast.warn('unlock wallet first')
                return
            }
            const pk = await keyringController.exportAccount(address);        
            let sigProvider = new HDWalletProvider({
                privateKeys: [
                    pk
                ],
                addressIndex: 0,
                chainId: 1,
                providerOrUrl: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
              });
            provider = sigProvider
        } else {
            provider = ethereum;
        }
        const seaport = new OpenSeaPort(provider, {
            networkName: Network.Main,
            apiKey: "2f6f419a083c46de9d83ce3dbe7db601"
            // apiKey: OS_API_KEY
        })
        const collectionAddress = collectionInfo.primary_asset_contracts[0].address
        const asset = await seaport.api.getAsset({
            tokenAddress : collectionAddress,
            tokenId: tokensToSign[0]
        })
        const expirationTime = Math.round(Date.now()/1000 + timeBump.seconds)
        setSigExpirationTime(expirationTime);
        const exchange = WyvernProtocol.getExchangeContractAddress(Network.Main)
        const priceParam = await seaport._getPriceParameters(0, WETH, expirationTime, Number(bidPrice))
        const approvedAmount = await seaport._getApprovedTokenCount({accountAddress:address})
        if (approvedAmount.toString() === '0') {
            toast.warn('this address has not given Opensea WETH approval, please do so manually through Opensea first ')
            if (address !== userAccount || isPk) provider.engine.stop()
            return
        }
        // setSigning(true)
        const localSignedBids = []
        if (address !== userAccount || isPk) {
            const perChunk = 50;
            const batchedTargets = tokensToSign.reduce((resultArray, item, index) => {
                const chunkIndex = Math.floor(index/perChunk);
    
                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = []
                }
                resultArray[chunkIndex].push(item)
                return resultArray
            }, [])
            for (const batch of batchedTargets) {
                await Promise.all(batch.map( async tk => {
                    let offer = null;
                    try {
                        offer = await seaport.createBuyOrderV2({
                            asset: {
                                tokenId : tk.toString(),
                                tokenAddress: collectionAddress
                            },
                            assetObject: asset,
                            accountAddress: address,
                            quantity: 1,
                            expirationTime: expirationTime,
                            priceParamObject: priceParam,
                            extraBountyBasisPoints: 0,
                            exchange: exchange
                        })
                        addSignedBid(seaport.orderToJson(offer), 0);
                        localSignedBids.push(seaport.orderToJson(offer))
                        return Promise.resolve(1);
                    } catch (error) {
                        console.log(error)
                        return Promise.resolve(0);
                    }
                }))
            }

            provider.engine.stop()
        } else {
            const perChunk = 10;
            const batchedTargets = tokensToSign.reduce((resultArray, item, index) => {
                const chunkIndex = Math.floor(index/perChunk);
    
                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = []
                }
                resultArray[chunkIndex].push(item)
                return resultArray
            }, [])
            for (const batch of batchedTargets) {
                await Promise.all(batch.map( async (tk, index) => {
                    let offer = null;
                    try {
                        offer = await seaport.createBuyOrderV2({
                            asset: {
                                tokenId : tk.toString(),
                                tokenAddress: collectionAddress
                            },
                            assetObject: asset,
                            accountAddress: address,
                            quantity: 1,
                            expirationTime: expirationTime,
                            priceParamObject: priceParam,
                            extraBountyBasisPoints: 0,
                            exchange: exchange
                        })
                        addSignedBid(seaport.orderToJson(offer), index);
                        localSignedBids.push(seaport.orderToJson(offer))
                        return Promise.resolve(1);

                    } catch (error) {
                        console.log(error)
                        return Promise.resolve(0);
                    }
                }))
            }
        } 
        // setSigning(false)   
        return localSignedBids    
    }

    // possibly deprecated
    const signBids = async ()=> {
        if (signing){
            console.log('already sending')
            setSigning(false)
            return
        }
        if (targetedTokens.length < 1) {
            toast.warn('no tokens selected')
            return
        }
        if (!bidPrice || Number.isNaN(Number(bidPrice))){
            toast.warn('invalid bid price')
            return
        }
        let provider;
        if (address !== userAccount || isPk) {
            const unlocked = await keyringController.fullUpdate().isUnlocked
            if (!unlocked) {
                toast.warn('unlock wallet first')
                return
            }
            const pk = await keyringController.exportAccount(address);        
            let sigProvider = new HDWalletProvider({
                privateKeys: [
                    pk
                ],
                addressIndex: 0,
                chainId: 1,
                providerOrUrl: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
              });
            provider = sigProvider
        } else {
            provider = ethereum;
        }
        const seaport = new OpenSeaPort(provider, {
            networkName: Network.Main,
            apiKey: "2f6f419a083c46de9d83ce3dbe7db601"
            // apiKey: OS_API_KEY
        })
        const collectionAddress = collectionInfo.primary_asset_contracts[0].address
        const asset = await seaport.api.getAsset({
            tokenAddress : collectionAddress,
            tokenId: targetedTokens[0]
        })
        const expirationTime = Math.round(Date.now()/1000 + timeBump.seconds)
        setSigExpirationTime(expirationTime);
        const exchange = WyvernProtocol.getExchangeContractAddress(Network.Main)
        const priceParam = await seaport._getPriceParameters(0, WETH, expirationTime, Number(bidPrice))
        const approvedAmount = await seaport._getApprovedTokenCount({accountAddress:address})
        if (approvedAmount.toString() === '0') {
            toast.warn('this address has not given Opensea WETH approval, please do so manually through Opensea first ')
            if (address !== userAccount || isPk) provider.engine.stop()
            return
        }
        setSigning(true)
        if (address !== userAccount || isPk) {
            const perChunk = 50;
            const allSignedIds = signedBids.map(sent => sent.metadata.asset.id);
            const toSign = targetedTokens.filter(token => !allSignedIds.includes(token.toString()))
            const batchedTargets = toSign.reduce((resultArray, item, index) => {
                const chunkIndex = Math.floor(index/perChunk);
    
                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = []
                }
                resultArray[chunkIndex].push(item)
                return resultArray
            }, [])
            for (const batch of batchedTargets) {
                await Promise.all(batch.map( async tk => {
                    let offer = null;
                    try {
                        offer = await seaport.createBuyOrderV2({
                            asset: {
                                tokenId : tk.toString(),
                                tokenAddress: collectionAddress
                            },
                            assetObject: asset,
                            accountAddress: address,
                            quantity: 1,
                            expirationTime: expirationTime,
                            priceParamObject: priceParam,
                            extraBountyBasisPoints: 0,
                            exchange: exchange
                        })
                        addSignedBid(seaport.orderToJson(offer), 0);
                        return Promise.resolve(1);
                    } catch (error) {
                        console.log(error)
                        return Promise.resolve(0);
                    }
                }))
            }

            provider.engine.stop()
        } else {
            const perChunk = 10;
            const batchedTargets = targetedTokens.reduce((resultArray, item, index) => {
                const chunkIndex = Math.floor(index/perChunk);
    
                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = []
                }
                resultArray[chunkIndex].push(item)
                return resultArray
            }, [])
            for (const batch of batchedTargets) {
                await Promise.all(batch.map( async (tk, index) => {
                    let offer = null;
                    try {
                        offer = await seaport.createBuyOrderV2({
                            asset: {
                                tokenId : tk.toString(),
                                tokenAddress: collectionAddress
                            },
                            assetObject: asset,
                            accountAddress: address,
                            quantity: 1,
                            expirationTime: expirationTime,
                            priceParamObject: priceParam,
                            extraBountyBasisPoints: 0,
                            exchange: exchange
                        })
                        addSignedBid(seaport.orderToJson(offer), index);
                        return Promise.resolve(1);

                    } catch (error) {
                        console.log(error)
                        return Promise.resolve(0);
                    }
                }))
            }
        } 
        setSigning(false)       
    }

    const addSignedBid = async(bid, index) => {
        setSignedBids(current => [...current, bid])
        if (address === userAccount && !isPk) {
            const sendFunction = index% 2 === 0 ? sendBidLocal : sendBidProxy
            sendFunction(bid)
        }
    }

    const sendBidsForTokens = async (sTokens)=> {
        if (!sTokens || sTokens.length === 0 ) return
        const allSentIds = sentBids.map(sent => sent.metadata.asset.id);
        const toSend = sTokens.filter(bid => !allSentIds.includes(bid.metadata.asset.id))
        const failedOnceBids = await sendLoop(toSend);
        if (failedOnceBids) await sendLoop(failedOnceBids)
        return
    }

    //  possibly deprecated
    const sendBids = async ()=> {
        if (sending){
            console.log('already sending')
            setSending(false)
            return
        }
        const allSentIds = sentBids.map(sent => sent.metadata.asset.id);
        const toSend = signedBids.filter(bid => !allSentIds.includes(bid.metadata.asset.id))
        setSending(true)
        const failedOnceBids = await sendLoop(toSend);
        if (failedOnceBids) await sendLoop(failedOnceBids)
        setSending(false);
        return
    }

    const sendLoop = async (bids) => {
        let fourHundredCount = 0
        let tryCount = 1
        let splits = 3
        let failedBids = []
        for (const [bid, index] of bids.map((x, i) => [x, i])) {
            if (!sendingRef.current) break;
            const sendFunction = index % splits === 0 ? sendBidLocal: sendBidProxy
            const response = await sendFunction(bid);
            if (response.status === 200) {
                setSentBids(current =>[...current, response.result])
            } else if (response.status === 401) {
                toast.info(`found invalid key : ${response.key}.\nplease report to aivc`);
                failedBids.push(bid)
            } else if (response.status === 400) {
                if (fourHundredCount > 10) {
                    toast.warn(`error 400 : ${response.error} \nstopping bidding.`, {pauseOnFocusLoss: true})
                    return null
                }
                const throtleDelay = 12000 + (tryCount * 3000)
                toast.warn(`error 400 : ${response.error}\n waiting ${throtleDelay/1000} seconds`, {autoClose: throtleDelay, pauseOnFocusLoss:false})
                await sleep(throtleDelay)
                failedBids.push(bid)
                fourHundredCount++
                tryCount++
            } else if (response.status === 429){
                // if (tryCount > 10){
                //     toast.warn(`error 429 :  ${response.error} \nstopping bidding.`, {pauseOnFocusLoss: true})
                //     return null
                // }
                // const throtleDelay = 12000 + (tryCount * 3000)
                const throtleDelay = 10000
                toast.warn(`error 429 :  ${response.error} \nwaiting ${throtleDelay/1000} seconds`, {autoClose: throtleDelay, pauseOnFocusLoss:false})
                await sleep(throtleDelay)
                failedBids.push(bid)
                tryCount++
            } else if (response.status !== 200){
                if (tryCount > 5){
                    toast.warn(`error ${response.status} :  ${response.error} \nstopping bidding.`)
                    return null
                }
                const throtleDelay = 12000 + (tryCount * 3000)
                toast.warn(`error ${response.status} :  ${response.error} \nwaiting ${throtleDelay/1000} seconds`, {autoClose: throtleDelay, pauseOnFocusLoss:false})
                await sleep(throtleDelay)
                failedBids.push(bid)
                tryCount++
            }
        }
        return failedBids
    }
    const script = `
    try {
        let json;
        let resp = await fetch(arguments[0], {
            method: arguments[1],
            headers: arguments[2],
            body: arguments[3]
        }).then(async res => {json=await res.json();console.log(json);return res})
        return {
            ok: resp.ok,
            status_code: resp.status,
            body: json,
            headers: resp.headers
        }
    } catch {
        return null
    }
    `
    const sendBidLocal = async (bid) => {
        const sendResponse = await fetch("https://api.opensea.io/wyvern/v1/orders/post/", {
            method: "POST",
            headers: {
                accept: "*/*",
                "accept-language": "en-US,en;q=0.9",
                "content-type": "application/json",
                "sec-ch-ua":
                '"Google Chrome";v="95", "Chromium";v="95", ";Not A Brand";v="99"',
                "user-agent":
                "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.69 Safari/537.36",
                "x-api-key": "2f6f419a083c46de9d83ce3dbe7db601",
            },
            body: JSON.stringify(bid)
        })
        .then(response => {
            if (!response.ok) {
                throw Error(response.status.toString())
            }
            return response
        })
        .then(response => response.json())
        .then(response => {
            return {status: 200, result: response, key: null, error: null}
        })
            
        .catch(error => {
            // toast.warn(`${error.message}`)
            
            return {status: Number(error), result: null, key: null, error: error.message }
        })
        return sendResponse
    }

    const sendBidLocalV2 = async (bid) => {
        // chrome.setDefaultService(new chrome.ServiceBuilder(chromedriver.path).build())

        // let opts = new chrome.Options()
        // const driver = await new selenium.Builder().withCapabilities(opts).build();
        // const postReq = async (url, headers, body) => {
        //     return driver.executeScript(script, url, 'POST', headers, body)
        // }

        // const sendResponse = await postReq(
        //     "https://api.opensea.io/wyvern/v1/orders/post/",
        //     {   'X-API-KEY' : "2f6f419a083c46de9d83ce3dbe7db601",
        //         'content-type': "application/json"
        //     },
        //     JSON.stringify({
        //         destination : "https://api.opensea.io/wyvern/v1/orders/post/",
        //         data: bid
        //     })
        // );
        // console.log(sendResponse)
        // driver.close()
        // return sendResponse
        const sendResponse= await fetch("https://grun-iqq5tbrf5q-uc.a.run.app/", {
            method: "POST",
            body: JSON.stringify({
                data: bid
            })
        })
        .then(response => {
            if (!response.ok) {
                throw Error(response.status.toString())
            }
            return response
        })
        .then(response => response.json())            
        .catch(error => {
            console.log(error)
            toast.warn(`${error.message}`)
            return {status: Number(error)}
        })
        return sendResponse;
    }

    const sendBidProxy = async (bid) => {
        const sendResponse= await fetch("https://cyberbabies.io/api/proxyPost", {
            method: "POST",
            body: JSON.stringify({
                destination : "https://api.opensea.io/wyvern/v1/orders/post/",
                data: bid
            })
        })
        .then(response => {
            if (!response.ok) {
                throw Error(response.status.toString())
            }
            return response
        })
        .then(response => response.json())            
        .catch(error => {
            console.log(error)
            toast.warn(`${error.message}`)
            return {status: Number(error)}
        })
        return sendResponse;
    }

    useEffect(()=>{ 
        setAddress(getCurrentAddress());
        setBalance(getCurrentBalance());
        setIsPk(getCurrentIsPk());
        forceUpdate()
    }, [showWalletManager])

    useEffect(()=>{
        if (localDelFirst === false) {
            window.localStorage.removeItem(
                'mnemonic')
            setLocalDelFirst(true);
        }
    },[])
    return (
    <>  
        <WalletManager userAccount={userAccount} web3={web3} keyringController={keyringController} setShowWalletManager={setShowWalletManager} showWalletManager={showWalletManager} />
        <ToastContainer pauseOnHover={false} pauseOnFocusLoss={false} position="top-left" theme="dark"/>   
        <Container className="settingsContainer">
            <Row> 
            <p>version: {version}</p>
                <Col>
                    <Button  style={{width:"200px"}} variant="secondary" onClick={()=>setShowWalletManager(true)}>wallet</Button>
                </Col>
                <p> {address}</p>
                <p>balance: {Number(Web3Utils.fromWei(balance, "ether")).toLocaleString(undefined, { maximumFractionDigits:6} )} weth</p> 
            </Row>
        </Container>
        <>
            {!collectionInfo.name? 
                <Container style={{width:"50vw", marginTop: "25px"}}>
                <Row>
                    <Form.Label column md="4"> collection slug </Form.Label>
                    <Form.Label column md="1"> or </Form.Label>
                    <Form.Label column md="4"> contract address </Form.Label>
                </Row>
                <Form.Group as={Row}>
                    <Col md="4">
                        <Form.Control onKeyPress={enterOnSlug} id="collectionSlug"  type="text"  onChange={(event) => setCollection(event.target.value)}/>
                    </Col>
                    <Col md="1"></Col>
                    <Col md="4">
                        <Form.Control onKeyPress={enterOnSlug} id="collectionContract"  type="text"  onChange={(event) => setContract(event.target.value)}/>
                    </Col>
                    <Col md="3">
                        <Button variant="secondary" onClick={submitCollection}> submit</Button>
                    </Col>
                </Form.Group>
                </Container>
            : null}
            <br />
            {collectionInfo.name ? 
                <>
                    <Container className="collectionInfo">
                        <Card style={{ background: "black"}}>
                            <Card.Img variant="top" src={collectionInfo?.banner_image_url} className="collectionInfoBanner" style={{maxHeight: "275px", minHeight:"150px"}}/>
                            <Card.Body style={{marginTop: '-144px', backgroundColor: "rgba(0, 0, 0, 0.7)"}}>
                            
                                <Row>
                                    <Col md="12">
                                        <p>collection</p>
                                        <h3><a target="_blank" className="nolinkplease" href={`https://opensea.io/collection/${collectionInfo.slug}`}>{collectionInfo.name}</a></h3>
                                    </Col>
                                    {/* <Col>
                                        <p> _</p>
                                        <Button size="sm" variant="secondary" onClick={()=>reloadCollection()}>reload</Button>
                                    </Col> */}
                                </Row>
                                <Row>
                                    <Col>
                                        <p>count</p>
                                        <h4>{collectionInfo.stats.count}</h4>
                                    </Col>
                                    <Col>
                                        <p>floor</p>
                                        <h4>{collectionInfo.stats.floor_price}</h4>
                                    </Col>
                                    <Col>
                                        <p>royalty</p>
                                        <h4>{collectionInfo.dev_seller_fee_basis_points /100} %</h4>
                                    </Col>

                                    <Col>
                                        <p>24h avg price</p>
                                        <h4>{collectionInfo.stats.one_day_average_price.toLocaleString(undefined, { maximumFractionDigits:2})}</h4>
                                    </Col>
                                    <Col>
                                        <p>24h sale count</p>
                                        <h4>{collectionInfo.stats.one_day_sales}</h4>
                                    </Col>
                                    <Col>
                                        <p>24h volume</p>
                                        <h4>{collectionInfo.stats.one_day_volume.toLocaleString(undefined, { maximumFractionDigits:2})}</h4>
                                    </Col>
                                    <Col>
                                        <p>rankings</p>
                                        {collectionRanks ? 
                                        collectionRanks.origin
                                        :<Button size="sm" variant="secondary" onClick={()=>loadRankingsCYBB()}>load</Button>}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Container>
                    <Container className="watchSettingsInput">
                    {/* <Row> */}
                        <Form.Group as={Row}>
                            <Form.Label column md="auto"> bid price</Form.Label>
                            <Col md="auto">
                                <Form.Control id="bidPrice" type="text" disabled={signedBids.length>0}  onChange={(event) => setBidPrice(event.target.value)} />
                            </Col>
                            <Form.Label column md="auto"> expiration</Form.Label>
                            <Col md="auto">
                                <Form.Control disabled={signedBids.length>0} style={{minWidth:"200px"}} as="select" type="text" onChange={(event) => 
                                                setTimeBump(JSON.parse(event.target.value) as any)
                                            }>
                                                
                                        {TIMEBUMPS.map(tbump =>
                                            <option selected={tbump.name === "3 hours"} value={JSON.stringify(tbump)}>{tbump.name}</option>
                                        )}
                                </Form.Control>
                            </Col>                            
                        </Form.Group>
                    {/* </Row> */}
                    </Container>

                    <Container className="watchSettingsInput">
                        <Form.Group as={Row}>
                            <Form.Label column md="auto"> selection</Form.Label>
                            <Col md="auto">
                                <Form.Control disabled={signedBids.length>0} style={{minWidth:"200px"}} as="select" type="text" onChange={(event) => changeSelectionType(event.target.value)}>
                                    <option value={'tokenRange'}> token id range</option>
                                    <option value={'rankRange'}>rank range</option> 
                                    <option value={'byTrait'}> by trait</option>
                                    <option value={'onlyListed'}> by listing status</option>
                                </Form.Control>
                            </Col>
                            
                            {selectionType === 'tokenRange' ? 
                            <>
                                <Form.Label column md="auto">token range</Form.Label>
                                <Col md="auto">
                                    <Form.Control disabled={signedBids.length>0} placeholder="start:end" type="text" onChange={(event) => sanitizeTokenRange(event.target.value)} />
                                </Col>
                            </>
                            :null} 

                            {selectionType === 'rankRange' ? 
                            <>
                                <Form.Label column md="auto">rank range</Form.Label>
                                <Col md="auto">
                                    <Form.Control disabled={signedBids.length>0} placeholder="start:end" id="maxRank" type="text" onChange={(event) => sanitizeRankRange(event.target.value)} />
                                </Col>
                            </>
                            :null} 

                            {selectionType === 'byTrait' ? 
                            <>

                                    <>
                                    <br />
                                    {traitFilters.map((filter, index) => 
                                        <>
                                            <Col md="auto">
                                                <Form.Control disabled={signedBids.length>0} as="select" type="text" onChange={(event) => 
                                                    modifyTraitFilter(index, {
                                                        trait_type: event.target.value,
                                                        value: collectionRanks.baseAttributeProps.find(prop => prop.name === event.target.value)?.values[0][0]
                                                })}>
                                                    {collectionRanks.baseAttributeProps.map((tType, index) => 
                                                        <option value={tType.name}>{tType.name}</option>
                                                    )}
                                                </Form.Control>
                                            </Col>
                                            <Col md="auto">
                                                <Form.Control disabled={signedBids.length>0} as="select" type="text" onChange={(event) => 
                                                    modifyTraitFilter(index, {
                                                        trait_type: filter.trait_type,
                                                        value: event.target.value
                                                    })
                                                }>
                                                    
                                                    {collectionRanks.baseAttributeProps.find(prop => prop.name === filter.trait_type)?.values.map((tValue, index) => 
                                                        <option value={tValue[0]}>{tValue[0]+ " - " + ((tValue[1]/collectionRanks.baseAttributeProps.find(prop => prop.name === filter.trait_type).amount) * 100).toPrecision(4)+ '%'}</option>
                                                    )}
                                                </Form.Control>
                                            </Col>
                                        </>
                                    )}
                                    </>
                            </>
                            :null} 

                            {selectionType === 'onlyListed' ? 
                            <>
                                <Button  style={{width:"200px"}} variant="secondary" onClick={()=>fetchListingInfo()}> fetch listings </Button>
                            </>
                            :null}

                        </Form.Group>
                    </Container>

            
                    {/* <Col md="auto">
                        <Button className="btn btn-info btn-custom" onClick={()=>{startBidding()}}> {bidding || checkingTx ? "cancel" : "start bidding"}</Button>
                    </Col> */}
                    <br />
                    <Container>
                        <Row>
                            <Col md="10">
                                <h3>Tokens targeted : {tokenCount}</h3>
                                {sigExpirationTime ? <>time remaining : <Countdown date={sigExpirationTime*1000} daysInHours={true} /></>:null}
                                <p> Signed Bids</p>
                            </Col>
                            <Col md="2">
                                <br></br>
                                <Button className="btn btn-warning" disabled={signedBids.length===0}style={{minWidth: "100px"}} onClick={()=>resetBids()}>{resetConfirm ? 'you sure ?' : 'reset'}</Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="10">
                                <ProgressBar style={{height:"40px"}} label={`${signedBids.length} / ${tokenCount}`} animated={signing} striped={signing} variant="info" now={signedBids.length} min={0} max={tokenCount}></ProgressBar>

                            </Col>
                            <Col md="2">
                                {/* <Button style={{minWidth: "150px"}} disabled={tokenCount === signedBids.length} onClick={()=>signBids()} className="btn btn-info btn-custom"> {signing ? "cancel" : "sign"}</Button> */}
                            </Col>
                        </Row>

                        
                        <Row>
                            <Col md="10">
                            <p>______</p>
                            </Col>
                            <Col md="2">
                                <Button 
                                    className="btn btn-info btn-custom" 
                                    style={{minWidth: "100px"}} 
                                    disabled={tokenCount === sentBids.length}
                                    onClick={()=> signAndSend()}
                                >
                                    {signing || sending ? 'cancel' : 'bid'}
                                </Button>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col md="10">
                            <ProgressBar style={{height:"40px"}}  label={`${sentBids.length} / ${tokenCount}`}animated={sending} striped={sending} variant="success" now={sentBids.length} min={0} max={tokenCount}></ProgressBar>

                            </Col>
                            <Col md="2">
                                {/* <Button style={{minWidth: "150px"}} disabled={tokenCount === sentBids.length} onClick={()=>sendBids()} className="btn btn-info btn-custom"> {sending ? "cancel" : "send"}</Button> */}
                            </Col>
                        </Row>

                    </Container>
                    <br/>
                    <Container>

                    
                    <Table bordered hover style={{padding: "20px", color: "#fff !important"}}>
                        <thead>
                            <tr>
                                <th>list id</th>
                                <th> id</th>
                                {collectionRanks? <th>rank</th>:null}
                                <th> price</th>
                                <th> list time</th>
                            </tr>
                        </thead>
                        <tbody>
                        {sentBids.length > 0 ? sentBids.map((entry, index) => 
                            <tr key={index} onClick={()=>console.log('clicked')}>
                                <td><a target="_blank" href={entry.asset?.permalink} rel="noreferrer" className="nolinkplease">{entry.id}</a></td>
                                <td><a target="_blank" href={entry.asset?.permalink} rel="noreferrer" className="nolinkplease">{entry.asset?.token_id}</a> </td>
                                {collectionRanks ? <td>{getTokenRank(entry.asset?.token_id)}</td>: null}
                                <td> {web3.utils.fromWei(entry.base_price, "ether")}</td>
                                <td> {entry.closing_date}</td>
                            </tr> 
                            ): null}
                        </tbody>
                    </Table>
                    </Container>
                </>
            : null}
        </>
    </>
    )
}
