import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { NftBotApp } from '../bots';
import {  ref, get } from 'firebase/database';
import { useAlchemy, useAuthentication, useFirebase } from '../hooks';
import {Container} from '@mui/material';

const CABI = require('../utilities/CyberBabies.json');

const cyberBabies  = {
    name : "Cyber Babies",
    address: "0x991A546A167cEb2a6a7C344C9D85269Ac03035D9",
}
const adminAddys = [
"0xe74f61066FB2bC14A8Eef295046d27068DC6CA58",
  "0x8154Ad71D7f398505598ceeD3Cd2f1F46b95e2b1",
  "0x4cF150b0116d503bF1Aa79d0EA64e2F8b21136cD",
  "0xAC3c837D67B4F638C0ABBF711327CFd33149Cf3D",
  "0xfF174799aD2cC7F777550178951E6f20f9dca3cD",
  "0xa81A3127BD9E757EC6c16e92a3D1778D3F428649",
  "0x62B5bD7F04Fe783796810b2526470dfdE73dAA49",
  "0x8d4dAbA34C92E581F928fCA40e018382f7A0282a",
]

export const NftBot = (props) => {
  const { alchemyWeb3: web3 } = useAlchemy();
  const { realtime } = useFirebase();
  const { hasAccess, isDelegate } = useAuthentication();
  const delegateRef = ref(realtime, 'flashbotDelegates');
  const [account, setAccount] = useState("");
  const [allowed, setAllowed] = useState(false);
  const [tokensOwned, setTokensOwned] = useState([]);
  const [delegateToken, setDelegateToken] = useState(undefined)
  const contract = new web3.eth.Contract(CABI.abi, cyberBabies.address);   
  const verifyOwnership = async () => {
    const tokensOwned = await contract.methods.whichBabyIsMine(account).call();
    setTokensOwned(tokensOwned)
        if (isDelegate) {
            setDelegateToken(1)
        }
      if (hasAccess){
        setAllowed(true)
        return
      }

      if (tokensOwned.length === 0) {
          setAllowed(false);
      } else {
          setAllowed(true);
      }
      if (adminAddys.map(addy => addy.toLowerCase()).includes(account)){
          setAllowed(true)
      }
      await get(delegateRef).then(data => {
          if (data.exists()) {
              const allDelegates = Object.entries(data.val())
              console.log(allDelegates)
              allDelegates.forEach(delEntry => {
                  if (Object.values(delEntry[1]).includes(account)){
                    setAllowed(true);
                    setDelegateToken(delEntry[0])
                  }
              }) 
          }
      })
  }
  useEffect(() => {
      const getCurrentWallet = async () => {
          try {
              const { ethereum } = window as any;
              const addressArray = await ethereum.request({ method: "eth_accounts"});
              if (addressArray.length > 0) {
                  setAccount(addressArray[0])
              }
          } catch (err) {
              console.log(err);
          }
      }
      getCurrentWallet();


  }, []);
  useEffect(() => {
      if (account) {
          verifyOwnership();
      }
  }, [account]);
    return (
      <>
      {!allowed ? 
          <>
              <Container className="mintContainers" style={{paddingTop:"5vh"}}>
                  <h1>
                      {allowed ? "Welcome to the club !" : "For (key) members only"}
                  </h1>
              </Container>
              <NftBotInfo />
          </>
          
          :<>
          <NftBotApp account={account} web3={web3} tokensOwned={tokensOwned} delegateToken={delegateToken}/>
          </>
      }
      </>
  )
}

export const NftBotInfo = (props) => {
  return (
    <Container>
    <Row>
        <Col>
            <Container className="FeatureListContainer">
                <h2 > 
                     Features
                </h2>
                <p>
                    ~ Fatest and most efficient way to mint very hyped drops<br />
                    ~ Watch mempool for the flip sale tx <br/>
                    ~ Sign your mint tx in advance <br/>
                    ~ Fully hands off at drop time to ensure maximum speed <br/>
                    ~ Avoid overpaying gas war prices<br/>
                    ~ Get on the same or very next  block as the flip tx <br/>
                </p>
            </Container>
        </Col>
        <Col>
          <Container className="FeatureListContainer">

            <h2>
                Info
            </h2>
            <p>
              ~ Need to be a baby owner<br />
              ~ Visit our discord for more information ! <br/>
            </p>
          </Container>
        </Col>
    </Row>
</Container>
  )
}