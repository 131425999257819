import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
const KeyringController = require('eth-keyring-controller');

async function initialize() {
  const status = 'started'
  const item = window.localStorage.getItem('initState');
  const initState = item ? JSON.parse(item) : {}
  const keyringController = new KeyringController({initState: initState} || {})
  return {
      status, keyringController
  };
}

initialize().then((props) => {
  ReactDOM.render(
    <App {...props}/>,
  document.getElementById('root')
);
})


