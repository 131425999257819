import React, { useState, useEffect } from 'react';
import {Row, Container, FormControl, Form, Button, Col, Modal, Dropdown} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useLocalStorage } from '../utilities/helpers';
import Web3Utils from 'web3-utils';

const { normalize: normalizeAddress } = require('eth-sig-util');

const useForceUpdate = () => {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}
const WETH = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
const minABI = [
    // balanceOf
    {
      "constant":true,
      "inputs":[{"name":"_owner","type":"address"}],
      "name":"balanceOf",
      "outputs":[{"name":"balance","type":"uint256"}],
      "type":"function"
    },
    // decimals
    {
      "constant":true,
      "inputs":[],
      "name":"decimals",
      "outputs":[{"name":"","type":"uint8"}],
      "type":"function"
    }
  ];

export const WalletManager = (props:any) => {
    const {app, keyringController, setShowWalletManager, showWalletManager, web3, userAccount} = props
    const [mnemonic, setMnemonic] = useState("")
    const [passwordField, setPasswordField] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [address, setAddress] = useLocalStorage("address", userAccount)
    const [allAccounts, setAllAccounts] = useState([])
    const [balance, setBalance] = useLocalStorage("balance", '')
    const [isPk, setIsPk] = useLocalStorage("isPk", "false");
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [initState, setInitState] = useLocalStorage('initState', {vault: undefined})
    const forceUpdate = useForceUpdate();
    const contract = new web3.eth.Contract(minABI, WETH);
    const submitPassword = async (text) => {
        try {
            const result = await keyringController.submitPassword(text);
            if (address !== userAccount) {
                const addy = keyringController.fullUpdate().keyrings[0].accounts.find(a => a===address)
                const balance = await contract.methods.balanceOf(addy).call();
                setAddress(addy)
                setBalance(balance);
                setIsPk(true);

            }
            forceUpdate();
            // setShowWalletManager(false);
        } catch (error) {
            toast.error(error.message)
            forceUpdate();
        }
        
    }



    const selectAccount = async (account, index=0) => {
        if (index === 0){
            setIsPk(false);
        } else {
            setIsPk(true);
        }
        try {
            const balance = await contract.methods.balanceOf(account).call();
            setAddress(account)
            setBalance(balance)
            forceUpdate();
        } catch (error) {
            toast.error(error.message)
        }
    } 
    const submitMnemonic = async () => {
        try {
            await keyringController.createNewVaultAndRestore(passwordField, mnemonic)
            keyringController.persistAllKeyrings(passwordField);
            setInitState({vault:keyringController.store.getState().vault})
            toast.info('created new encrypted vault')
            setIsPk(true);
        } catch (error) {
            toast.error(error.message)
            forceUpdate();
        }
    }

    const verifyPasswordMatch = (a,b) => {
        return a!== '' && b!== '' && a === b
    }

    const deleteAccount = async ()=>{
        if (!deleteConfirm){
            setDeleteConfirm(true)
            return
        }
        await keyringController.removeAccount(normalizeAddress(address));
        await keyringController.setLocked();
        toast.error('deleted current vault')
        setInitState({vault:undefined})
        setDeleteConfirm(false);
        forceUpdate();
    }

    const addNewAccount = async ()=>{
        const keyring = await keyringController.getKeyringForAccount(normalizeAddress(address))
        await keyringController.addNewAccount(keyring)
        setInitState({vault:keyringController.store.getState().vault})
        toast.info('added new account')
        forceUpdate();
    }

    const enterOnPassword = ( key) => {
        if (key.charCode === 13) {
            submitPassword(passwordField)
        }
    }

    useEffect(()=>{
        const updateBalance = async() =>{
            const balance = await contract.methods.balanceOf(address).call();
            setBalance(balance);
        }
        updateBalance();

    },[])

    return (
        <Modal show={showWalletManager}  onHide={()=>setShowWalletManager(false)}>
            <Modal.Header>
                <Modal.Title>wallet manager</Modal.Title>
            </Modal.Header>
            <Modal.Body >
            <>
            {  !keyringController.fullUpdate().isUnlocked && initState.vault ?
                <div style={{ textAlign:"center"}}>
                    <p>password</p>
                    <FormControl type="password" onKeyPress={enterOnPassword} id="password" onChange={(event) => setPasswordField(event.target.value)}/>

                </div>
            : null}
            { keyringController.fullUpdate().isUnlocked ? 
                <>
                <div style={{ textAlign:"center"}}>

                
                <Dropdown>
                <Dropdown.Toggle className="btn-secondary" >
                    {keyringController.fullUpdate().keyrings[0].accounts.indexOf(address) !== -1 ? keyringController.fullUpdate().keyrings[0].accounts.indexOf(address) : 'MM' } : {address}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{overflow: "hidden"}}>
                    
                        <Dropdown.Item className="btn-secondary" as="button" onClick={()=>selectAccount(userAccount, 0)}>MM : {userAccount}</Dropdown.Item>
                        <Dropdown.Divider />
                        {keyringController.fullUpdate().keyrings[0].accounts
                        .map((ele, index) => 
                            <Dropdown.Item active={address ===ele} key={index} className="btn-secondary" as="button" onClick={()=>selectAccount(ele, index+1)}>{index} : {ele}</Dropdown.Item>
    
                        )}
                        <Dropdown.Divider />
                        <Dropdown.Item className="btn-secondary" as="button" onClick={()=>addNewAccount()} >add new account</Dropdown.Item>
                        
                </Dropdown.Menu>
                </Dropdown>
                    <br/>
                    <p>balance: {Number(Web3Utils.fromWei(balance, "ether")).toLocaleString(undefined, { maximumFractionDigits:6} )} weth</p> 
                </div>
                </>
            
            
            : null}
            {!(initState.vault) ?
                <div style={{ textAlign:"center"}}>
                    <p className="importantMessage">READ CAREFULLY</p>
                    <p>By default, the bid bot will use your Metamask selected account to bid.
                        To enable bulk signing, you have to provide your private key to this page. 
                        That private key will be encrypted and stored in your browser's local storage.
                        This is the same process that Metamask uses to store your private key safely.
                        We've gone one step further by locking your vault everytime your refresh your page, thus requiring
                        you to login with your password at each page refresh</p>
                   
                     
                    <p className="importantMessage">READ CAREFULLY</p>
                    <p>initiate a new vault:</p>
                    <br/>
                    <p>12 words mnemonic</p>
                    <FormControl type="text" autoComplete="off" id="mnemonic" onChange={(event) => setMnemonic(event.target.value)}/>
                    <br />
                    <p>password</p>
                    <FormControl type="password" id="password"  onChange={(event) => setPasswordField(event.target.value)}/>
                    <br />
                    <p>confirm password</p>
                    <FormControl type="password" id="passwordConfirm"  onChange={(event) => setPasswordConfirm(event.target.value)}/>
                    <br />
                    {verifyPasswordMatch(passwordField, passwordConfirm) ? null : <p>password don't match</p>}
                    <Button variant="success" disabled={!verifyPasswordMatch(passwordField, passwordConfirm)}onClick={()=>submitMnemonic()}>create new vault</Button>
                </div>
            :null}
            </>

            </Modal.Body>
            <Modal.Footer>
            {keyringController.fullUpdate().isUnlocked ? 
                <Button variant="danger" onClick={()=>{deleteAccount()}}> {deleteConfirm ? "are you sure ? ": 'delete vault'}</Button>
            : null}
            {!keyringController.fullUpdate().isUnlocked && initState.vault ?<Button variant="secondary" onClick={()=>submitPassword(passwordField)}>unlock</Button> : null}
            <Button variant="secondary" onClick={()=>{setShowWalletManager(false)}}>
                close
            </Button>
            </Modal.Footer>
        </Modal>
    )
}