import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { RarityBotApp } from '../bots';
import {Carousel} from 'react-responsive-carousel';
import { useAlchemy, useAuthentication } from '../hooks';

const CABI = require('../utilities/CyberBabies.json');

const cyberBabies  = {
    name : "Cyber Babies",
    address: "0x991A546A167cEb2a6a7C344C9D85269Ac03035D9",
}

const adminAddys = [
    "0x8154Ad71D7f398505598ceeD3Cd2f1F46b95e2b1",
    "0x4cF150b0116d503bF1Aa79d0EA64e2F8b21136cD",
    "0xAC3c837D67B4F638C0ABBF711327CFd33149Cf3D",
    "0xfF174799aD2cC7F777550178951E6f20f9dca3cD",
    "0xa81A3127BD9E757EC6c16e92a3D1778D3F428649",
]
export const RarityBot = (props) => {
    const { alchemyWeb3: web3 } = useAlchemy();
    const { hasAccess } = useAuthentication();
    const [account, setAccount] = useState("");
    const [allowed, setAllowed] = useState(false);
    const contract = new web3.eth.Contract(CABI.abi, cyberBabies.address);   
    const verifyOwnership = async () => {
        if (hasAccess){
            setAllowed(true)
            return
        }
        const response = await contract.methods.balanceOf(account).call();
        if (Number(response) === 0) {
            setAllowed(false);
        } else {
            setAllowed(true);
        }
        if (adminAddys.map(addy => addy.toLowerCase()).includes(account)){
            setAllowed(true)
        }
    }
    useEffect(() => {
        const getCurrentWallet = async () => {
            try {
                const { ethereum } = window as any;
                const addressArray = await ethereum.request({ method: "eth_accounts"});
                if (addressArray.length > 0) {
                    setAccount(addressArray[0])
                }
            } catch (err) {
                console.log(err);
            }
        }
        getCurrentWallet();


    }, []);
    useEffect(() => {
        if (account) {
            verifyOwnership();
        }
    }, [account]);
    return (
        <>
        {!allowed ? 
            <>
                <Container className="mintContainers" style={{paddingTop:"5vh"}}>
                    <h1>
                        {allowed ? "Welcome to the club !" : "For members only"}
                    </h1>
                </Container>
                <RarityBotInfo />
            </>
            
            :<>
            {/* <h1>Maintenance</h1> */}
            <RarityBotApp account={account} web3={web3}/>
            </>
        }
        </>
    )

}

export const RarityBotInfo = (props) => {
    return (
        <Container>
        <Row>
            <Col>
                <Container className="FeatureListContainer">
                    <h2 > 
                        Current Features
                    </h2>
                    <p>
                        ~ Cloud Fetch new collections<br />
                        &emsp; - fetching is done on our backend to speed up the process <br/>
                        &emsp; - collections are stored for all users to access quickly<br/>
                        &emsp; - all tokesn are fetched simultaneously<br/>
                        &emsp; - attempt 5 ipfs nodes for each token<br/>
                        &emsp; - ability to refetch a collection if changes are detected<br/>
                        <br />
                        ~ OpenSea listings shown for each token <br/>
                        &emsp; - implements the CYBB button from our OSBot <br />
                        &emsp; - ability to go directly to Metamask using CYBB <br/>
                        &emsp; - uses your gas presets for quicker sniping of rares <br/>
                        &emsp; - ensures your tx doesn't fail <br/>
                        <br/>
                        ~ Instant Rarity Ranks <br/>
                        &emsp; - rarity calculation includes normalization (like Rarity Tools) <br />
                        &emsp; - detailed breakdown of the total score <br />
                        &emsp; - sort by ranks and traits combined <br />
                        <br/>
                        ~ Extensive search and sort capabilities <br/>
                        &emsp; - each trait type is scored and can be used as a filter<br />
                        &emsp; - search accross IDs and trait types combinations <br/>
                    </p>
                </Container>
            </Col>
            <Col>
                <h2>
                    Screenshots
                </h2>
                <Carousel dynamicHeight={true}>
                    <div>
                        <img alt="" src="screenshots/rarity/1.png"/>
                        <p className="legend">Status and Actions</p>
                    </div>
                    <div>
                        <img alt="" src="screenshots/rarity/2.png"/>
                        <p className="legend">Sorting options</p>
                    </div>
                    <div>
                        <img alt=""src="screenshots/rarity/3.png"/>
                        <p className="legend">Detailed score breakdown</p>
                    </div>
                    <div>
                        <img alt=""src="screenshots/rarity/4.png"/>
                        <p className="legend">CYBB Button direc to MM</p>
                    </div>
                    <div>
                        <img alt=""src="screenshots/rarity/5.png"/>
                        <p className="legend">sort by traits and fetch prices</p>
                    </div>
                    <div>
                        <img alt=""src="screenshots/rarity/6.png"/>
                        <p className="legend">Traits dropdown</p>
                    </div>
                </Carousel>
            </Col>
        </Row>
    </Container>
    )
}