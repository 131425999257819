import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export type MetadataAttribute = {
    trait_type: string;
    value: any;
    display_type?: string;
    percentage?:Number;
    rarScore?:number;
} 

export type TokenMetadata = {
    id: number;
    name: string;
    description: string;
    image: string;
    attributes?: MetadataAttribute[];
    tokenIndex?: number;
    attributeCount?: number;
    rarityScore?: number;
    rarityRank?: number;
    rarScore?: number;
    lowestPrice? : number;
}

export type TokenAttributes = {
    [key: string]: TraitType
}

export type TraitType = {
    name: string;
    amount?: number;
    values: TraitValue[];
    percentage?: number;
}

export type TraitValue = {
    name: string;
    amount: number;
    percentage?: number;
    score?: number; 
    rarScore?: number;
}


export type RarityPreset = {
    name?: string;
    contractAddress : string;
    tokenUrl?: string;
    replacementString?: string;
    minTokenId?: number;
    maxTokenId?: number;
    tokenIdOffset?: number;
    moduloWrap?: boolean;
    delay?: number;
    tokenReplaceURI?: string;
    isBase64?: boolean;
}

const NONE = "<none>"

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

export const fetchWithTimeout = async(resource) => {
    const timeout = 10000
    
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
  
    const response = await fetch(resource, {
      signal: controller.signal  
    });
    clearTimeout(id);
  
    return response;
  }

export const getTokenInfo = async (tokenUrl: string, delay: number) : Promise<TokenMetadata> => {
    await sleep(delay);
    return fetchWithTimeout(tokenUrl).then(response => {
        if (!response.ok) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.json())
    .catch(response =>  null)
} 

export const createTokenUrl = (tokenURL:string, replacementString: string, tokenId: number) => {
    return tokenURL.replace(replacementString, tokenId.toString())
}

export const calculateRarities = async (tokenDatas: TokenMetadata[],  setTokenData, setDistribution, forceUpdate) => {
    let rarities: TokenAttributes = {
        traitCount: {
            name: 'TraitCounts',
            values: []
        }
    }
    const dataLen = Object.values(tokenDatas).length
    tokenDatas.forEach((tokenData) => {
        if (!(tokenData.attributes)){return}
        const cnt =rarities.traitCount.values.find((traitValue) => traitValue.name === Object.values(tokenData.attributes).length.toString())
        if (cnt) {
            cnt.amount++
        }
        else {
            rarities.traitCount.values.push({
                name: Object.values(tokenData.attributes).length.toString(),
                amount: 1
            })
        }
        tokenData.attributes.forEach((attr) => {
            if (!(attr.trait_type && attr.value)) return
            const attribute = {trait_type:attr.trait_type.replace(/\s|:/g, ''), value:  attr.value ? attr.value.toString().replace(/\s|:/g, ''): NONE}
            
            if (rarities[attribute.trait_type]) {
                rarities[attribute.trait_type].amount++
                const curr = rarities[attribute.trait_type].values.find((attri) => attri.name === attribute.value)
                if (curr) {
                    curr.amount++
                } else {
                    rarities[attribute.trait_type].values.push({
                        name: attribute.value,
                        amount: 1,
                        score: 0
                    })
                }
            } else {
                rarities[attribute.trait_type] = {
                    name:  attribute.trait_type,
                    amount: 1,
                    percentage: 1 / (dataLen),
                    values: [{
                        name: attribute.value,
                        amount: 1,
                        score: 0
                    }]     
                }          
        
            }
        })
    })
    const attributeNames = Object.keys(rarities).filter(tType => tType !== 'traitCount');
    tokenDatas.forEach((tokenData) => {
        if (!tokenData.attributes) return
        const existingAttr = tokenData.attributes.map(att => att.trait_type?.toString().replace(/\s|:/g, '')).filter(tType => tType !== 'traitCount');
        tokenData.attributeCount = Object.values(tokenData.attributes).length
        if (!(tokenData.attributes.map(att => att.trait_type).find(tType => tType === 'traitCount'))) tokenData.attributes.push({
            trait_type: 'traitCount',
            value: Object.values(tokenData.attributes).length
        })
        attributeNames.forEach((attrName => {
            if (!(existingAttr.includes(attrName))) tokenData.attributes.push({trait_type : attrName, value : NONE})
        }))
    })
    setDistribution(rarities);
    const distribution = rarities
    console.log(distribution);
    var maxSize = 0;
    Object.keys(distribution).forEach((key) => {
        var count = distribution[key].values.length
        if (count > maxSize) maxSize = count
    })
    Object.keys(distribution).forEach((key) => {
        distribution[key].percentage = distribution[key].amount / (dataLen)
        if (key !== 'traitCount' && (distribution[key].amount !== (dataLen))) distribution[key].values.push({
            name: NONE,
            amount:  (dataLen) - distribution[key].amount
        })
        distribution[key].values.forEach((traitValue) => {
            traitValue.percentage = traitValue.amount / distribution[key].amount
            traitValue.score =traitValue.percentage * distribution[key].percentage;
            traitValue.rarScore = 1 / (traitValue.amount / dataLen) * (10 / distribution[key].values.length);
            // traitValue.rarScore = (1 / (traitValue.amount / dataLen)) / (distribution[key].values.length / Object.keys(distribution).length)
            // traitValue.rarScore = (1 / (traitValue.amount / dataLen)) / (distribution[key].values.length / 7)
        })
        distribution[key].values.sort((a, b)=> {return a.score - b.score})
    })
    distribution.traitCount.values.forEach((tCount) => {
        tCount.percentage = tCount.amount / (dataLen)
        tCount.score = tCount.percentage
        tCount.rarScore = 1 / (tCount.amount / dataLen) * (10 / distribution.traitCount.values.length)
        // tCount.rarScore = 1 / (tCount.amount / dataLen) / (distribution.traitCount.values.length /Object.keys(distribution).length )
        // tCount.rarScore = 1 / (tCount.amount / dataLen) / (distribution.traitCount.values.length / 7 )

    })
    distribution.traitCount.values.sort((a, b)=> {return a.score - b.score})
    setDistribution(distribution);
    tokenDatas.forEach((token) => {
        let rarScore = 0;
        let attCount;
        if (!token.attributes) return
        token.attributes.forEach((att)=> {
            if (!(att.trait_type && att.value)) return
            if (att.trait_type === 'traitCount') {attCount = att.value}
            const attribute = {trait_type:att.trait_type.replace(/\s|:/g, ''), value: att.value ? att.value.toString().replace(/\s|:/g, ''): NONE}
            const thisattr = distribution[attribute.trait_type].values.find((trait) => trait.name === attribute.value)
            att.percentage = thisattr.score
            rarScore += thisattr.rarScore;
            att.rarScore = thisattr.rarScore;
        })
        token.attributes.sort( (a, b) => {return b.rarScore - a.rarScore})
        token.rarScore = rarScore
    })
    // tokenDatas.sort( (a, b) => {return a.rarityScore - b.rarityScore})
    // tokenDatas.forEach((token, i) => token.rarityRank = i + 1);
    tokenDatas.sort( (a, b) => {return b.rarScore - a.rarScore})
    tokenDatas.forEach((token, i) => token.rarityRank = i + 1);
    setTokenData(tokenDatas); 
    forceUpdate();
}

export const combineAttributes = (tokenDatas: TokenMetadata[]) => {
    let rarities: TokenAttributes = {
        traitCount: {
            name: 'TraitCounts',
            values: []
        }
    }
            const newData = tokenDatas.map(tokenData => {
            const bareTokenData = {
                attributes: tokenData.attributes ?? null,
                image: tokenData.image ?? null,
                name: tokenData.name ?? null,
                id : tokenData.id ?? null,
                tokenIndex : tokenData.tokenIndex ?? null
            }
            return bareTokenData;
        })
        // database.ref('contractMetadata/' + address + '/tokenData').set(newData)
    const dataLen = Object.values(tokenDatas).length
    tokenDatas.forEach((tokenData) => {
        if (!tokenData.attributes || !(tokenData.attributes.length > 0)){return}
        const cnt =rarities.traitCount.values.find((traitValue) => traitValue.name === Object.values(tokenData.attributes).length.toString())
        if (cnt) {
            cnt.amount++
        }
        else {
            rarities.traitCount.values.push({
                name: Object.values(tokenData.attributes).length.toString(),
                amount: 1
            })
        }
        tokenData.attributes.forEach((attr) => {
            if (!attr.trait_type && attr.value) {
                attr.trait_type = 'unnamed'
            }else if (attr.trait_type && !attr.value) {
                attr.value = NONE
            }
            else if  (!(attr.trait_type && attr.value)) {return}
            const attribute = {trait_type:attr.trait_type?.replace(/\s|:/g, ''), value:  attr.value ? attr.value?.toString().replace(/\s|:/g, ''): NONE}
            if (rarities[attribute.trait_type]) {
                rarities[attribute.trait_type].amount++
                const curr = rarities[attribute.trait_type].values.find((attri) => attri.name === attribute.value)
                if (curr) {
                    curr.amount++
                } else {
                    rarities[attribute.trait_type].values.push({
                        name: attribute.value,
                        amount: 1,
                        score: 0
                    })
                }
            } else {
                rarities[attribute.trait_type] = {
                    name:  attribute.trait_type,
                    amount: 1,
                    percentage: 1 / (dataLen),
                    values: [{
                        name: attribute.value,
                        amount: 1,
                        score: 0
                    }]     
                }          
        
            }
        })
    })
    const attributeNames = Object.keys(rarities).filter(tType => tType !== 'traitCount');
    tokenDatas.forEach((tokenData) => {
        if (!tokenData.attributes || !(tokenData.attributes.length > 0)){return}
        const existingAttr = tokenData.attributes.map(att => att.trait_type?.toString().replace(/\s|:/g, '')).filter(tType => tType !== 'traitCount');
        tokenData.attributeCount = Object.values(tokenData.attributes).length
        if (!(tokenData.attributes.map(att => att.trait_type).find(tType => tType === 'traitCount'))) tokenData.attributes.push({
            trait_type: 'traitCount',
            value: Object.values(tokenData.attributes).length
        })
        attributeNames.forEach((attrName => {
            if (!(existingAttr.includes(attrName))) tokenData.attributes.push({trait_type : attrName, value : NONE})
        }))
    })
    const distribution = rarities
    Object.keys(distribution).forEach((key) => {
        distribution[key].percentage = distribution[key].amount / (dataLen)
        if (key !== 'traitCount' && (distribution[key].amount !== (dataLen))) distribution[key].values.push({
            name: NONE,
            amount:  (dataLen) - distribution[key].amount
        })
        distribution[key].values.forEach((traitValue) => {
            traitValue.percentage = traitValue.amount / distribution[key].amount
            traitValue.score =traitValue.percentage * distribution[key].percentage;
            traitValue.rarScore = 1 / (traitValue.amount / dataLen) * (10 / distribution[key].values.length);
        })
        distribution[key].values.sort((a, b)=> {return a.score - b.score})
    })
    distribution.traitCount.values.forEach((tCount) => {
        tCount.percentage = tCount.amount / (dataLen)
        tCount.score = tCount.percentage
        tCount.rarScore = 1 / (tCount.amount / dataLen)  * (10 / distribution.traitCount.values.length);
    })
    distribution.traitCount.values.sort((a, b)=> {return a.score - b.score})
    const baseAttributeProps = Object.values(distribution).map(trait_type => 
        {
            return {
                name: trait_type.name,
                amount: trait_type.amount,
                values: trait_type.values.map(val=>[val.name, val.amount, Number(val.rarScore.toPrecision(6))])
            }
        })
    const shortTokenData = []
    console.log(baseAttributeProps);

    tokenDatas.forEach((token) => {
        if (!token.attributes) return
        let score = 0
        const attributesIndexes = baseAttributeProps.map(baseProp =>  {
            const traitName = baseProp.name === 'TraitCounts' ? 'traitCount' : baseProp.name

            const topType = token.attributes.filter(n=>n).find(att => {
                if (!att.trait_type && att.value) {
                    att.trait_type = 'unnamed'
                }
                return (att.trait_type && att.value) && (att.trait_type.replace(/\s|:/g, '') === traitName)
            })
            const indexFound = baseProp.values.map(val => val[0]).indexOf(topType.value.toString().replace(/\s|:/g, ''))
            score += Number(baseProp.values[baseProp.values.map(val => val[0]).indexOf(topType.value.toString().replace(/\s|:/g, ''))][2])
            return indexFound;
        })
        const short = [
            token.id,
            Number(score.toPrecision(6)),
            ...attributesIndexes
        ]
        shortTokenData.push(short);
    })
    return {baseAttributeProps : baseAttributeProps, shortTokenData: shortTokenData }
}