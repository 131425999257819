import React, { useEffect, useState } from 'react'
import { RarityPreset, TokenMetadata, TokenAttributes, fetchWithTimeout, calculateRarities, combineAttributes } from '../utilities/rarities'
import {Card, Container, Row, Col, Button, Collapse, InputGroup, OverlayTrigger, Popover, FormControl, Spinner, Dropdown, DropdownButton, ButtonGroup, Form, FloatingLabel } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, connectDatabaseEmulator, get, set } from 'firebase/database';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Web3 from 'web3';
import Web3Utils from 'web3-utils';
import { getContractAbi, stdAbi } from './bot_helpers';
import { fetchContractInfo } from '../utilities/helpers';
import axios from "axios";
import { Card as MUICard } from '@mui/material'
import { MenuItemUnstyled } from '@mui/base';


const firebaseConfig = {
    apiKey: "AIzaSyCwbBN1kYtu5544pvet_9wgrhfAD3trVEg",
    authDomain: "cyberbabies-4781d.firebaseapp.com",
    databaseURL: "https://cyberbabies-4781d-default-rtdb.firebaseio.com",
    projectId: "cyberbabies-4781d",
    storageBucket: "cyberbabies-4781d.appspot.com",
    messagingSenderId: "482371976310",
    appId: "1:482371976310:web:0b62806d3ff19cd976b357",
    measurementId: "G-MS6NGCBPY6"
  };

const OS_API_KEY = "7c2daba2e5564f5dab59a4c4763292b5"

const adminAddys = [
    "0x4cF150b0116d503bF1Aa79d0EA64e2F8b21136cD",
    "0xAC3c837D67B4F638C0ABBF711327CFd33149Cf3D",
    "0xfF174799aD2cC7F777550178951E6f20f9dca3cD",
    "0xa81A3127BD9E757EC6c16e92a3D1778D3F428649",
    "0x62B5bD7F04Fe783796810b2526470dfdE73dAA49",
]
const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const LOCALHOST = 'localhoadfadfst'

const app = initializeApp(firebaseConfig);
let db;
if (window.location.hostname === LOCALHOST) {
  console.log("testing locally -- hitting local functions and firestore emulators");
//   db = getDatabase(app, "http://localhost:3006/?ns=cyberbabies-4781d-default-rtdb")
db = getDatabase(app);
connectDatabaseEmulator(db, "localhost",3006)
} else {
  db = getDatabase(app)
}
const etherscanAbiBaseUrl = "https://api.etherscan.io/api?module=contract&action=getabi&address="
const etherscanAbiEndUrl = "&format=raw"
const alchemy_key = "https://eth-mainnet.alchemyapi.io/v2/6QVACdRsVCmi_P-yNwRIcA00ifT6JCwT"
const cloudfare_eth = "https://cloudflare-eth.com"

type TokenFilter = {
    name: string,
    function?: TokenFilterFunction
}

type TokenFilterFunction = {
    (token: TokenMetadata) : boolean
}

const displayDate = (dateNumber) => {
    const myDate = new Date(0);
    myDate.setUTCSeconds(dateNumber);
    return myDate.toLocaleTimeString();
}

const LoadingSpinner = (props) => {
    return (
        props.loading ? 
        <Spinner animation="border" role="status" size="sm" variant="info">
            <span className="visually-hidden"> Loading...</span>
        </Spinner> 
        : <span className="visulally-hidden">_ </span>
    )
}

const basePreset : RarityPreset = {
    name: "CyberBabies",
    contractAddress: "0x991A546A167cEb2a6a7C344C9D85269Ac03035D9",
    tokenUrl: "https://cyberbabies.io/api/token/REPLACE",
    replacementString: "REPLACE",
    minTokenId: 1,
    maxTokenId: 500,
    tokenIdOffset: 0,
    delay: 20
}

const OSBaseUrl = "https://api.opensea.io/wyvern/v1/orders?";
const OSExtraSettings = "&bundled=false&include_bundled=false&include_invalid=false&side=1&sale_kind=0&limit=50&offset=0&order_by=eth_price&order_direction=asc";

const useForceUpdate = () => {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

const isValidRarityRun= (rarityRun: RarityPreset) => {
    if (rarityRun.name && rarityRun.contractAddress && rarityRun.tokenUrl && rarityRun.replacementString) return true
    return false;
}



export const RarityBotApp = (props) => {
    const [contractMetadata, setContractMetadata] = useState<TokenMetadata[]>([])
    const [rawData, setRawData] = useState<TokenMetadata[]>([])
    const [tokenDistribution, setTokenDistribution] = useState<TokenAttributes>({
        traitCount: {
            name: 'TraitCounts',
            values: []
        }
    })
    const [fetchType, setFetchType] = useState("cloud");
    const [rarityRun, setRarityRun] = useState<RarityPreset>();
    const [tokenCount, setTokenCount] = useState(0);
    const [presets, setPresets] = useState<RarityPreset[]>([]);
    const [rankingFilter, setRankingFilter] = useState(1);
    const [bottomRankingFilter, setBottomRankingFilter] = useState(0);
    const [showMaxTokens, setShowMaxTokens] = useState(50);
    const [filters, setFilters] = useState<TokenFilter[]>([]);
    const [fetchStarted, setFetchStarted] = useState(false);
    const [errorTokens, setErrorTokens] = useState([])
    const [osToggled, setOSToggled] = useState(false)
    const [showCustomTokens, setShowCustomTokens] = useState([])
    const forceUpdate = useForceUpdate();
    const [loading, setLoading] = useState(false);
    const [imagesShow, setImagesShow] = useState(false);
    const [cfListValues, setCfListValues] = useState({} as any);
    const [fetchInterval, setFetchInterval] = useState()
    const [failedTokenCount, setFailedTokenCount] = useState(0);
    const [existingCollections, setExistingCollections] = useState({} as any);
    const [topListingsFetched, setTopListingsFetched] = useState(false);
    const [toggleSortByPrice, setToggleSortByPrice] = useState(false);
    const [page, setPage] = useState(1);

    const [collectionSearchValue, setCollectionSearchValue] = useState('');
    const userAccount = props.account;
    const web3 = props.web3;
    const { ethereum } = window as any;

    const getAllSettings = ():RarityPreset=> {
        return {
            contractAddress: (document.getElementById('contractAddress') as HTMLInputElement).value.trim().toLowerCase(),
            minTokenId: Number((document.getElementById('minTokenId') as HTMLInputElement).value.trim()),
            maxTokenId: Number((document.getElementById('maxTokenId') as HTMLInputElement).value.trim()),
        }
    }  

    const getDisplayedTokenIds = () => {
        const ids = Array.from(document.querySelectorAll('div.card-header a.cybb-link')).map(element=> element.id);
        return ids
    }

    const addToFilters = (addFilter:TokenFilter) => {
        const [thisTraitType, thisTraitValue] = addFilter.name.split('_&&_')
        setFilters([...filters.filter((fil) => !fil.name.includes(thisTraitType)), addFilter]);
    }

    const removeFromFilters = (removeFilter: TokenFilter) => {
        setFilters(filters.filter((fil) => fil.name !== removeFilter.name))
    }

    const removeAllTraitFilters = () => {
        setFilters([]);
        Array.from(document.querySelectorAll('input')).filter((el) => (el.type === "checkbox" && el.checked)).forEach((el)=>el.checked=false)

    }

    const toggleImages = () => {
        setImagesShow(current => !current)
    }

    const toggleOSFilter = async () =>{
        const OsFilter = filters.find((fil) => fil.name === "OSListings")
        if (OsFilter){
            setFilters(filters.filter((fil) => fil.name !== "OSListings"))
            setOSToggled(false)
        } else {
            const osfunction = {name: "OSListings", function: (token) => !!token.lowestPrice}
            setFilters([...filters, osfunction])
            setOSToggled(true);
        }
        forceUpdate();
    }


    const changeCustomTokenDisplay = (fieldValue: string) => {
        console.log(fieldValue)
        const splitValue = fieldValue.split('-')
        const commaValue = fieldValue.split(/\s|,/g);
        if(splitValue.length > 1) {
            var list = [];
            for (var i = Number(splitValue[0]); i<=Number(splitValue[1]); i++) {
                list.push(i)
            }
            setShowCustomTokens(list)
            return
        }
        else if(commaValue.length > 1) {
            setShowCustomTokens(commaValue.map(st => Number(st)))
            return
        }
        else if (Number(fieldValue)){
            setShowCustomTokens([Number(fieldValue)])
            return
        }
        else {
            setShowCustomTokens([])
        }

    }

    const runRarityCalculation = () => {
        toast('calculating rarities...')
        console.log(rawData);
        calculateRarities(rawData, setContractMetadata, setTokenDistribution, forceUpdate)
        // combineAttributes(rawData)
        toast('rarities have been updated')
    }

    const sendCloudFetch = async () => {
        const rarityRunSettings =  rarityRun ?? getAllSettings() as RarityPreset;
        if (!rarityRunSettings.contractAddress) {
            toast('Contract address is missing')
            return
        }
        const isAddy = Web3Utils.isAddress(rarityRunSettings.contractAddress);
        if (!isAddy) {
            toast('Not a valid contract address...')
            return
        }
        // toast('fetching contract ABI...')
        // let abi = null;
        // try {
        //     abi = await getContractAbi(rarityRunSettings.contractAddress)
        // } catch(error) { toast("ABI could not be loaded, continuing with std ABI")}
        toast('fetching settings from contract')
        const web3Contract = new web3.eth.Contract(stdAbi as any, rarityRunSettings.contractAddress)
        let totalSupplyFunction
        let tokenByIndexFunction
        let totalSupply;
        let minTokenId;
        let tokenReplaceURI;
        let isBase64 = false;
        let indexFunctionExists;
  
        try {
            totalSupply = await web3Contract.methods.totalSupply().call();
        } catch {
            if (!rarityRunSettings.maxTokenId) {
                toast('TotalSupply not found, please provide max token id')
                return
            } else {
                totalSupply = rarityRunSettings.maxTokenId
            }
        }

        try {
            indexFunctionExists = await web3Contract.methods.tokenByIndex(0).call();
        } catch (e) {
          indexFunctionExists = false
        }
        console.log(indexFunctionExists);
        if (indexFunctionExists) {
            minTokenId = 0
            toast('contract has token indexing enabled')
        } else {
            try {
                const tokenUrl = await web3Contract.methods.tokenURI(1).call();
                minTokenId = 1
                if (tokenUrl.includes('http')) {
                  const realTokenUrl = tokenUrl.replace('ipfs://', 'https://ipfs.io/ipfs/') 
                } else {
                  isBase64 = true
                }
              } catch (e) {
                  if (!rarityRunSettings.minTokenId) {
                      toast('tokenURI of token ID 1 not found')
                      return
                  } else {
                      minTokenId = rarityRunSettings.minTokenId
                  }
              }
        }

  
        //TODO : logic to get tokenURL

        rarityRunSettings.maxTokenId = totalSupply;
        rarityRunSettings.isBase64 = isBase64
        
        let fetchUrl;
        let initUrl
        if (window.location.hostname === LOCALHOST) {
            fetchUrl = "http://localhost:3005/api/rarityTool/cloudFetch/"
            initUrl = "http://localhost:3005/api/rarityTool/getTokenData/"
        }
        else {
            fetchUrl = 'https://cyberbabies.io/api/rarityTool/cloudFetch/';
            initUrl = 'https://cyberbabies.io/api/rarityTool/getTokenData/'
        }

        toast('requesting cybb cloud data')
        const queries = `?totalSupply=${totalSupply}&minTokenId=${minTokenId}`
        const cloudFetchResponse = await fetch(fetchUrl + rarityRunSettings.contractAddress + queries)
            .then(response => {
                if (!response.ok) {
                    throw Error('couldnt fetch url')
                }
                return response
            }).then(response => response.json() as any)
            .catch(error =>  {console.log(error); return null;})
        if (!cloudFetchResponse) {
            toast('error while requesting cybb cloud data')
            return
        }
        setCfListValues(cloudFetchResponse);
        // const contractAddress = rarityRunSettings.contractAddress.toLowerCase()
        // const contractInfoRef = ref(db, 'contractInfo/'+ contractAddress);
        // const contractDataRef = ref(db, 'contractMetadata/' + contractAddress);
        // const contractCountRef = ref(db, 'contractInfo/'+ contractAddress + '/tokenCount');
        // setLoading(true);
        // await get(contractInfoRef).then((snapshot) => {
        //     if (snapshot.exists()) {
        //         setCfListValues({...snapshot.val(), contractAddress: contractAddress});
        //         // setTokenCount(snapshot.val().tokenCount);
        //     }
        // }).catch(error => toast(error))
        // await get(contractDataRef).then((snapshot)=> {
        //     if (snapshot.exists() && snapshot.val().tokenData) {
        //         console.log(snapshot.val())
        //         if (snapshot.val().tokenData.length) {
        //             console.log('tokendata has length attribute')
        //             setContractMetadata(snapshot.val().tokenData)
        //             setRawData(snapshot.val().tokenData)
        //             calculateRarities(snapshot.val().tokenData, setContractMetadata, setTokenDistribution, forceUpdate)
        //             setTokenCount(Object.keys(snapshot.val().tokenData).length)
        //             set(contractCountRef, Object.keys(snapshot.val().tokenData).length)
        //         } else {
        //             setContractMetadata(Object.values(snapshot.val().tokenData))
        //             setRawData(Object.values(snapshot.val().tokenData))
        //             calculateRarities(Object.values(snapshot.val().tokenData), setContractMetadata, setTokenDistribution, forceUpdate)
        //             setTokenCount(Object.keys(snapshot.val().tokenData).length)
        //             set(contractCountRef, Object.keys(snapshot.val().tokenData).length)
        //         }
        //     }
        //     if (snapshot.exists() && snapshot.val().tokensFailed) {
        //         setFailedTokenCount(Object.keys(snapshot.val().tokensFailed).length);  
        //     }
        // }).catch(error => toast(error))
        setLoading(false);
        setFetchStarted(true);
        setTopListingsFetched(false);
        setRarityRun(rarityRunSettings);
    }

    const loadFromCollection = async(collection) => {
        setLoading(true)
        setFetchStarted(true);
        console.log(collection);
        setRarityRun({
            contractAddress: collection.contractAddress,
            maxTokenId : collection.totalSupply,
            minTokenId : collection.minTokenId
        })
        setCfListValues(collection);
        setLoading(false);
        setTopListingsFetched(false);
    }

    const refreshMeta = async (verifyCount:boolean = false) => {
        if ( rarityRun &&
             rarityRun.contractAddress && 
             cfListValues)
             {
            const contractAddress = rarityRun.contractAddress.toLowerCase()
            const contractInfoRef = ref(db, 'contractInfo/'+ contractAddress);
            const contractDataRef = ref(db, 'contractMetadata/' + contractAddress);
            const contractCountRef = ref(db, 'contractInfo/'+ contractAddress + '/tokenCount');
            let countChanged = true
            if (verifyCount){
                await get(contractCountRef).then(data => {
                    if (data.exists() && Number(data.val()) === Number(tokenCount) ){
                        toast('Counts have not changed, metadata is currently accurate')
                        countChanged = false
                    }
                    
                })
            }
            if(!countChanged) return
            setLoading(true);
            await get(contractInfoRef).then((snapshot) => {
                if (snapshot.exists()) {
                    setCfListValues({...snapshot.val(), contractAddress: rarityRun.contractAddress});
                    // setTokenCount(snapshot.val().tokenCount);
                }
            }).catch(error => toast(error))
            await get(contractDataRef).then((snapshot)=> {
                if (snapshot.exists() && snapshot.val().tokenData) {
                    console.log(snapshot.val())
                    if (snapshot.val().tokenData.length) {
                        setContractMetadata(snapshot.val().tokenData)
                        setRawData(snapshot.val().tokenData)
                        calculateRarities(snapshot.val().tokenData, setContractMetadata, setTokenDistribution, forceUpdate)
                        setTokenCount(Object.keys(snapshot.val().tokenData).length)
                        set(contractCountRef, Object.keys(snapshot.val().tokenData).length)
                    } else {
                        setContractMetadata(Object.values(snapshot.val().tokenData))
                        setRawData(Object.values(snapshot.val().tokenData))
                        calculateRarities(Object.values(snapshot.val().tokenData), setContractMetadata, setTokenDistribution, forceUpdate)
                        setTokenCount(Object.keys(snapshot.val().tokenData).length)
                        set(contractCountRef, Object.keys(snapshot.val().tokenData).length)
                    }
                }
                if (snapshot.exists() && snapshot.val().tokensFailed) {
                    setFailedTokenCount(Object.keys(snapshot.val().tokensFailed).length);  
                }
            }).catch(error => toast(error))
            
        }
        setLoading(false);
        setTopListingsFetched(false);
        forceUpdate();
    }

    const resetContract = async (rarRun, cfListValues, authorizedUser) => {
        const web3Contract = new web3.eth.Contract(stdAbi as any, rarRun.contractAddress)
        const totalSupply = await web3Contract.methods.totalSupply().call();
        if (rarRun && rarRun.contractAddress){
            if (!authorizedUser) {
                if (totalSupply === cfListValues.totalSupply && cfListValues.status !== 'verifyingValidity'){
                    toast('totalSupply matches up, request to reset contract has been rejected')
                    return
                }
            }
            const resetResponse = await fetch("https://cyberbabies.io/api/rarityTool/cloudFetch/" + rarRun.contractAddress + "?action=reset&totalSupply="+ totalSupply)
                .then(response => {
                    if (!response.ok) {
                        throw Error('couldnt fetch url')
                    }
                    return response
                }).then(response => response.json() as any)
                .catch(error =>  {console.log(error); return null;})
            if (resetResponse.status) {
                toast('reset contract successful');
                await sleep(1000)
                toast('requesting new data')
                await sleep(4000)
                sendCloudFetch()
            }
        }
    }

    const retryContract = async (rarRun) => {
        if (rarRun && rarRun.contractAddress){
            toast('sending retry request...')
            const resetResponse = await fetch("https://cyberbabies.io/api/rarityTool/cloudFetch/" + rarRun.contractAddress + "?action=retryFailedTokens&totalSupply="+ rarRun.maxTokenId)
                .then(response => {
                    if (!response.ok) {
                        throw Error('couldnt fetch url')
                    }
                    return response
                }).then(response => response.json() as any)
                .catch(error =>  {console.log(error); return null;})
            if (resetResponse.status) {
                toast('request approved, retrying failed tokens...');
            }
        }
    }

    const forceFetch = async (rarRun) => {
        if (rarRun && rarRun.contractAddress){
            const resetResponse = await fetch("https://cyberbabies.io/api/rarityTool/cloudFetch/" + rarRun.contractAddress + "?action=forceFetch&totalSupply="+ rarRun.maxTokenId)
                .then(response => {
                    if (!response.ok) {
                        throw Error('couldnt fetch url')
                    }
                    return response
                }).then(response => response.json() as any)
                .catch(error =>  {console.log(error); return null;})
            if (resetResponse.status) {
                toast('force fetching tokens...');
            }
        }
    }

    const deleteTokens = async (rarRun) => {
        if (rarRun && rarRun.contractAddress){
            const resetResponse = await fetch("https://cyberbabies.io/api/rarityTool/cloudFetch/" + rarRun.contractAddress + "?action=deleteTokens&totalSupply="+ rarRun.maxTokenId)
                .then(response => {
                    if (!response.ok) {
                        throw Error('couldnt fetch url')
                    }
                    return response
                }).then(response => response.json() as any)
                .catch(error =>  {console.log(error); return null;})
            if (resetResponse.status) {
                toast('deleting tokens...');
            }
        }
    }

    const fetchMissingTokens = async (rarRun: RarityPreset, cfListValues, authorizedUser) => {
        console.log(cfListValues);
        if (rarRun && rarRun.contractAddress && rarRun.minTokenId !== undefined && rarRun.maxTokenId){
            console.log(cfListValues);
            if (cfListValues.status !== 'valid') {
                toast('can only fetch missing tokens of valid collections')
            }else {

                const missingResponse = await fetch("https://cyberbabies.io/api/rarityTool/cloudFetch/" + rarRun.contractAddress + "?action=fetchMissingTokens&minTokenId="+rarRun.minTokenId+"&totalSupply="+ rarRun.maxTokenId)
                    .then(response => {
                        if (!response.ok) {
                            throw Error('couldnt fetch url')
                        }
                        return response
                    }).then(response => response.json() as any)
                    .catch(error =>  {console.log(error); return null;})
                if (missingResponse.status && missingResponse.amountSent) {
                    toast(`requested ${missingResponse.amountSent} to be fetched`)
                    await sleep(4000)
                    refreshMeta()
                }
            }
        }
    }

    const fetchListingData = (rarity)=>{
        fetchModuleData(rarity);
    }

    const fetchModuleData = async(rarity) => {
        const toastId = toast.loading('Fetching listing information');
        const ModuleListingsURL = "https://api.modulenft.xyz/api/v2/eth/nft/listings"
        const contractInfo = await fetchContractInfo(rarity.contractAddress)
        let collectionSlug = undefined
        if (contractInfo && contractInfo['collection']) {
            collectionSlug =  contractInfo['collection']['slug']
        }
        const url = ModuleListingsURL + '?slug=' + collectionSlug
        const results = await axios.get(url)
        .then(response => {
            if (response.status !== 200) {
                throw Error('couldnt fetch url')
            }
            return response
        }).then(response => response.data)
        .catch(error =>  {
            console.log(error)
        })
        console.log(results);
        if(!results.error) {
            setContractMetadata(currentData => {
                const resetData = currentData.map(t => ({...t, lowestPrice: undefined}));
                results['data'].forEach((listing) => {
                    const tokenId = Number(listing['tokenId']);
                    const price = listing['price']
                    const matchingToken = resetData.find((tok) => tok?.id && Number(tok.id) === Number(tokenId))
                    if (matchingToken) matchingToken.lowestPrice = price;
                })
                return resetData;
            })
            forceUpdate()
        }
        toast.done(toastId)
        toast.dismiss(toastId)

    }

    

    const fetchOSData = async (rarity) => { 
        const displayed = getDisplayedTokenIds().map(id=>Number(id));
        let toFetch = displayed
        const perChunk = 25;
        const result = toFetch.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index/perChunk);

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []
            }
            resultArray[chunkIndex].push(item)
            return resultArray
        }, [])
        result.forEach(async (disa, index) => {
            let tokenIdsUrl = "";
            disa.forEach((tokenId, i) => {
                if (i < perChunk) {
                    tokenIdsUrl += `&token_ids=${tokenId}`
                }
            })
            const contractUrl = OSBaseUrl + `asset_contract_address=${rarity.contractAddress}` + tokenIdsUrl + OSExtraSettings;
            let response = null
            console.log(contractUrl.length)
            let delay = 2500
            let count = 1
            while(!response) {
                console.log(delay)
                console.log(count)
                console.log(index);
                let sleepTime = (delay * count * (index+1)) - delay
                console.log(sleepTime)
                console.log('--------------------------------')
                await sleep(sleepTime)
                response = await fetch(contractUrl, {
                    headers: {
                        'X-API-KEY' : OS_API_KEY
                    }
                }).then(response => {
                    if (!response.ok) {
                        throw Error('couldnt fetch url')
                    }
                    return response
                }).then(response => response.json()).catch(response =>  null)
                if(response) {
                    response.orders.forEach((listing, i, array) => {
                        if ( i > 0 && listing.asset.token_id === array[i-1].asset.token_id) return
                        const tokenId = Number(listing.asset.token_id);
                        const price = Number(listing.current_price) / 1000000000000000000;
                        const matchingToken = contractMetadata.find((tok) => Number(tok.id) === Number(tokenId))
                        if (matchingToken && !(matchingToken.lowestPrice && matchingToken.lowestPrice <price)) matchingToken.lowestPrice = price;
                        
                    })
                    forceUpdate()
                } else {
                    toast(`opensea is throttling, retry in ${(delay*count)/1000}s`, {toastId: count})
                    
                    count += 1
                }
            }
            
        })
        

    }

    useEffect(()=>{
        const contractInfoRef = ref(db, 'contractInfo');
        get(contractInfoRef).then(snapshot => {
            const validContracts = Object.entries(snapshot.val())
                        .map( (value) => {return {...value[1] as any, contractAddress: value[0] }})
                        .filter((col) => col['status'] === 'valid')
                        .sort((a, b) => new Date(b['lastFetchTime']).getTime() - new Date(a['lastFetchTime']).getTime())
            setExistingCollections(validContracts)
        })
        setToggleSortByPrice(false)
    }, [])

    useEffect(()=>{
        forceUpdate();
    }, [contractMetadata, tokenDistribution, filters]);

    useEffect(()=>{
        refreshMeta();
    },[rarityRun])

    return (
        <>  
            <ToastContainer pauseOnHover={false} pauseOnFocusLoss={false} position="top-left" theme="dark"/>
            <Container  style={{marginTop: "5vh"}}>
                <Row className="justify-content-md-center">
                    <Col md="auto"> <p> Version : 0.3.8</p></Col>
                </Row>
                <Row xs={3} md={3} lg={3} >
                    <Col>

                        <Dropdown focusFirstItemOnShow={true}>
                            <Dropdown.Toggle disabled={false} size="lg" className="btn-info btn-custom" id="contractSelection">
                                {fetchStarted ? cfListValues.name : "Load Collection"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu >
                                
                                <Dropdown.Item as={FormControl} placeholder="Search" onChange={ (e) => {
                                    setCollectionSearchValue((e.target as any).value)
                                }} >
                                 
                                    {/* <FormControl placeholder="Search" onClick={(e) => {e.preventDefault();console.log('clicked')}} /> */}
                                </Dropdown.Item>
                                {existingCollections.length ? 
                                    existingCollections
                                    .filter(col => !collectionSearchValue || col['name'].toLowerCase().includes(collectionSearchValue.toLowerCase()))
                                    .slice(0,15)
                                    .map((collection) => 
                                        <Dropdown.Item as="button" onClick={()=>{loadFromCollection(collection)}} eventKey={collection['name']}>{collection['name']}</Dropdown.Item>
                                    )
                                    : <></>}
                            </Dropdown.Menu>
                        </Dropdown>
                        {!fetchStarted ?
                            <>
                            <br />
                            <h4> OR</h4>
                            <div>
                                <input style={{width: "300px"}} placeholder="Contract Address" type="text" id="contractAddress"></input>
                                <input style={{width: "300px"}} placeholder="Min Token Id (optional)" type="text" id="minTokenId"></input>
                                <input style={{width: "300px"}} placeholder="Max Token Id (optional)" type="text" id="maxTokenId" ></input>
                            </div>
                            <Button style={{ width: "300px"}} onClick={()=>sendCloudFetch()}  className="btn-info btn-custom" disabled={fetchStarted}> Cloud Fetch</Button>

                            </>
                        : null}
                    </Col>
                    <Col style={{textAlign: "left"}}>
                        <h6>Name: {cfListValues.name}</h6>
                        <h6>Symbol: {cfListValues.symbol}</h6>
                        <h6>Status: {cfListValues.status}</h6>
                        <h5>Supply: {cfListValues.totalSupply} </h5>
                        <h5>Count <LoadingSpinner loading={loading} />: {tokenCount}</h5>
                        {(cfListValues.contractAddress) 
                            ? ( <h6>
                                    Contract: &nbsp;
                                    <a rel="noreferrer" className="cybb-link" target="_blank" href={`https://etherscan.io/address/${cfListValues.contractAddress}`}>View</a>
                                    &nbsp;
                                    <a href="javascript:;" onClick={()=>navigator.clipboard.writeText(cfListValues.contractAddress)}><i onClick={()=>navigator.clipboard.writeText(cfListValues.contractAddress)} className="bi-clipboard"></i></a>
                                    
                                </h6>
                                )
                            : <></>}
                        {(failedTokenCount !== 0) ? (<h6> Failed count: {failedTokenCount} </h6>): <></>}
                        <h6>{cfListValues.lastFetchTime ? (cfListValues.lastFetchTime as Date).toLocaleString('en-US'): ''}</h6>

                    </Col>
                    <Col>
                        <Button style={{ width: "200px"}} id="refresMeta" onClick={()=>refreshMeta(true)}  className="btn-info btn-custom" disabled={!fetchStarted}> Refresh Metadata</Button>
                        <br></br>
                        <br></br>
                        <Button style={{ width: "200px"}} onClick={()=> runRarityCalculation()} className="btn-warning" disabled={!fetchStarted}> Calculate Rarity</Button>
                        <br></br>
                        <br></br>

                        {adminAddys.map(addy => addy.toLowerCase()).includes(userAccount)
                        ? (
                        <Dropdown>
                            <Dropdown.Toggle style={{ width: "200px"}} className="btn-info btn-custom" id="contractSelection">
                                Actions
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ width: "200px"}}>
                                <Dropdown.Item as="button" onClick={()=> resetContract(rarityRun, cfListValues, true)} eventKey="1"> Reset Contract </Dropdown.Item>
                                <Dropdown.Item as="button" onClick={()=> retryContract(rarityRun)} eventKey="2"> Retry Failed </Dropdown.Item>
                                <Dropdown.Item as="button" onClick={()=> forceFetch(rarityRun)} eventKey="3"> Force Fetch </Dropdown.Item>
                                <Dropdown.Item as="button" onClick={()=> deleteTokens(rarityRun)} eventKey="4"> Delete Tokens</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={()=> fetchMissingTokens(rarityRun, cfListValues, true)} eventKey="5"> Fetch Missing Tokens </Dropdown.Item>
                                <Dropdown.Item as="button" onClick={()=> refreshMeta()} eventKey="6"> Force Refresh </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        ) : (
                        <>
                        <Dropdown>
                            <Dropdown.Toggle style={{ width: "200px"}} className="btn-info btn-custom" id="contractSelection">
                                Actions
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ width: "200px"}}>
                                <Dropdown.Item as="button" onClick={()=> refreshMeta()} eventKey="1"> Force Refresh </Dropdown.Item>
                                <Dropdown.Item as="button" onClick={()=> fetchMissingTokens(rarityRun, cfListValues, false)} eventKey="2"> Fetch Missing Tokens </Dropdown.Item>
                                <Dropdown.Item as="button" onClick={()=> resetContract(rarityRun, cfListValues, false)} eventKey="3"> Reset Contract </Dropdown.Item>
                                {/* <Dropdown.Item as="button" onClick={()=> retryContract(rarityRun)} eventKey="3"> Retry Failed </Dropdown.Item> */}
                            </Dropdown.Menu>
                        </Dropdown>
                        </>)
                        } 
    
                    </Col>    
                </Row>
                
            </Container>
            <Container>
                { tokenDistribution ? <DistributionCards distribution={tokenDistribution} addToFilters={addToFilters} removeFromFilters={removeFromFilters} /> : <></>}
            </Container>
            <Container style={{paddingTop: "10px"}} className="MintContainer">
                <Row>
                    <Col>
                        <h1>______</h1>
                    </Col>
                </Row>
                <Row style={{marginBottom:"1vh"}}>
                    <Col md="auto"><Button style={{ width: "150px"}} onClick={()=>removeAllTraitFilters()} className="btn-info btn-custom">Reset Traits</Button></Col>
                    <Col md="auto"><Button style={{ width: "150px"}} onClick={()=>toggleImages()} className="btn-info btn-custom">{imagesShow ? "Hide": "Show"} images</Button></Col>
                    
                </Row>
                <Row style={{marginBottom:"1vh"}}>
                    <Col md="auto">
                        <InputGroup>
                            <FormControl id="maxDisplay" defaultValue={showMaxTokens} style={{width: "75px"}} onChange={(event) => setShowMaxTokens(Number(event.target.value))}/> <Button className="btn-info btn-custom" onClick={()=>setShowMaxTokens(Number((document.getElementById("maxDisplay") as HTMLInputElement).value))}>Max</Button>     
                        </InputGroup>
                    </Col>
                    <Col md="auto">
                        <InputGroup>
                                    <FormControl placeholder="x | xx-yy"id="customTokenDisplay"  style={{width: "150px"}}/> <Button className="btn-info btn-custom" onClick={()=>changeCustomTokenDisplay((document.getElementById("customTokenDisplay") as HTMLInputElement).value)}>ID Search</Button>
                                    
                        </InputGroup>
                    </Col>
                    
                </Row>
                <Row style={{marginBottom:"1vh"}}>
                    <Col md="auto"><Button style={{ width: "150px"}} onClick={()=>setToggleSortByPrice(!toggleSortByPrice)}  className="btn-info btn-custom">{toggleSortByPrice ? "Sort by rank" : "Sort By Price"}</Button></Col>
                    <Col md="auto"><Button style={{ width: "150px"}} onClick={()=>toggleOSFilter()}  className="btn-info btn-custom">{osToggled ? 'Show All' : 'Show Listed'}</Button></Col>
                    <Col md="auto"><Button style={{ width: "150px"}} onClick={()=>fetchListingData(rarityRun)}  className="btn-info btn-custom">Fetch Listings</Button></Col>
                </Row>
                <Row style={{marginBottom:"1vh"}}>
                    <Col md="auto"><Button style={{ width: "75px"}} onClick={()=>{setRankingFilter(0.01);setBottomRankingFilter(0)}} className="btn-success">1%</Button></Col>
                    <Col md="auto"><Button style={{ width: "75px"}} onClick={()=>{setRankingFilter(0.05);setBottomRankingFilter(0.01)}} className="btn-warning">1-5%</Button></Col>
                    <Col md="auto"><Button style={{ width: "75px"}} onClick={()=>{setRankingFilter(0.10);setBottomRankingFilter(0.05)}} className="btn-danger">5-10%</Button></Col>
                    <Col md="auto"><Button style={{ width: "100px"}} onClick={()=>{setRankingFilter(1);setBottomRankingFilter(0)}} className="btn-info btn-custom">Reset %</Button></Col>
    
                </Row>
                <Row>
                    <Col md="auto">
                        <InputGroup>
                            <Button disabled={page===1} className="btn-info btn-custom" onClick={()=>setPage((current) => current -1)}>{"<"}</Button>     
                            <FormControl style={{textAlign:"center", width: "50px"}} disabled value={page}/>
                            <Button className="btn-info btn-custom" onClick={()=>setPage((current) => current +1)}>{">"}</Button>     
                        </InputGroup>
                    </Col>
                </Row>
                <Row><p className="pageLabel">page</p></Row>
                <Row>
                    <ShowRankedTokens contractMetadata={contractMetadata}
                                      rarityRunSettings={rarityRun}
                                      filters={filters}
                                      rankingFilter={rankingFilter}
                                      bottomRankingFilter={bottomRankingFilter}
                                      showMaxTokens={showMaxTokens}
                                      showCustomTokens={showCustomTokens}
                                      imagesShow={imagesShow}
                                      toggleSortByPrice={toggleSortByPrice}
                                      page={page}
                                    />
                </Row>
            </Container>
        </>
    )
}

export const ShowRankedTokens = ({contractMetadata, rarityRunSettings, filters, rankingFilter, bottomRankingFilter, showMaxTokens, showCustomTokens, imagesShow, toggleSortByPrice, page}) => {
    let displayedTokens;
    const lowSlice = (page-1) * showMaxTokens
    const highSlice = page * showMaxTokens
    if (showCustomTokens.length > 0) {
        displayedTokens = contractMetadata
            .filter((token) => showCustomTokens.includes(Number(token.id)))
            .filter((token) => filters.map((fil)=> fil.function(token)).every(v => v === true))
            .slice(lowSlice,highSlice)
            .sort((a, b) => {
                if (toggleSortByPrice) {
                    return (a.lowestPrice - b.lowestPrice)
                } else {
                    return 0
                }
            })
    } else {
        displayedTokens = contractMetadata
        .filter((token) => filters.map((fil)=> fil.function(token)).every(v => v === true))
        .filter((token) => !token.rarityRank || (contractMetadata.length * bottomRankingFilter <= token.rarityRank && token.rarityRank  <= contractMetadata.length * rankingFilter))
        .slice(lowSlice,highSlice)
        .sort((a, b) => {
            if (toggleSortByPrice) {
                return (a.lowestPrice - b.lowestPrice)
            } else {
                return 0
            }
        })
    }
    return (
        <>
            {displayedTokens.length > 0 ? displayedTokens
                .map((token) => 
                <TokenCard tokenData={token} rarityRunSettings={rarityRunSettings} imagesShow={imagesShow} key={token.id}/>
             ):
            <Container style={{marginTop: "10vh"}}>
                <Row><h2> no tokens matching criteria</h2></Row>
            </Container>
            }
        </>
    )

}

export const DistributionCard = ({trait, addToFilters, removeFromFilters, collapseAll, setCollapseAll}) => {
    const [displayed, setDisplayed] = useState(false);
    const triggerDisplay = () => {
        setDisplayed(!displayed);
        if (!displayed) setCollapseAll(false);
    }
    const toggleFilter = (event) => {
        const [thisTraitType, thisTraitValue] = event.target.id.split('_&&_')
        let filFunction: TokenFilter;
        let thisFunction;
        if (thisTraitType === 'TraitCounts') {
            thisFunction = (token: TokenMetadata) => {
                let match = false;
                if (token.attributeCount === Number(thisTraitValue)) match =true
                return match
            }
            filFunction = {
                name: "TraitCounts",
                function: thisFunction
            }
        } else {
            thisFunction = (token: TokenMetadata) => {
                let match = false;
                const traitType = token.attributes.find((att) => att.trait_type?.replace(/\s|:/g, '') === thisTraitType)
                if (traitType) {
                    if (traitType.value?.toString().replace(/\s|:/g, '') === thisTraitValue) match = true
                }
                return match
            }
            filFunction= {
                name: event.target.id,
                function: thisFunction
            }
        }
        if (event.target.checked) {
            const allToggled = Array.from(document.querySelectorAll('input')).find((el) => el.id.includes(thisTraitType) && el.checked && el.id !== event.target.id)
            if (allToggled) {
                allToggled.checked = false
            }
            addToFilters(filFunction);

        } else {
            removeFromFilters(filFunction)
        }
    }
    useEffect(()=>{{
        if (collapseAll === true){
            setDisplayed(false);
        }
    }}, [collapseAll])
    return (
        <Col><MUICard className="distribution-header">
            <div className="card-header" onClick={()=>{triggerDisplay()}}>
                 { trait.name } ({Number(trait.percentage).toLocaleString(undefined, {style:"percent", minimumFractionDigits:0} )})
            </div>
            <Collapse in={displayed}>
            <Card.Body style={{maxHeight: "400px", overflow:"auto"}}>
                <table style={{fontSize:"70%"}}>
                <thead>
                    <tr>
                        <th scope="col"> </th >
                        <th scope="col"> value</th>
                        <th scope="col"> percentage</th>
                        <th scope="col"> RAR</th>
                    </tr>
                </thead>
                <tbody>
                {trait.values.map((traitValue)=> 
                    <tr style={{padding: "none"}}>
                        <td><input type="checkbox" onClick={toggleFilter} id={`${trait.name}_&&_${traitValue.name}`}></input> </td>
                        <td>{traitValue.name.slice(0, 10)} </td>
                        <td>{Number(traitValue.percentage).toLocaleString(undefined, {style:"percent", maximumFractionDigits:1} )} </td>
                        {/* <td>{Number(traitValue.score * 100).toLocaleString(undefined, {maximumFractionDigits: 2})}</td> */}
                        <td>{Number(traitValue.rarScore).toLocaleString(undefined, {maximumFractionDigits:0})}</td>
                    </tr>
                )}
                </tbody>
            </table> 
            </Card.Body>
            </Collapse>

        </MUICard></Col>
    )
}

export const DistributionCards = ({distribution, addToFilters, removeFromFilters}) => {
    const [collapseAll, setCollapseAll] = useState(true);
    const cards = Object.keys(distribution).map((key)=> {
        if (distribution[key].values.length)
            return<DistributionCard trait={distribution[key]} addToFilters={addToFilters} removeFromFilters={removeFromFilters} key={key} collapseAll={collapseAll} setCollapseAll={setCollapseAll} />
    }
    )
    return (cards.length > 1 ?
        <>
        <Row><Col><Button className="cybb-collapse" onClick={()=>setCollapseAll(true)}>Collapse All</Button></Col></Row>
        <Row className="no-gutters" md={5}>{cards}</Row>
        <Row><Col><Button className="cybb-collapse" onClick={()=>setCollapseAll(true)}>Collapse All</Button></Col></Row>
        </>
        :<></>
    )
}

export const TokenCard = ({tokenData, rarityRunSettings, imagesShow}) => {
    const determineBgColor = (rarityRank: number) => {

        if (rarityRank <= rarityRunSettings.maxTokenId * 0.01 ){
            return "bg-success"
        } else if (rarityRank <= rarityRunSettings.maxTokenId * 0.05 ) {
            return "bg-warning"
        } else if (rarityRank <= rarityRunSettings.maxTokenId * 0.1 ) {
            return "bg-danger"
        } else {
            return ""
        }
    }
    const popover = (
        <Popover id="popover-basic">
        <div className="card popCard" style={{width:"30vh"}}>
            <MUICard>
            <Card.Header>
                <h4> RAR Score : {Number(tokenData.rarScore).toLocaleString(undefined, {maximumFractionDigits:0})}</h4>
            </Card.Header>
            <Card.Body>
                <table style={{fontSize:"80%"}}>
                    <thead>
                        <tr>
                            <th scope="col"> Trait</th >
                            <th scope="col"> Value</th>
                            <th scope="col"> Percentage</th>
                            <th scope="col"> RAR </th>
                        </tr>
                    </thead>
                    <tbody>
                    { tokenData.attributes ? (tokenData.attributes.map((trait)=> 
                        <tr style={{padding: "none"}}>
                            <td>{trait.trait_type? trait.trait_type.toString().slice(0, 15): '-----'} </td>
                            <td>{trait.value ? trait.value.toString().slice(0, 15) : "none"} </td>
                            <td>{Number(trait.percentage).toLocaleString(undefined, {style:"percent", maximumFractionDigits:2} )} </td>
                            <td>{Number(trait.rarScore).toLocaleString(undefined, { maximumFractionDigits:0} )} </td>
                        </tr>
                    )): <></>}
                    </tbody>
                </table> 
            </Card.Body>
            </MUICard>
        </div>
        </Popover>
    )

    return (
        <Col style={{paddingRight: "0", paddingLeft: "0"}}>
            <MUICard key={tokenData.id} style={{width:"150px"}}>
                <div className="card-header"><a id={tokenData.id} className="cybb-link" target="_blank" rel="noreferrer" href={`https://opensea.io/assets/${rarityRunSettings.contractAddress}/${tokenData.id}`}> ID: {tokenData.id}</a></div>
                <div className={`card-title ${determineBgColor(tokenData.rarityRank)}`}> {tokenData.rarityRank} / {rarityRunSettings.maxTokenId}</div>
                <OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={popover}>
                    <div className="card-body" style={{padding: "0"}}>
                        <img src={imagesShow
                                    ? (tokenData.image || tokenData.image_url)?.replace('ipfs://', 'https://ipfs.io/ipfs/') 
                                    : "placeholder.png"}
                             width="100%" height="100%" alt="">
                            
                        </img>
                    </div>
                </OverlayTrigger>
                <div className="card-footer" style={{fontSize:"80%"}}>
                    {tokenData.lowestPrice ? 
                        (<>
                            {/* <img src="eth.svg" alt="eth" width="15px" height="15px"/> */}
                            <h4>{Number(tokenData.lowestPrice).toLocaleString(undefined, { maximumFractionDigits:2} )} E</h4>
                            <Button style={{marginLeft:"5px"}}className="btn-info btn-custom " size="sm">
                                <a rel="noreferrer" className="cybb_button_target" href={`https://opensea.io/assets/${rarityRunSettings.contractAddress}/${tokenData.id}`} target="_blank">CYBB</a>
                            </Button>
                        </>)
                        :  <>{"No listing"}</>
                    }
                </div>
            </MUICard>
        </Col>
    )
}

