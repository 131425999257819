import * as React from 'react';
import { OpenSeaStreamClient } from '@opensea/stream-js';

const dadKey = "0617084e142d4710bdfcb9a903f7175b"  

export type OpenseaContextType = {
    client: OpenSeaStreamClient;
    setErrorFunction: any;
}

interface OpenseaProviderProps {
    readonly children?: React.ReactNode;
  }

export const OpenseaContext = React.createContext<OpenseaContextType | null>(null);

export const OpenseaProvider: React.FC<OpenseaProviderProps> = (props) => {
    const { children } = props
    const [errorFunction, setErrorFunction] = React.useState((()=>{}) as any)
    const client = new OpenSeaStreamClient({
        token: dadKey,
        onError: (error)=>{
            console.log(error);
            errorFunction();
        }
    });

    return (
        <OpenseaContext.Provider value={{ client, setErrorFunction}}>
            {children}
        </OpenseaContext.Provider>
    )
}