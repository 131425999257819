import { Firestore, getFirestore } from "firebase/firestore";
import { Auth, getAuth } from "firebase/auth";
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Functions, getFunctions  } from "firebase/functions"
import { Database, getDatabase } from "firebase/database";

import * as React from 'react';

export type FirebaseContextType = {
    app: FirebaseApp;
    auth: Auth;
    db: Firestore;
    realtime: Database;
    functions: Functions;
}

interface FirebaseProviderProps {
    readonly children?: React.ReactNode;
  }

export const FirebaseContext = React.createContext<FirebaseContextType | null>(null);

export const FirebaseProvider: React.FC<FirebaseProviderProps> = (props) => {
    const { children } = props
    const firebaseConfig = {
        apiKey: "AIzaSyCwbBN1kYtu5544pvet_9wgrhfAD3trVEg",
        authDomain: "cyberbabies-4781d.firebaseapp.com",
        databaseURL: "https://cyberbabies-4781d-default-rtdb.firebaseio.com",
        projectId: "cyberbabies-4781d",
        storageBucket: "cyberbabies-4781d.appspot.com",
        messagingSenderId: "482371976310",
        appId: "1:482371976310:web:0b62806d3ff19cd976b357",
        measurementId: "G-MS6NGCBPY6"
      };
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const db = getFirestore(app);
    const realtime = getDatabase(app);
    const functions = getFunctions(app);

    return (
        <FirebaseContext.Provider value={{ app, auth, db, functions, realtime}}>
            {children}
        </FirebaseContext.Provider>
    )
}