import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import { initializeApp } from 'firebase/app';
import Web3 from 'web3'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getDatabase, ref, get, set, remove, child, push, update } from 'firebase/database';
import "bootstrap/js/src/collapse.js";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useAlchemy } from '../hooks';
const cyberKeys  = {
    name : "CyberKeys",
    address: "0xb6283b2E41843612CCE2A70a87Cf6eDb9f40aA8a",
}
const CABI = require('../utilities/CyberKeys.json');


const ETHERSCAN_ADDRESS_URL = "https://etherscan.io/address/";
const ETHERSCAN_TX_URL = "https://etherscan.io/tx/";

const adminAddys = [
    "0x8154Ad71D7f398505598ceeD3Cd2f1F46b95e2b1"
]
const firebaseConfig = {
    apiKey: "AIzaSyCwbBN1kYtu5544pvet_9wgrhfAD3trVEg",
    authDomain: "cyberbabies-4781d.firebaseapp.com",
    databaseURL: "https://cyberbabies-4781d-default-rtdb.firebaseio.com",
    projectId: "cyberbabies-4781d",
    storageBucket: "cyberbabies-4781d.appspot.com",
    messagingSenderId: "482371976310",
    appId: "1:482371976310:web:0b62806d3ff19cd976b357",
    measurementId: "G-MS6NGCBPY6"
  };
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const dbSuccess = ref(db, 'flashbotTransactionLog/success')
const dbFail = ref(db, 'flashbotTransactionLog/dropped')
const dbDropped = ref(db, 'flashbotTransactionLog/fail')
const dbProcess = ref(db, 'flashbotTransactionLog/processed')

const useForceUpdate = () => {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

export const Admin = (props) => {
    const { alchemyWeb3: web3 } = useAlchemy();
    const [account, setAccount] = useState("");
    const [allowed, setAllowed] = useState(false);
    const verifyOwnership = async () => {
        if (adminAddys.map(addy => addy.toLowerCase()).includes(account)){
            setAllowed(true)
        }
    }
    const forceUpdate = useForceUpdate();
    const [successTxLog, setSuccessTxLog] = useState({}as any)
    const [toBurnTxLog, setToBurnTxLog] = useState({}as any)
    const [processTxLog, setProcessTxLog] = useState({}as any)

    const keysContract = new web3.eth.Contract(CABI.abi, cyberKeys.address)

    const requestBurnAll = async (successLog) => {
        console.log('trigger burn')
        const allAddresses = successLog.map(tx => tx.from_account)
        const estimateWeb3 = new Web3("https://eth-mainnet.alchemyapi.io/v2/lbS10YM6EB3uAj8OV1uhlvo-s5WsLqLc")
        const estimateKeysContract = new estimateWeb3.eth.Contract(CABI.abi, cyberKeys.address)
        const finalAllAddress = await Promise.all(allAddresses.map(async fromAccount => {
            try {
                const gasEstimate = await estimateKeysContract.methods.ownerBurn([fromAccount], [1]).estimateGas({from:account})
                return fromAccount
            } catch {
                return undefined
            }

            
        }))
        console.log(finalAllAddress)
        const final = finalAllAddress.filter(r => r !== undefined)
        const valueArray = new Array(final.length).fill(1)
        const finalagain = final.filter((r, index) => {
            if (final.indexOf(r) === index){
                return true
            } else if (final.indexOf(r) !== index){
                valueArray[final.indexOf(r)] += 1
                console.log(valueArray[final.indexOf(r)])
                valueArray.pop()
                return false
            } else {
                return false
            }
        })
        console.log(finalagain)
        console.log(valueArray)
        try {
            const response = await keysContract.methods.ownerBurn(finalagain, valueArray).send({from:account})
            if (response) {
                console.log(response);
                toBurnTxLog.forEach(logEntry => {
                    const newEntry =  {...logEntry, burnTransaction: response}
                    requestMoveToProcessed(newEntry)
                })
            }
        } catch (error){
            console.log(error)
            toast('burn Cancelled')
        }
        

    }

    const requestBurnSingleKey = async(logEntry)=>{
        console.log('attempting burn of single key')
        try {
            const response = await keysContract.methods.ownerBurn([logEntry.from_account], [1]).send({from:account})
            if (response) {
                console.log(response);
                const newEntry = {...logEntry, burnTransaction: response}
                requestMoveToProcessed(newEntry);
            }
        } catch (error){
            console.log(error)
            toast('burn Cancelled')
        }

    }

    const requestMoveToProcessed = async(logEntry)=>{
        console.log(logEntry);
        const logKey = logEntry.entryKey
        set(ref(db, 'flashbotTransactionLog/processed/'+logKey), logEntry)
            .then(()=>{
                toast(`processed entry ${logKey}`)
                // remove(ref(db, 'flashbotTransactionLog/success/'+logKey))
            })
            .catch((error) => {
                console.log(error)
                toast('error while trying to process entry')
            })
        set(ref(db, 'flashbotTransactionLog/success/'+logKey), logEntry)
            .then(()=>{
                toast(`updated success entry ${logKey}`)
                // remove(ref(db, 'flashbotTransactionLog/success/'+logKey))
            })
            .catch((error) => {
                console.log(error)
                toast('error while trying to process entry')
            })
        
        
    }

    useEffect(() => {
        const getCurrentWallet = async () => {
            try {
                const { ethereum } = window as any;
                const addressArray = await ethereum.request({ method: "eth_accounts"});
                if (addressArray.length > 0) {
                    setAccount(addressArray[0])
                }
            } catch (err) {
                console.log(err);
            }
        }
        getCurrentWallet();
        get(dbSuccess).then((snapshot)=>{
            if (snapshot.exists()){
                const correctObjects = Object.keys(snapshot.val()).map(key => ({...snapshot.val()[key], entryKey: key}))
                setSuccessTxLog(correctObjects);
                setToBurnTxLog(correctObjects.filter(logEntry => !(logEntry.isApeOwner || logEntry.isRobotOwner || logEntry.isDelegate ||logEntry.burnTransaction)))
                const reduced = correctObjects.reduce((r, a) => {
                    r[a.to_account] = r[a.to_account] || []
                    r[a.to_account].push(a);
                    return r
                }, Object.create(null))
                console.log(reduced)
            }
        })
        get(dbProcess).then((snapshot)=>{
            if (snapshot.exists()){
                const correctObjects = Object.keys(snapshot.val()).map(key => ({...snapshot.val()[key], entryKey: key}))
                setProcessTxLog(correctObjects);
            }
        })

    }, []);
    useEffect(() => {
        if (account) {
            verifyOwnership();
        }
    }, [account]);
    return (
    allowed ?
    (<div className="Page-centered">
      <ToastContainer pauseOnHover={false} position="top-left" theme="dark"/>
      <Row className="align-items-center">
          <h2> Admin Dashboard</h2>
      </Row>

      <Table striped bordered hover style={{padding: "20px"}} variant="dark">
          <thead>
              <tr>
                <th> Count : {toBurnTxLog.length}</th>
                <th colSpan={9}>TO BE BURNED</th>
                  <th>
                    <Button className="btn-info btn-danger" variant="danger" onClick={()=>requestBurnAll(toBurnTxLog)}>
                        Burn All
                    </Button>
                </th>
              </tr>
              <tr>
                  <th>Key</th>
                  <th>To</th>
                  <th>From</th>
                  <th>Value</th>
                  <th>Prio</th>
                  <th>Max</th>
                  <th>KeyBalance</th>
                  <th>Hash</th>
                  <th>Status</th>
                  <th>ShouldBurn</th>
                  <th colSpan={2}>Actions</th>

              </tr>

          </thead>
          <tbody>
          {toBurnTxLog.length > 0 ? toBurnTxLog.map(logEntry => 
               <tr>
                   <td>{logEntry.entryKey}</td>
                   <td> <a target="_blank" rel="noreferrer" href={ETHERSCAN_ADDRESS_URL+logEntry.to_account}> {logEntry.to_account.slice(-8)}</a></td>
                   <td> <a target="_blank" rel="noreferrer" href={ETHERSCAN_ADDRESS_URL+logEntry.from_account}>{logEntry.from_account.slice(-8)}</a></td>
                   <td> {logEntry.payableValue}</td>
                   <td> {web3.utils.fromWei(web3.utils.hexToNumberString(logEntry.maxPriorityFeePerGas), 'gwei')}</td>
                   <td> {web3.utils.fromWei(web3.utils.hexToNumberString(logEntry.maxFeePerGas), 'gwei')}</td>
                   <td> {logEntry.keysBalance ?? 'na'}</td>
                   <td>
                       <a target="_blank" rel="noreferrer" href={ETHERSCAN_TX_URL+logEntry.txHash}>Hash</a>
                   </td>
                   <td>Status</td>
                   <td>{(logEntry.isApeOwner || logEntry.isRobotOwner) ? 'No' : 'Yes'}</td>
                   <td>
                       <Button className="btn-info btn-custom" onClick={()=>requestBurnSingleKey(logEntry)}>
                            Burn
                       </Button>
                   </td>
                   <td>
                       <Button className="btn-info btn-custom" onClick={()=>requestMoveToProcessed({...logEntry, burnTransaction:"processed"})}>
                            Process
                       </Button>
                   </td>
               </tr> 
            ): null}
          </tbody>

      </Table>
      <Table striped bordered hover style={{padding: "20px"}} variant="dark">
          <thead>
              <tr>
                <th> Count : {successTxLog.length}</th>
                <th colSpan={9}>SUCCESFUL</th>
                  <th>
                </th>
              </tr>
              <tr>
                  <th>To</th>
                  <th>From</th>
                  <th>Value</th>
                  <th>Prio</th>
                  <th>Max</th>
                  <th>KeyBalance</th>
                  <th>Hash</th>
                  <th>Status</th>
                  <th>ShouldBurn</th>
                  <th colSpan={2}>Actions</th>

              </tr>

          </thead>
          <tbody>
          {successTxLog.length > 0 ? successTxLog.map(logEntry => 
               <tr>
                   <td> <a target="_blank" rel="noreferrer" href={ETHERSCAN_ADDRESS_URL+logEntry.to_account}> {logEntry.to_account.slice(-8)}</a></td>
                   <td> <a target="_blank" rel="noreferrer" href={ETHERSCAN_ADDRESS_URL+logEntry.from_account}>{logEntry.from_account.slice(-8)}</a></td>
                   <td> {logEntry.payableValue}</td>
                   <td> {web3.utils.fromWei(web3.utils.hexToNumberString(logEntry.maxPriorityFeePerGas), 'gwei')}</td>
                   <td> {web3.utils.fromWei(web3.utils.hexToNumberString(logEntry.maxFeePerGas), 'gwei')}</td>
                   <td> {logEntry.keysBalance ?? 'na'}</td>
                   <td>
                       <a target="_blank" rel="noreferrer" href={ETHERSCAN_TX_URL+logEntry.txHash}>Hash</a>
                   </td>
                   <td>Status</td>
                   <td>{(logEntry.isApeOwner || logEntry.isRobotOwner || logEntry.isDelegate) ? 'No' : 'Yes'}</td>
                   <td>
                       <Button className="btn-info btn-custom" onClick={()=>requestBurnSingleKey(logEntry)}>
                            Burn
                       </Button>
                   </td>
                   <td>
                       <Button className="btn-info btn-custom" onClick={()=>requestMoveToProcessed(logEntry)}>
                            Process
                       </Button>
                   </td>
               </tr> 
            ): null}
          </tbody>

      </Table>

      <Table striped bordered hover style={{padding: "20px"}} variant="dark">
          <thead>
              <tr>
                <th colSpan={10}>SUCCESFUL</th>
              </tr>
              <tr>
                  <th>Contract</th>
                  <th>Count</th>
              </tr>

          </thead>
          <tbody>
          {successTxLog.length > 0 ? Object.entries(successTxLog.reduce((r, a) => {
              r[a.to_account] = r[a.to_account] || []
              r[a.to_account].push(a);
              return r
          }, Object.create(null))).map(keyValue=>{
                const contract = keyValue[0]
                const logs = keyValue[1] as any
               return(
               <>
               <tr
                    data-toggle="collapse"
                    data-target={`.collapse1`}
                    aria-controls="multiCollapse1"
               >
                   <td>{contract}</td>
                   <td> {logs.length}</td>
               </tr>
               <tr className={`collapse collapse1`} id="multiCollapse1"> 
                    <td>Test</td>
               </tr>
               </>
               )} 
            ): null}
          </tbody>

      </Table>

    </div> ) : null
    )
}