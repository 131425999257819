import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button,  InputGroup, Spinner, Dropdown, Form, Tabs, Tab, Modal, Table, FormControl} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { getContractAbi, getTestContractAbi, hexToGwei } from './bot_helpers'; 
import Web3Utils from 'web3-utils';
import Web3 from 'web3'
import * as rlp from 'rlp'
import { getDatabase, ref, connectDatabaseEmulator, get, onValue, Unsubscribe, set } from 'firebase/database';
import { initializeApp } from 'firebase/app';
import { useAuthentication } from '../hooks';


type SignedTxObj = {
    txParams : any;
    payableValue : string;
    signedData: string;
    status: string;
    hash: string;
}

const firebaseConfig = {
    apiKey: "AIzaSyCwbBN1kYtu5544pvet_9wgrhfAD3trVEg",
    authDomain: "cyberbabies-4781d.firebaseapp.com",
    databaseURL: "https://cyberbabies-4781d-default-rtdb.firebaseio.com",
    projectId: "cyberbabies-4781d",
    storageBucket: "cyberbabies-4781d.appspot.com",
    messagingSenderId: "482371976310",
    appId: "1:482371976310:web:0b62806d3ff19cd976b357",
    measurementId: "G-MS6NGCBPY6"
  };
const app = initializeApp(firebaseConfig);
let db;
db = getDatabase(app);
const CABI = require('../utilities/CyberKeys.json');
declare let window: any;

const cyberKeys  = {
    name : "CyberKeys",
    address: "0xb6283b2E41843612CCE2A70a87Cf6eDb9f40aA8a",
}
const cyberKeysTesting = {
    name : "CyberKeys",
    address: "0x9a72FAe801004Ee127C97DEEC3b2166242b2bfb4",
}

const useForceUpdate = () => {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}
const ROBOTS = [5,9,15,20,24,64,107,218,229,241,242,293,297,303,312,335,353,370,375,387,389,438,442,444,473, 43];
const hasRobot = (tokens: String[]) => {
    return tokens.some(token => ROBOTS.includes(Number(token)))
}

const hasApe = (tokens: String[]) => {
    return tokens.some(token => APES.includes(Number(token)))
}

const isAdmin = (account: string) => {
     return adminAddys.map(addy => addy.toLowerCase()).includes(account)
}

const APES = [299,208,363, 265, 127, 18, 307, 244, 255, 97, 261, 176, 402, 44, 82, 355, 277, 93, 315, 377, 483, 31, 2, 345, 49]


const adminAddys = [
    "0xe74f61066FB2bC14A8Eef295046d27068DC6CA58",
    "0x4cF150b0116d503bF1Aa79d0EA64e2F8b21136cD",
    "0xAC3c837D67B4F638C0ABBF711327CFd33149Cf3D",
    "0xfF174799aD2cC7F777550178951E6f20f9dca3cD",
    "0xa81A3127BD9E757EC6c16e92a3D1778D3F428649",
    "0x62B5bD7F04Fe783796810b2526470dfdE73dAA49",
    "0x8d4dAbA34C92E581F928fCA40e018382f7A0282a",
    "0x62B5bD7F04Fe783796810b2526470dfdE73dAA49",
    "0xb5fb8c42a36ab86fb22e277033898b327ed46552",
    "0x00f09F87a2e15c9666F37ad48c01e180E66381Ed"
]
const etherscanBaseTxUrl = "https://etherscan.io/tx/"
const etherscanBaseAddressUrl = "https://etherscan.io/address/"
const GASLIMIT = 500000

export const NftBotApp = (props) => {
    let web3;
    let keysContract;
    let fetchContractAbi;
    if (!window.location.host.includes('localhost')){
        web3 = new Web3("https://eth-mainnet.alchemyapi.io/v2/lbS10YM6EB3uAj8OV1uhlvo-s5WsLqLc")
        keysContract = new web3.eth.Contract(CABI.abi, cyberKeys.address)
        fetchContractAbi = getContractAbi
    }else {
        web3 = new Web3('https://eth-rinkeby.alchemyapi.io/v2/1PFuHewm69KMYQD13SJpdy8SwOQ1EyB3')
        keysContract = new web3.eth.Contract(CABI.abi, cyberKeysTesting.address)
        fetchContractAbi = getTestContractAbi

    }
    const [botMode, setBotMode] = useState('normal')
    const [showModal, setShowModal] = useState(false)
    const [proxyContract, setProxyContract] = useState(false);
    const [contractAddress, setContractAddress] = useState("");
    const [contractAbi, setContractAbi] = useState([]);
    const [mintFunction, setMintFunction] = useState({} as any);
    const [saleFlags, setSaleFlags] = useState([]);
    const [payableValue, setPayableValue] = useState("");
    const [priorityFee, setPriorityFee] = useState("");
    const [maxFee, setMaxFee] = useState("");
    const [gasLimit, setGasLimit] = useState("500000");
    const [watching, setWatching] = useState(false);
    const [watchCount, setWatchCount] =useState(0);
    const [watchCountAdvanced, setWatchCountAdvanced] =useState(0);
    const [watchCountExpert, setWatchCountExpert] = useState(0);
    const [signedTxHex, setSignedTxHex] = useState("");
    const [allTransactionParams, setAllTransactionParams] = useState({}as any)
    const [transactionHash, setTransactionHash] = useState("");
    const [keysBalance, setKeysBalance] = useState(0);
    const [watchForRevertFlag, setWatchForRevertFlag] = useState(false)
    const [revertErrorMessage, setRevertErrorMessage] = useState("")
    const [watchForBlockTS, setWatchForBlockTS] = useState(false)
    const [blockTS, setBlockTS] = useState(0);
    const [currentBlockTS, setCurrentBlockTS] = useState(0);
    const [sendNowAck, setSendNowAck] = useState(false);
    const [txDelay, setTxDelay] = useState(7000);
    const [signedTransactions, setSignedTransactions] = useState([])
    const [cancelMempoolListen, setCancelMempoolListen] = useState<Unsubscribe>(()=>{})
    const [showOnlyPayable, setShowOnlyPayable] = useState(true)
    const [isDelayStaggerred, setIsDelayStaggered] = useState(false)

    const [useFlashbotRPC, setUseFlashbotRPC] = useState(false);

    const [flashbotInfo, setFlashbotInfo] = useState({} as any)
    const [hexData, setHexData] = useState("")
    const [fromAddress, setFromAddress] = useState("");
    const { account : userAccount, tokensOwned, delegateToken } = props
    const { isDelegate } = useAuthentication();
    const isRobotOwner = hasRobot(tokensOwned)
    const isApeOwner = hasApe(tokensOwned)
    const isAdminOwner = isAdmin(userAccount)

    const forceUpdate = useForceUpdate();


    const getContractInput = (level):string=> {
        return (document.getElementById(`contractAddress-${level}`) as HTMLInputElement).value.trim().toLowerCase()
    } 
    const getContractInputAdvanced = ():string=> {
        return (document.getElementById('contractAddress-advanced') as HTMLInputElement).value.trim().toLowerCase()
    } 

    const getHexData = ():string=>{
        return (document.getElementById('hexData') as HTMLInputElement).value.trim()

    }

    const getFromAddress = () =>{
        return (document.getElementById('fromAddress') as HTMLInputElement).value.trim().toLowerCase();
    }

    const getFunctionInputs = () => {
        if (!mintFunction.name) return [null]
        const allInputs = mintFunction.inputs.map((input) => {
            const rawInput = (document.getElementById(input.name) as HTMLInputElement).value.trim();
            if (!rawInput) return null;
            if (input.type.includes('uint') && !input.type.includes('[')) return Number(rawInput);
            if (input.type.includes('[')) {
                const jsList = rawInput.replace('[', '').replace(']', '').split(',')
                if (jsList[0] === '') return []
                if (input.type.includes('uint')) return jsList.map(inp => Number(inp))
                return jsList
            }
            if ( input.type.includes('tuple')) {
                const jsList = rawInput.replace('(', '').replace(')', '').split(',')
                if (jsList[0] === '') return []
                const tList = input.components.map((c, index) => {
                    if (c.type.includes('uint')) return Number(jsList[index])
                    if (c.type.includes('[')) return []
                })

                return tList
            }
            return rawInput;
        })
        return allInputs;
    }

    const getFlagInputs = () => {
        if (saleFlags[0].inputs.length === 0) return undefined

        return saleFlags[0].inputs.map(input => {
            if (input.type === 'bool') {
                const outputTrue = (document.getElementById(`${saleFlags[0].name}-${input.name}-true`) as HTMLInputElement).checked
                const outputFalse = (document.getElementById(`${saleFlags[0].name}-${input.name}-false`) as HTMLInputElement).checked
                if (outputTrue) return true
                if (outputFalse) return false;
                return undefined;
            }else if (input.type.includes('uint')) {
                const output = (document.getElementById(`${saleFlags[0].name}-${input.name}`) as HTMLInputElement).value
                if (output.length=== 0) return undefined
                if (Number.isNaN(Number(output))) return undefined
                return output
            }else if (input.type.includes('address')) {
            const output = (document.getElementById(`${saleFlags[0].name}-${input.name}`) as HTMLInputElement).value
            if (output.length=== 0) return undefined
            return output
        }
            return undefined

        })
    }

    const getFlagValues = () => {
        return saleFlags.map(flag => {
            console.log(flag)
            if (flag.outputs.length === 0) {
                return undefined
            
            }else if (flag.outputs[0].type === 'bool') {
                const outputTrue = (document.getElementById(`${flag.name}-true`) as HTMLInputElement).checked
                const outputFalse = (document.getElementById(`${flag.name}-false`) as HTMLInputElement).checked
                if (outputTrue) return true
                if (outputFalse) return false;
                return undefined;
            }else if (flag.outputs[0].type.includes('uint')) {
                const output = (document.getElementById(`${flag.name}`) as HTMLInputElement).value
                if (output.length=== 0) return undefined
                if (Number.isNaN(Number(output))) return undefined
                return output
            }
            return undefined

        })
    }

    const verifyFieldsValidity = () => {
        const flagWatchValues = getFlagValues();
        const inputs = getFunctionInputs();
        if (inputs.length !== mintFunction.inputs.length || inputs.some(input=> input === null)){
            toast("inputs are not valid")
            return false;
        }
        if (flagWatchValues.some(val => val === undefined) && botMode !== 'expert') {
            toast("flag values not set")
            return false;
        }

        if( !maxFee || 
            !priorityFee || 
            !gasLimit || 
            Number.isNaN(Number(maxFee)) || 
            Number.isNaN(Number(priorityFee)) || 
            Number.isNaN(Number(gasLimit))) 
        {
            toast('max priority / fee / gasLimit are required fields')
            return false
        }
        return true
    }

    const verifyFieldsValidityAdvanced = () => {
        if( !maxFee || 
            !priorityFee || 
            !gasLimit || 
            !hexData ||
            Number.isNaN(Number(maxFee)) || 
            Number.isNaN(Number(priorityFee)) || 
            Number.isNaN(Number(gasLimit)) ||
            !Web3Utils.isHex(hexData)) 
        {

            toast('max priority / fee / gasLimit / hexData are required fields')
            return false
        }
        return true
    }

    const loadFromAddress = async () =>{
        const inputFromAddress = getFromAddress();
        if (!inputFromAddress) {
            toast('From address is missing')
            return
        }
        const isAddy = Web3Utils.isAddress(inputFromAddress);
        if (!isAddy) {
            toast('Not a valid address...')
            return
        }
        setFromAddress(inputFromAddress);
    }

    const loadContract = async(level)=>{
        const inputAddress = getContractInput(level);
        if (!inputAddress) {
            toast('Contract address is missing')
            return
        }
        const isAddy = Web3Utils.isAddress(inputAddress);
        if (!isAddy) {
            toast('Not a valid contract address...')
            return
        }
        setContractAddress(inputAddress);
        let abi = null;
        try {
            abi = await fetchContractAbi(inputAddress)
            console.log(abi);

            if (abi.some(ele => ele.name === 'implementation' && ele.outputs.length === 1 && ele.outputs[0].type === 'address') || proxyContract) {
                console.log('THIS IS A PROXY IMPLEMENTATION')
                const implementationStorage = await web3.eth.getStorageAt(inputAddress, "0x360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc")
                const realAddress = "0x" + implementationStorage.slice(26)
                console.log('Implementation address : ', realAddress)
                const newAbi = await fetchContractAbi(realAddress)
                console.log(newAbi);
                abi = newAbi
            }
            toast('fetched contract ABI')
            if(!abi) {
                throw Error('abi not available')
            }
            setContractAbi(abi);
        } catch(error) {
            console.log(error)
            toast("ABI could not be loaded, contract needs to be verified");
            return
        }
    }

    const loadContractAdvanced = async()=>{
        const inputAddress = getContractInputAdvanced();
        if (!inputAddress) {
            toast('Contract address is missing')
            return
        }
        const isAddy = Web3Utils.isAddress(inputAddress);
        if (!isAddy) {
            toast('Not a valid contract address...')
            return
        }
        setContractAddress(inputAddress);
    }

    const watchForRevert = async()=> {
        if (watching){
            setWatching(false);
            setWatchCountAdvanced(0);
            toast('cancelled watch...')
            return
        }
        if (!verifyFieldsValidityAdvanced()){
            return
        }
        setWatching(true);
        watchRevertLoop();
    }

    const watchRevertLoop = async()=>{ 
        await sleep(500);
        try {
            web3.eth.handleRevert = true;
            const gasEstimate = await web3.eth.estimateGas({
                from: userAccount,
                to: contractAddress,
                data: hexData,
                value: web3.utils.toHex(web3.utils.toWei(Number(payableValue).toString(), "ether"))})
            console.log(gasEstimate);
            setWatching(false);
            setRevertErrorMessage("")
            toast('function is not reverting, sending tx')
            sendMintTxAdvanced();

        }
        catch (error){
            console.log(error.message)
            const revertMessage = error.message.includes(':') ? error.message.split(":").pop() : error.message
            setRevertErrorMessage(revertMessage)
            setWatchCountAdvanced((count)=>count+1)

        }      
    }

    const sendMintNowCheck = async() => {
        if (!verifyFieldsValidity()){
            return
        }
        sendMintTx();
    }

    const watchForFlipState = async()=>{
        const contract = new web3.eth.Contract(contractAbi, contractAddress);
        const currentFlagValues = await Promise.all(saleFlags.map( async flag=> {
            const curVal = await contract.methods[flag.name]().call();
            return curVal;
        }))
        if (watching){
            setWatching(false);
            setWatchCount(0);
            toast('cancelled watch...')
            return
        }
        const flagWatchValues = getFlagValues();
        // if (!verifyFieldsValidity()){
            // return
        // }
        if (signedTransactions.length > 0 &&
                !watchForRevertFlag &&
                !watchForBlockTS &&
                currentFlagValues.map((value, index) => value === flagWatchValues[index]).every(v => v === true)
            ){
            toast("flag is already at the set value");
            return;
        } else if (signedTxHex && watchForRevertFlag) {
            toast("when using the revert flag, ensure that you start WATCHING before you PRE-SIGN TX")
            return;
        }
        toast('watching contract for flip tx...')
        setWatching(true)
        watchLoop(flagWatchValues, contract);
    }

    const watchLoop = async(flagWatchValues, contract)=>{
        await sleep(500);
        const currentFlagValues = await Promise.all(saleFlags.map( async flag=> {
            const curVal = await contract.methods[flag.name]().call();
            return curVal;
        }))
        const inputs = getFunctionInputs();
        const flagsAreMatching = currentFlagValues.map((value, index) => value === flagWatchValues[index]).every(v => v === true)
        let revertWatchResult;
        web3.eth.handleRevert =true;
        if (!watchForRevertFlag) {
            revertWatchResult = true
        } else {
            try {
                const gasEstimation = await contract.methods[mintFunction.signature](...inputs).estimateGas(
                    {from:userAccount, value: web3.utils.toHex(web3.utils.toWei(Number(payableValue).toString(), "ether"))})
                revertWatchResult = true
                setRevertErrorMessage("")
            } catch(error) {
                revertWatchResult = false
                const revertMessage = error.message.split(":").pop()
                setRevertErrorMessage(revertMessage)
                
            }
        }
        let blockTimeStampResult;
        if (!watchForBlockTS) {
            blockTimeStampResult = true
        } else {
            blockTimeStampResult = false
            const blockNumber = await window.ethereum.request({method: "eth_blockNumber"})
            const thisBlock = await window.ethereum.request({method: "eth_getBlockByNumber", params: [blockNumber, false]})
            const thisTs = Web3Utils.hexToNumber(thisBlock.timestamp);
            setCurrentBlockTS(thisTs);
            if (thisTs> blockTS) blockTimeStampResult = true

        }

        if (flagsAreMatching && revertWatchResult && blockTimeStampResult) {
            sendMintTx();
            setWatching(false);
            setWatchCount(0);
            toast(`flags have been flipped to required value`);
        } else {
            setWatchCount((count)=>count+1)
            
        }
        
    } 

    const watchMempool = async () =>{
        if (watching){
            setWatching(false);
            setWatchCount(0);
            toast('cancelled watch...')
            return
        }
        const inputs = getFunctionInputs();
        if (!mintFunction.name || inputs.length !== mintFunction.inputs.length || inputs.some(input=> input === null)){
            toast("inputs are not valid")
            return false;
        }
        const watchReqUrl = "https://cyberbabies.io/api/flashbot/watchRequest/"
        const watchResponse = await fetch(watchReqUrl + contractAddress + "?fromAddress=" + fromAddress)
        .then(response => {
            if (!response.ok) {
                throw Error('couldnt fetch url')
            }
            return response
        }).then(response => response.json() as any)
        .catch(error =>  {console.log(error); return null;})
    if (!watchResponse) {
        toast('error while requesting cybb cloud data')
        return
    }
    const infoRef = ref(db, 'flashbotContractInfo/'+ contractAddress);
    // await get(infoRef).then((snapshot) => {
    //     if (snapshot.exists()) {
    //         setFlashbotInfo(snapshot.val());
    //     }
    // }).catch(error => toast(error))

    const cancelFunc = onValue(infoRef, (snapshot) => {
        const data = snapshot.val();
        setFlashbotInfo(data);
    })
    // console.log(cancelFunc)
    // setCancelMempoolListen(cancelFunc);
    }

    const sendMintTxAdvanced = async () => {
        if (signedTransactions.length > 0) {
            const queuedTxs = signedTransactions.map(tx => ({...tx, status: 'queued'}))
            setSignedTransactions(queuedTxs);
            signedTransactions.forEach(async (transaction, index) => {
                try {
                    await sleep(txDelay * (isDelayStaggerred ? index+1 : 1))
                    sendSingleTx(transaction, index)
                } catch (e) {
                    console.log(e)
                }
            })
        } else {

            const contract = new web3.eth.Contract(contractAbi, contractAddress);
            const transactionParameters = {
                    from: userAccount,
                    to: contractAddress,
                    value: web3.utils.toHex(web3.utils.toWei(Number(payableValue).toString(), "ether")),
                    data: hexData,
            } as any;
            if (priorityFee) transactionParameters.maxPriorityFeePerGas = web3.utils.numberToHex(web3.utils.toWei(priorityFee, "gwei"))
            if (maxFee) transactionParameters.maxFeePerGas = web3.utils.numberToHex(web3.utils.toWei(maxFee, "gwei"))
            transactionParameters.gasLimit = gasLimit;
            const provider = window.ethereum as any;
            if (provider) {
                provider.request({
                    method: "eth_sendTransaction",
                    params: [transactionParameters]
                })
            }
        }
    }

    const logTransaction = async(transaction:SignedTxObj, hash:string) => {
        const response = await fetch("https://cyberbabies.io/api/flashbot/logTransaction/", {
            "method": "POST",
            "headers": {
                'accept': "application/json",
                'content-type': "application/json" 
            },
            "body": JSON.stringify({
                ...transaction.txParams,
                txHash: hash,
                timestamp: Date.now(),
                botMode: botMode,
                isRobotOwner: isRobotOwner,
                isApeOwner: isApeOwner,
                isDelegate: !!delegateToken,
                keysBalance: keysBalance,
            })
        })
        .then(response => {
            if (!response.ok) {
                throw Error('couldnt fetch url')
            }
            return response
        }).then(response => response.json() as any)
        .catch(error =>  {console.log(error); return null;})
        if (response && response.status === 'success') {
            toast(response.status.message)
        }
    }

    const sendSingleTx = async (transaction:SignedTxObj, index: number) => {
        console.log('Attempting to send tx :')
        console.log(transaction);
        console.log('From public wallet : ', userAccount);
        let tx;
        let hash;
        if (useFlashbotRPC) {
            console.log('using flashbot RPC')
            const flashbotWeb3 = new Web3("https://rpc.flashbots.net")
            tx = await flashbotWeb3.eth.sendSignedTransaction(transaction.signedData);
        }else {
            tx = await window.ethereum.request({
                method: "eth_sendRawTransaction",
                params:[transaction.signedData]
            })
        }

        setSignedTransactions((current) => {
            const newSigned = current;
            newSigned[index].hash = tx
            newSigned[index].status = 'sent'
            return newSigned
        })
        setTransactionHash(tx)
        try {
            logTransaction(transaction, tx);
        } catch (e) {console.log(e)};
    }

    const sendMintTx = async()=>{
        if (signedTransactions.length > 0) {
            const queuedTxs = signedTransactions.map(tx => ({...tx, status: 'queued'}))
            setSignedTransactions(queuedTxs);
            toast('starting delay');
            signedTransactions.forEach(async (transaction, index) => {
                try {
                    await sleep(txDelay * (isDelayStaggerred ? index+1 : 1))
                    sendSingleTx(transaction, index)
                } catch (e) {
                    console.log(e)
                }
            })
        } else {

            const inputs = getFunctionInputs();
            const contract = new web3.eth.Contract(contractAbi, contractAddress);
            const encodedAbi = contract.methods[mintFunction.signature](...inputs).encodeABI();            
            const transactionParameters = {
                    from: userAccount,
                    to: contractAddress,
                    value: web3.utils.toHex(web3.utils.toWei(Number(payableValue).toString(), "ether")),
                    data: encodedAbi
            } as any;
            if (priorityFee) transactionParameters.maxPriorityFeePerGas = web3.utils.numberToHex(web3.utils.toWei(priorityFee, "gwei"))
            if (maxFee) transactionParameters.maxFeePerGas = web3.utils.numberToHex(web3.utils.toWei(maxFee, "gwei"))
            const provider = window.ethereum as any;
            if (provider) {
                provider.request({
                    method: "eth_sendTransaction",
                    params: [transactionParameters]
                })
            }
        }

    }

    const signMintModalQuestion = async ()=>{
        if (!isRobotOwner && !isApeOwner && !delegateToken &&!isAdminOwner && keysBalance === signedTransactions.length){
            toast('you cannot submit more transaction than the amount of keys you own')
            return
        }
        if (!verifyFieldsValidity()) return
        const inputs = getFunctionInputs();
        const contract = new web3.eth.Contract(contractAbi, contractAddress)
        const encodedAbi = contract.methods[mintFunction.signature](...inputs).encodeABI();
        // const gasLimit = contract.methods[mintFunction.signature](Number(inputs)).estimateGas();
        const nonce = await web3.eth.getTransactionCount(userAccount,"pending") + signedTransactions.length
        const chainId = await web3.eth.getChainId();
        const transactionParameters = {
                from_account: userAccount,
                to_account: contractAddress,
                payableValue: Number(payableValue),
                data: encodedAbi,
                nonce: nonce,
                chainId: chainId,
                gasLimit: GASLIMIT,
        } as any;
        if (priorityFee && maxFee && gasLimit) {
            transactionParameters.maxPriorityFeePerGas = Web3Utils.numberToHex(web3.utils.toWei(priorityFee, "gwei"));
            transactionParameters.maxFeePerGas = Web3Utils.numberToHex(web3.utils.toWei(maxFee, "gwei"));
            transactionParameters.gasLimit = gasLimit;

        } else {
            toast('max priority / fee / gasLimit are required fields')
            return
        }
        setAllTransactionParams(transactionParameters)
        setShowModal(true);
    }

    const signMintModalQuestionAdvanced = async ()=>{
        if (!isRobotOwner && !isApeOwner && !delegateToken &&!isAdminOwner && keysBalance === signedTransactions.length){
            toast('you cannot submit more transaction than the amount of keys you own')
            return
        }
        const data = getHexData();
        const nonce = await web3.eth.getTransactionCount(userAccount,"pending")  + signedTransactions.length
        const chainId = await web3.eth.getChainId();
        const transactionParameters = {
                from_account: userAccount,
                to_account: contractAddress,
                payableValue: Number(payableValue),
                data: data,
                nonce: nonce,
                chainId: chainId,
                gasLimit: GASLIMIT,
        } as any;
        if (priorityFee && maxFee && gasLimit) {
            transactionParameters.maxPriorityFeePerGas = Web3Utils.numberToHex(web3.utils.toWei(priorityFee, "gwei"));
            transactionParameters.maxFeePerGas = Web3Utils.numberToHex(web3.utils.toWei(maxFee, "gwei"));
            transactionParameters.gasLimit = gasLimit;

        } else {
            toast('max priority / fee / gasLimit are required fields')
            return
        }
        setAllTransactionParams(transactionParameters)
        setShowModal(true);
    }



    const signMintTx = async ()=>{
        const transactionParameters = allTransactionParams
        const payableHex = Number(payableValue) === 0 ? "" : Web3Utils.toHex(Web3Utils.toWei(Number(payableValue).toString(), "ether"))
        const eip1599ParamsToSign = [
            transactionParameters.chainId,
            transactionParameters.nonce,
            transactionParameters.maxPriorityFeePerGas,
            transactionParameters.maxFeePerGas,
            Web3Utils.toHex(transactionParameters.gasLimit),
            contractAddress,
            payableHex,
            transactionParameters.data,
            []
        ]

        const signingData = rlp.encode(eip1599ParamsToSign)
        const signingDataHex = web3.utils.toHex(signingData)
        const signingDataHexType2 = '0x02' + signingDataHex.slice(2)
        const signingDataHashed = web3.utils.sha3(signingDataHexType2)
        console.log('EIP1599Params : ',eip1599ParamsToSign)

        const signature = await (window.ethereum as any).request({
            method: "eth_sign",
            params: [userAccount, signingDataHashed]
        })
        const slicedSignature = signature.slice(2)
        const myR = slicedSignature.slice(0, 64)
        const myS = slicedSignature.slice(64, 128)
        const myV = Number(web3.utils.hexToNumber('0x' +slicedSignature.slice(128))) % 27
        const signed9Params = [
            ...eip1599ParamsToSign,
            myV,
            "0x" +myR,
            "0x" +myS
        ]
        const txData = rlp.encode(signed9Params)
        const txDataHex = web3.utils.toHex(txData);
        const txDataHexType2 = '0x02'+txDataHex.slice(2)

        console.log(txDataHexType2);
        setSignedTransactions((allSigned) => {
            const newStack = allSigned;
            newStack.push({
                txParams :  transactionParameters,
                payableValue : payableValue, 
                signedData: txDataHexType2,
                status: "waiting",
                hash: ""
            })
            return newStack
        })
        forceUpdate()
    }

    const calculateEthRequirement = () => {
        if( !maxFee || 
            !gasLimit || 
            Number.isNaN(Number(maxFee)) || 
            Number.isNaN(Number(gasLimit))) 
        {        
            return "Inputs not valid"
        } else {
            const weiCount = Number(Web3Utils.toWei(maxFee, "gwei")) * Number(gasLimit)
            const ethReq = Number(payableValue)  + Number(Web3Utils.fromWei(weiCount.toString(), 'ether'))
            return parseFloat(ethReq.toFixed(5)) + "eth required in wallet"
        }
    }

    useEffect(()=>{
        if (watching) {
            const contract = new web3.eth.Contract(contractAbi, contractAddress);
            const flagWatchValues = getFlagValues();
            watchLoop(flagWatchValues, contract)
        }
    }, [watchCount])

    useEffect(()=>{
        if (watching) {
            watchRevertLoop();
        }
    }, [watchCountAdvanced])

    useEffect(() => {
        const getCurrentKeyCount = async () => {
            try {

                const balance = await keysContract.methods.balanceOf(userAccount, 1).call();
                setKeysBalance(Number(balance));
            } catch (err) {
                console.log(err);
            }
        }
        getCurrentKeyCount();
    }, [])

    useEffect(()=>{
        console.log(flashbotInfo);
        if (Object.keys(flashbotInfo).length > 0) {
            flashbotInfo.status=== 'watching' ? setWatching(true) : setWatching(false);
            if (flashbotInfo.status && flashbotInfo.status === 'watching') {
                const contract = new web3.eth.Contract(contractAbi, contractAddress);
                const funcInputs = (getFlagInputs() ?? []).filter(t => t!== undefined);
    
                console.log(funcInputs)
                const requiredInput = contract.methods[saleFlags[0].signature](...funcInputs).encodeABI()
                console.log("Looking for input : ", requiredInput);
                // console.log(Object.keys(flashbotInfo.alchemyMessages))
                // console.log(Object.values(flashbotInfo.alchemyMessages))
                if (flashbotInfo.alchemyMessages && Object.keys(flashbotInfo.alchemyMessages).length > 0) {
                    console.log(flashbotInfo.alchemyMessages)
                    if (Object.values(flashbotInfo.alchemyMessages).some(message => 
                        message['input'] === requiredInput
                    )) {
                        sendMintTx();
                        setWatching(false);
                        // cancelMempoolListen();

                    }
                }
            }

        }
    }, [flashbotInfo])
    return (
    (<>
        <ToastContainer pauseOnHover={false} pauseOnFocusLoss={false} position="top-left" theme="dark"/>
        <Modal show={showModal} onHide={()=>setShowModal(false)}>
            <Modal.Header>
                <Modal.Title>Pre-Sign Transaction</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p> 
                    By signing this transaction in your Metamask, you authorize CYBB to send the minting transaction as soon as the sale flag that you specificied is flipped<br></br>               
                </p>
                <Row>
                    <Col md="auto">
                        <p>
                            Nonce : <br></br>
                            Chain Id: <br></br>
                            Mint Fx Name : <br></br>
                            Mint Eth Value : <br></br>
                            Priority Fee : <br></br>
                            Max Fee : <br></br>
                            Gas Limit : <br></br>

                        </p>
                    </Col>
                    <Col md="auto">
                        <p>
                            {allTransactionParams.nonce}<br></br>
                            {allTransactionParams.chainId}<br></br>
                            {mintFunction.name} <br></br>
                            {Number(payableValue)} eth <br></br>
                            {priorityFee} gwei <br></br>
                            {maxFee} gwei <br></br>
                            {allTransactionParams.gasLimit} <br></br>
                        </p>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={()=>setShowModal(false)}>
                CANCEL
            </Button>
            <Button variant="warning" onClick={()=>{signMintTx();setShowModal(false)}}>
                I UNDERSTAND
            </Button>
            </Modal.Footer>
        </Modal>

        <Container>
            <Row>
                <Col > <p> Version : 0.2.8</p></Col>

                {isApeOwner ? <Col><p>APE LIFETIME OWNER</p></Col> : null}
                {isRobotOwner ? <Col><p>ROBOT OWNER</p></Col> : null}
                {isAdminOwner ? <Col><p>ADMIN</p></Col> : null}
                {delegateToken ? <Col><p>DELEGATE OF CYBB#{delegateToken}</p></Col> : null}
                <Col><p>Key Count : {keysBalance}</p></Col>
            </Row>
        </Container>
        <Container className="MintContainer" style={{marginTop: "5vh"}}>
        { keysBalance || isRobotOwner || isApeOwner || delegateToken || isAdminOwner ?
        <Tabs style={{width:"70vw"}} activeKey={botMode} onSelect={(k) => setBotMode(k)}>
        {/* ---------------------------------------------NORMAL -------------------------------------------------------- */}
        <Tab eventKey="normal" title="Normal" tabClassName="btn-custom btn-tab" style={{width:"70vw"}} disabled={contractAddress ? true : false}>
            {botMode === 'normal' ? 
            <Container  style={{marginTop: "5vh"}}>
                {contractAddress ? 
                    <>
                    <Form.Check type="checkbox" onChange={(event =>setUseFlashbotRPC(event.target.checked))} inline label="Use FlashBots RPC Server"/>
                    <h5> Contract : <a className="nolinkplease" target="_blank" href={etherscanBaseAddressUrl+contractAddress+"#code"}>{contractAddress}</a> </h5>
                    </>
                    :
                    <>
                    <Col md="auto" className="MintContainer">
                    <InputGroup style={{width: "450px"}} as={Row}>
                        <Col>
                        <input style={{width: "450px"}} placeholder="Contract Address" type="text" id="contractAddress-beginner"></input>
                        </Col>
                        <Col>
                        <Form.Check type="checkbox" onChange={(event =>setProxyContract(event.target.checked))} inline label="Is Proxy"/>
                        </Col>
                    </InputGroup>
                    <br/>
                    
                    <Button style={{ width: "250px"}} onClick={()=>loadContract('beginner')} disabled={contractAbi.length > 0}  className="btn-info btn-custom" > Load Contract</Button>
                    </Col>
                    <br/>
                    </>
                }

                    { contractAddress ? 
                        <>
                        <Row>
                            <ShowSignedTxs 
                                setSignedTransactions={setSignedTransactions}
                                signedTransactions={signedTransactions} 
                                sendSingleTx={sendSingleTx} 
                                allowSend={sendNowAck}
                                txDelay={txDelay}
                                setTxDelay={setTxDelay}
                                isDelayStaggerred={isDelayStaggerred}
                                setIsDelayStaggered={setIsDelayStaggered}
                            />
                        </Row>

                        <p>Refresh count : {`${watchCount}`}</p>
                        {revertErrorMessage ? `Revert Message : ${revertErrorMessage}`: null}
                        {currentBlockTS ? `Current Block Timestamp : ${currentBlockTS}`: null}
                        <br />
                        <Row>
                            <Col><>STEP 1</><br/><Button disabled={(!saleFlags.length && !watchForRevertFlag && !watchForBlockTS)} style={{width: "250px"}}variant={watching ? "danger" : "warning"} type="submit" onClick={()=>watchForFlipState()} >
                                {watching  ? "Cancel" : "Watch" }
                            </Button></Col>
                            <Col><>STEP 2</><br/><Button disabled={!!!mintFunction.name} style={{width: "250px"}}variant={signedTxHex ? "danger" : "warning"} type="submit" onClick={()=>signMintModalQuestion()} >
                                {signedTxHex ? "Cancel Signature" : "Pre-Sign Tx" }
                            </Button></Col>
                        </Row>
                        </>
                        :null
                    }
                                 
                {contractAbi.length ? 
                <Row style={{marginTop:"5vh"}}>
                    <Col>
                        <p> Sale Flags </p>
                        <Dropdown>
                        <Dropdown.Toggle disabled={watching} style={{ width: "250px"}} className="btn-info btn-custom" id="saleFlagSelection">
                            Add Sale Flag
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{overflow: "hidden"}}>
                            
                                <Dropdown.Item style={{ width:"250px"}} as="button" onClick={()=>setWatchForRevertFlag(true)} eventKey="revert">revert (common)</Dropdown.Item>
                                <Dropdown.Item style={{ width:"250px"}} as="button" onClick={()=>setWatchForBlockTS(true)} eventKey="revert">block timestamp (common)</Dropdown.Item>

                                {contractAbi
                                .filter(ele => ele.type === "function" &&
                                        ele.stateMutability === 'view' &&
                                        ele.inputs.length === 0  && 
                                        ele.outputs.length === 1 &&
                                        !saleFlags.map(flag=>flag.name).includes(ele.name)
                                        
                                )
                                .map((ele) => 
                                    <Dropdown.Item style={{ width: "250px"}} as="button" onClick={()=>{setSaleFlags(flags=>[...flags, ele])}} eventKey={ele['name']}>{`${ele['name']} (${ele['outputs'][0].type})`}</Dropdown.Item>
                                )}
                                
                        </Dropdown.Menu>
                        </Dropdown>
                        <br></br>
                        {saleFlags.map(saleFlag => <SaleFlagModule disabled={watching} flag={saleFlag} setSaleFlags={setSaleFlags} />)}
                        {watchForRevertFlag ? <SaleFlagModuleRevert disabled={watching} setWatchForRevertFlag={setWatchForRevertFlag} />: null}
                        {watchForBlockTS ? <SaleFlagModuleBlockTS disabled={watching} setWatchForBlockTS={setWatchForBlockTS} setBlockTS={setBlockTS} />: null}

                    </Col>
                    <Col>
                        <p>Mint Function <Form.Check type="checkbox" className="iKnowCheckbox" defaultValue={showOnlyPayable.toString()} onChange={(event =>setShowOnlyPayable(!event.target.checked))} inline label="show all"/></p> 
                        
                        <Dropdown >
                                <Dropdown.Toggle disabled={!!signedTxHex} style={{ width: "250px"}} className="btn-info btn-custom" id="functionSelection">
                                    {mintFunction.name ? mintFunction.name : "Select Mint Function"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{overflow: "hidden"}}>
                                    
                                    
                                        {contractAbi
                                        .filter(ele => ele.type === "function" && (showOnlyPayable ? ele.stateMutability === 'payable': true))
                                        .map((ele) => 
                                            <Dropdown.Item style={{ width: "250px"}} as="button" onClick={()=>{setMintFunction(ele)}} eventKey={ele['name']}>{ele['name']}</Dropdown.Item>
                                        )}
                                        
                                </Dropdown.Menu>
                        </Dropdown>
                        {mintFunction.name ?
                        <>
                        <br></br>
                        <Form>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="6"> Value (ether) : </Form.Label>
                                <Col sm="6">
                                    <Form.Control id="payableValue" type="text" placeholder="value : ether" disabled={!!signedTxHex} onChange={(event)=>setPayableValue(event.target.value)}/>
                                </Col>
                            </Form.Group>
                            {mintFunction.inputs.map((input) =>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="6">{`${input.name} (${input.type}) :`}</Form.Label>
                                    <Col sm="6">
                                        <Form.Control  disabled={!!signedTxHex} required id={input.name} type="text" placeholder={`${input.name} : ${input.type}`}/>
                                    </Col>
                                </Form.Group>
                            )}                  
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="6"> Priority fee (gwei) : </Form.Label>
                                <Col sm="6">
                                    <Form.Control  disabled={!!signedTxHex} id="priorityFee"  type="text" placeholder="priority fee : gwei" onChange={(event)=>setPriorityFee(event.target.value)}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="6"> Max fee (gwei) : </Form.Label>
                                <Col sm="6">
                                <   Form.Control  disabled={!!signedTxHex} id="maxFee"  type="text" placeholder="max fee : gwei" onChange={(event)=>setMaxFee(event.target.value)}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="6"> Gas Limit : </Form.Label>
                                <Col sm="6">
                                    <Form.Control  disabled={!!signedTxHex} id="gasLimit"  type="text" placeholder="gasLimit : unit" onChange={(event)=>setGasLimit(event.target.value)}/>
                                </Col>
                            </Form.Group>
                        </Form>
                        <p>{calculateEthRequirement()}</p>
                        </>
                        : <></>}

                    </Col>
                </Row>
                
                : <></>}
                {mintFunction.name ?<Form.Check type="checkbox" className={sendNowAck ? "iKnowCheckbox" : "iKnowCheckbox"} onChange={(event =>setSendNowAck(event.target.checked))} inline label="I know what I'm doing"/>: null}
            </Container>
            :null}
        </Tab>


        {/* ---------------------------------------------ADVANCED -------------------------------------------------------- */}
        <Tab eventKey="advanced" title="Advanced" tabClassName="btn-custom btn-tab"  style={{width:"70vw"}} disabled={contractAddress ? true : false}>
        {botMode === 'advanced' ? 
        <Container  style={{marginTop: "5vh"}}>

            {contractAddress ? 
                <>
                <Form.Check type="checkbox" onChange={(event =>setUseFlashbotRPC(event.target.checked))} inline label="Use FlashBots RPC Server"/>
                <h5> Contract : <a className="nolinkplease" target="_blank" href={etherscanBaseAddressUrl+contractAddress+"#code"}>{contractAddress}</a> </h5>
                </>
                :
                <>
                <Col md="auto" className="MintContainer">
                <InputGroup style={{width: "450px"}}>
                    <input style={{width: "450px"}} placeholder="Contract Address" type="text" id="contractAddress-advanced"></input>
                </InputGroup>
                <br/>
                
                <Button style={{ width: "250px"}} onClick={()=>loadContractAdvanced()} disabled={contractAbi.length > 0}  className="btn-info btn-custom" > Load Contract</Button>
                </Col>
                </>
            }
            {contractAddress ?
            <>
                <Row>
                    <ShowSignedTxs 
                        setSignedTransactions={setSignedTransactions}
                        signedTransactions={signedTransactions} 
                        sendSingleTx={sendSingleTx} 
                        allowSend={sendNowAck}
                        txDelay={txDelay}
                        setTxDelay={setTxDelay}
                        isDelayStaggerred={isDelayStaggerred}
                        setIsDelayStaggered={setIsDelayStaggered}
                        />
                </Row>
                <p>Refresh count : {watchCountAdvanced} </p>
                {revertErrorMessage ? `Revert Message : ${revertErrorMessage}`: null}
                <br />
                    
                <Row>
                    <Col><>STEP 1</><br/><Button  style={{width: "250px"}} variant={watching ? "danger" : "warning"} type="submit" onClick={()=>watchForRevert()} >
                        {watching ? "Cancel" : "Watch for revert" }
                    </Button></Col>
                    <Col><>STEP 2</><br/><Button style={{width: "250px"}}variant="warning" type="submit" onClick={()=>signMintModalQuestionAdvanced()} >
                        {signedTxHex ? "Cancel Signature" : "Pre-Sign Tx" }
                    </Button></Col>
                </Row>

                <Row style={{marginTop:"5vh"}}>
                    <Col>
                        <p>Mint Function Data</p>
                        <>
                        <br></br>
                        <Form>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="6"> Value (ether) : </Form.Label>
                                <Col sm="6">
                                    <Form.Control id="payableValue" type="text" placeholder="value : ether" disabled={!!signedTxHex} onChange={(event)=>setPayableValue(event.target.value)}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="6"> Hex Data :  </Form.Label>
                                <Col sm="6">
                                    <Form.Control id="hexData" type="text" placeholder="hex data" disabled={!!signedTxHex} onChange={(event)=>setHexData(event.target.value)}/>              
                                </Col>
                            </Form.Group>                  
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="6"> Priority fee (gwei) : </Form.Label>
                                <Col sm="6">
                                    <Form.Control  disabled={!!signedTxHex} id="priorityFee"  type="text" placeholder="priority fee : gwei" onChange={(event)=>setPriorityFee(event.target.value)}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="6"> Max fee (gwei) : </Form.Label>
                                <Col sm="6">
                                <   Form.Control  disabled={!!signedTxHex} id="maxFee"  type="text" placeholder="max fee : gwei" onChange={(event)=>setMaxFee(event.target.value)}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="6"> Gas Limit : </Form.Label>
                                <Col sm="6">
                                    <Form.Control  disabled={!!signedTxHex} id="gasLimit"  type="text" placeholder="gasLimit : unit" onChange={(event)=>setGasLimit(event.target.value)}/>
                                </Col>
                            </Form.Group>
                        </Form>
                        <p>{calculateEthRequirement()}</p>

                        </>

                    </Col>
                </Row>
            </>
            :<></>
            }   
            </Container>
            :null}
        </Tab>

        {/* ---------------------------------------------EXPERT -------------------------------------------------------- */}

        <Tab eventKey="expert" title="Expert" tabClassName="btn-custom btn-tab"  style={{width:"70vw"}}  disabled={contractAddress ? true : false}>
        {botMode === 'expert' ? 
        <Container  style={{marginTop: "5vh"}}>

            {contractAddress ? 
                <>
                <Form.Check type="checkbox" onChange={(event =>setUseFlashbotRPC(event.target.checked))} inline label="Use FlashBots RPC Server"/>
                <h5> Contract : <a className="nolinkplease" target="_blank" href={etherscanBaseAddressUrl+contractAddress+"#code"}>{contractAddress}</a> </h5>
                </>
                :
                <>
                <Col md="auto" className="MintContainer">
                <InputGroup style={{width: "450px"}}>
                        <Col>
                        <input style={{width: "450px"}} placeholder="Contract Address" type="text" id="contractAddress-expert"></input>
                        </Col>
                        <Col>
                        <Form.Check type="checkbox" onChange={(event =>setProxyContract(event.target.checked))} inline label="Is Proxy"/>
                        </Col>
                </InputGroup>
                <br/>
                
                <Button style={{ width: "250px"}} onClick={()=>loadContract('expert')} disabled={contractAbi.length > 0}  className="btn-info btn-custom" > Load Contract</Button>
                </Col>
                <br/>
                </>
            }
            {fromAddress ? 
                    <h5> From : <a className="nolinkplease" target="_blank" href={etherscanBaseAddressUrl+fromAddress}>{fromAddress}</a> </h5>
                    :
                    <>
                    <Col md="auto" className="MintContainer">
                    <InputGroup style={{width: "450px"}}>
                        <input style={{width: "450px"}} placeholder="Owner Address" type="text" id="fromAddress"></input>
                    </InputGroup>
                    <br/>
                    
                    <Button style={{ width: "250px"}} onClick={()=>loadFromAddress()} disabled={fromAddress.length > 0}  className="btn-info btn-custom" > Owner Address</Button>
                    </Col>
                    <br/>
                    </>
            }
            { contractAbi.length && fromAddress? 
            <>
                <Row>
                    <ShowSignedTxs 
                        setSignedTransactions={setSignedTransactions}
                        signedTransactions={signedTransactions} 
                        sendSingleTx={sendSingleTx} 
                        allowSend={sendNowAck}
                        txDelay={txDelay}
                        setTxDelay={setTxDelay}
                        isDelayStaggerred={isDelayStaggerred}
                        setIsDelayStaggered={setIsDelayStaggered}
                    />
                </Row>

                Status : {watching ? "Watching" : "Idle" }
                {watching ? 
                    <Spinner animation="border" role="status" size="sm" variant="info">
                    <span className="visually-hidden"> Loading...</span>
                    </Spinner> 
                :null}
                <br />
            
                <Row>
                    <Col><>STEP 1</><br/><Button disabled={(!saleFlags.length && !watchForRevertFlag && !watchForBlockTS)} style={{width: "250px"}}variant={watching ? "danger" : "warning"} type="submit" onClick={()=>watchMempool()} >
                        {watching  ? "Cancel" : "Watch mempool" }
                    </Button></Col>
                    <Col><>STEP 2</><br/><Button disabled={!!!mintFunction.name} style={{width: "250px"}}variant={signedTxHex ? "danger" : "warning"} type="submit" onClick={()=>signMintModalQuestion()} >
                        {signedTxHex ? "Cancel Signature" : "Pre-Sign Tx" }
                    </Button></Col>
                </Row>            
                <Row style={{marginTop:"5vh"}}>
                    <Col>
                        <p> Watch Function </p>
                        <Dropdown>
                        <Dropdown.Toggle disabled={watching || saleFlags.length > 0}  style={{ width: "250px"}} className="btn-info btn-custom" id="saleFlagSelection">
                            Add Watch Function
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{overflow: "hidden"}} >
                                {contractAbi
                                .filter(ele => ele.type === "function" &&
                                        ele.stateMutability !== 'view' &&
                                        ele.stateMutability !== 'payable' &&
                                        !saleFlags.map(flag=>flag.name).includes(ele.name)
                                        
                                )
                                .map((ele) => 
                                    <Dropdown.Item style={{ width: "250px"}} as="button" onClick={()=>{setSaleFlags(flags=>[...flags, ele])}} eventKey={ele['name']}>{`${ele['name']}`}</Dropdown.Item>
                                )}
                                
                        </Dropdown.Menu>
                        </Dropdown>
                        <br></br>
                        {saleFlags.map(saleFlag => <WatchFunctionModule disabled={watching} flag={saleFlag} setSaleFlags={setSaleFlags} />)}
                    </Col>
                    <Col>
                        <p>Mint Function <Form.Check type="checkbox" className="iKnowCheckbox" defaultValue={showOnlyPayable.toString()} onChange={(event =>setShowOnlyPayable(!event.target.checked))} inline label="show all"/></p> 
                        <Dropdown >
                                <Dropdown.Toggle disabled={!!signedTxHex} style={{ width: "250px"}} className="btn-info btn-custom" id="functionSelection">
                                    {mintFunction.name ? mintFunction.name : "Select Mint Function"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{overflow: "hidden"}}>
                                    
                                    
                                        {contractAbi
                                        .filter(ele => ele.type === "function" && (showOnlyPayable ? ele.stateMutability === 'payable': true))
                                        .map((ele) => 
                                            <Dropdown.Item style={{ width: "250px"}} as="button" onClick={()=>{setMintFunction(ele)}} eventKey={ele['name']}>{ele['name']}</Dropdown.Item>
                                        )}
                                        
                                </Dropdown.Menu>
                        </Dropdown>
                        {mintFunction.name ?
                        <>
                        <br></br>
                        <Form>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="6"> Value (ether) : </Form.Label>
                                <Col sm="6">
                                    <Form.Control id="payableValue" type="text" placeholder="value : ether" disabled={!!signedTxHex} onChange={(event)=>setPayableValue(event.target.value)}/>
                                </Col>
                            </Form.Group>
                            {mintFunction.inputs.map((input) =>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="6">{`${input.name} (${input.type}) :`}</Form.Label>
                                    <Col sm="6">
                                        <Form.Control  disabled={!!signedTxHex} required id={input.name} type="text" placeholder={`${input.name} : ${input.type}`}/>
                                    </Col>
                                </Form.Group>
                            )}                  
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="6"> Priority fee (gwei) : </Form.Label>
                                <Col sm="6">
                                    <Form.Control  disabled={!!signedTxHex} id="priorityFee"  type="text" placeholder="priority fee : gwei" onChange={(event)=>setPriorityFee(event.target.value)}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="6"> Max fee (gwei) : </Form.Label>
                                <Col sm="6">
                                <   Form.Control  disabled={!!signedTxHex} id="maxFee"  type="text" placeholder="max fee : gwei" onChange={(event)=>setMaxFee(event.target.value)}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="6"> Gas Limit : </Form.Label>
                                <Col sm="6">
                                    <Form.Control  disabled={!!signedTxHex} id="gasLimit"  type="text" placeholder="gasLimit : unit" onChange={(event)=>setGasLimit(event.target.value)}/>
                                </Col>
                            </Form.Group>
                        </Form>
                        <p>{calculateEthRequirement()}</p>
                        </>
                        : <></>}

                    </Col>
                </Row>
            </>
            :null}
        </Container>
        :null}
        </Tab> 
        <Tab eventKey="bundle" title="Bundle" tabClassName="btn-custom btn-tab" style={{width:"70vw"}} disabled={true}></Tab>
        {(isApeOwner || isRobotOwner) ? 
            <Tab eventKey="delegate" title="Delegate" tabClassName="btn-custom btn-tab delegate-tab"  style={{width:"70vw"}}  disabled={contractAddress ? true : false}>
            <DelegatesTab tokensOwned={tokensOwned.map(t => Number(t))}/>   
            </Tab>
        : null} 
        </Tabs>
        :
        <>
        <h5 style={{marginTop:"10px"}}> You don't have any cyber keys</h5>
        <h5><a href="/mint" className="nolinkplease"> Claim your keys here</a>!</h5>
        </>
        } 
        </Container>
    </>)
    )
}


const SaleFlagModule = (props) => {
    const {setSaleFlags, flag, disabled} = props
    const removeFlag = () =>{
        setSaleFlags((flags) => {
            return flags.filter(f => f.name !== flag.name)
        })
    }
    return (
        <>
        <Form style={{borderTop:"1px ridge darkcyan", marginTop:"5px"}}>
        <Form.Group className="mb-3">
            <br />
            {flag.outputs.map((output) => {
                if (output.type === 'bool') {
                    return (
                        <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="4"> {flag.name} </Form.Label>
                        <Col sm="3">
                            <Form.Check disabled={disabled} type="radio" name="group1" inline id={`${flag.name}-true`} label="True"/>
                        </Col>
                        <Col sm="3">
                            <Form.Check disabled={disabled} type="radio" name="group1" inline id={`${flag.name}-false`} label="False"/>
                        </Col>

                        <Col sm="2">
                            <Button onClick={removeFlag} size="sm" disabled={disabled} variant="secondary"> Remove</Button>
                        </Col>
                        </Form.Group>
                    )
                } else if (output.type.includes('uint')) {
                    return (

                        <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="6">  {`${flag.name} (${output.type}) : `} </Form.Label>
                        <Col sm="4">
                            <Form.Control disabled={disabled} id={`${flag.name}`} type="text" />
                        </Col>
                        <Col sm="2">
                            <Button onClick={removeFlag} size="sm" disabled={disabled} variant="secondary"> Remove</Button>
                        </Col>
                        </Form.Group>
                    )

                } else if (output.type.includes('address')) {
                    return (

                        <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="6">  {`${flag.name} (${output.type}) : `} </Form.Label>
                        <Col sm="4">
                            <Form.Control disabled={disabled} id={`${flag.name}`} type="text" />
                        </Col>
                        <Col sm="2">
                            <Button onClick={removeFlag} size="sm" disabled={disabled} variant="secondary"> Remove</Button>
                        </Col>
                        </Form.Group>
                    )
                } else {
                    <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="6">  {`${flag.name} (${output.type}) : `} </Form.Label>
                    <Col sm="4">
                        <p> not supported</p>
                    </Col>
                    <Col sm="2">
                        <Button onClick={removeFlag} size="sm" disabled={disabled} variant="secondary"> Remove</Button>
                    </Col>
                    </Form.Group>
                }
            })}

        </Form.Group>
        </Form>
        </>
    )
}


const SaleFlagModuleRevert = (props) => {
    const {setWatchForRevertFlag, disabled} = props
    return (
        <>
        <Form style={{borderTop:"1px ridge darkcyan", marginTop:"5px"}}>
        <Form.Group className="mb-3">

            <>
            <br />
            <Form.Label> Revert Flag </Form.Label>
            &emsp;
            <Button onClick={()=>setWatchForRevertFlag(false)} size="sm" disabled={disabled} variant="secondary"> Remove</Button>
            <br></br>
            </>

        </Form.Group>
        </Form>
        </>
    )
}

const SaleFlagModuleBlockTS = (props) => {
    const {setWatchForBlockTS, setBlockTS, disabled} = props
    return (
        <>
        <Form style={{borderTop:"1px ridge darkcyan", marginTop:"5px"}}>
        <Form.Group className="mb-3" as={Row}>
            <Form.Label column sm="6"> Block Timestamp &emsp;  {'>'} </Form.Label>

            <Col sm="4">
                <Form.Control disabled={disabled} id="blockTS-input" type="text" onChange={(event)=> setBlockTS(Number(event.target.value))}/>
            </Col>
            <Col  sm="2">
                <Button onClick={()=>setWatchForBlockTS(false)} size="sm" disabled={disabled} variant="secondary"> Remove</Button>
            </Col>
        </Form.Group>
        </Form>
        </>
    )
}

const WatchFunctionModule = (props) => {
    const {setSaleFlags, flag, disabled} = props
    const removeFlag = () =>{
        setSaleFlags((flags) => {
            return flags.filter(f => f.name !== flag.name)
        })
    }
    return (
        <>
        <Form style={{borderTop:"1px ridge darkcyan", marginTop:"5px"}}>
        <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="4"> {flag.name} </Form.Label>
            <br />
            {flag.inputs.map((input) => {
                if (input.type === 'bool') {
                    return (
                        <>
                        <Col sm="3">
                            <Form.Check disabled={disabled} type="radio" name="group1" inline id={`${flag.name}-${input.name}-true`} label="True"/>
                        </Col>
                        <Col sm="3">
                            <Form.Check disabled={disabled} type="radio" name="group1" inline id={`${flag.name}-${input.name}-false`} label="False"/>
                        </Col>
                        </>
                    )
                } else if (input.type.includes('uint')) {
                    return (
                        <>
                        <Col sm="4">
                            <Form.Control disabled={disabled} id={`${flag.name}-${input.name}`} type="text" />
                        </Col>
                        </>
                    )
                } else if (input.type.includes('address')) {
                    return (
                        <>
                        <Col sm="4">
                            <Form.Control disabled={disabled} id={`${flag.name}-${input.name}`} type="text" />
                        </Col>
                        </>
                    )
                } else {
                    return (
                        <>
                        <Col sm="2">
                            <p> not supported</p>
                        </Col>
                        </>
                    )
                }
            })}
        {flag.inputs.length === 0 ? <Col sm="3"></Col> : null}
        <Col sm="2">
            <Button onClick={removeFlag} size="sm" disabled={disabled} variant="secondary"> Remove</Button>
        </Col>
        </Form.Group>
        </Form>
        </>
    )
}

const ShowSignedTxs = (props) =>{
    const {signedTransactions, setSignedTransactions, sendSingleTx, allowSend, txDelay, setTxDelay, isDelayStaggerred, setIsDelayStaggered} = props
    const signedAmount = signedTransactions.length
    const removeTx = async (transaction:SignedTxObj) => {
        setSignedTransactions((current) => {
            const newSignedTx = current.filter(tx => tx['txParams'].nonce !== transaction.txParams.nonce)
            return newSignedTx
        })
    }
    return (
        <Table bordered hover style={{padding: "20px", color: "white", backgroundColor: "grey"}}>
            <thead>
                <tr>
                    <th colSpan={8}> Signed Transactions</th>
                    <td colSpan={3}>
                        <Form.Group as={Row} className="auto">

                            <Form.Label column sm="4">
                                Delay(ms)
                            </Form.Label>

                            <Col sm="4">
                                <Form.Check style={{marginTop:"8px"}} type="checkbox" defaultValue={isDelayStaggerred.toString()} onChange={(event =>setIsDelayStaggered(event.target.checked))} inline label="stagger"/>

                            </Col>
                            <Col sm="4">
                                <Form.Control id="txDelay" defaultValue={txDelay} style={{width: "75px"}} onChange={(event) => setTxDelay(Number(event.target.value))}/>    
                            </Col>
                        </Form.Group>
                    </td>
                </tr>
                <tr>
                    <td>Nonce</td>
                    <td>Value</td>
                    <td>Prio</td>
                    <td>Max</td>
                    <td>Limit</td>
                    <td>Status</td>
                    <td>Delay</td>
                    <td>Link</td>
                    <td colSpan={2}>Actions</td>
                </tr>
            </thead>
            <tbody>
                {signedAmount > 0 ? signedTransactions.map((transaction, index) => {
                    const bgColor = transaction['status'] === 'queued' ? "#ffc107" : transaction['status'] === 'sent' ? "#198754" : 'initial'
                    return (
                    <tr style={{backgroundColor:bgColor}}>
                        <td>{transaction['txParams'].nonce}</td>
                        <td>{transaction['payableValue']}</td>
                        <td>{hexToGwei(transaction['txParams'].maxPriorityFeePerGas)}</td>
                        <td>{hexToGwei(transaction['txParams'].maxFeePerGas)}</td>
                        <td>{transaction['txParams'].gasLimit}</td>
                        <td style={{backgroundColor: "white !important"}}>{transaction['status']}</td>
                        <td>{(txDelay * (isDelayStaggerred ? index+1 : 1))}</td>
                        <td>
                            {transaction['hash'] ? 
                                <a className="nolinkplease" target="_blank" rel="noreferrer" href={`${etherscanBaseTxUrl}${transaction['hash']}`}>view</a>
                                : transaction['status'] === 'queued' ? 
                                    <Spinner animation="border" role="status" size="sm" variant="info">
                                    <span className="visually-hidden"> Loading...</span>
                                    </Spinner>
                                    : null
                                                        
                            }
                        </td>
                        <td>
                            <Button onClick={()=>sendSingleTx(transaction as SignedTxObj, index)} size="sm" variant="danger" disabled={!allowSend || transaction['status'] === 'sent' }> Send</Button>
                        </td>
                        <td>
                            <Button onClick={()=>removeTx(transaction as SignedTxObj)} size="sm" disabled={(index !== signedAmount-1) || transaction['status'] === 'sent'} variant="danger"> Remove</Button>
                        </td>
                    </tr>
                )})
                : null}
            </tbody>
        </Table>
    )
} 

const DelegatesTab = (props) => {
    const {tokensOwned} = props
    const [delegates, setDelegates] = useState([])
    const [selectedToken, setSelectedToken] = useState(tokensOwned.filter(token => ROBOTS.includes(token) || APES.includes(token))[0]);
    const updateSingleDelegate = (index) => {
        const newValue = (document.getElementById(`delegate-${index}`) as HTMLInputElement).value.trim().toLowerCase()
        const indexValue = Number(index) + 1
        console.log(indexValue);
        const delegateRef = ref(db, 'flashbotDelegates/'+ selectedToken + '/' + indexValue);
        set(delegateRef, newValue).then(()=>toast('new delegate value saved'))

    }
    useEffect(()=>{
        const delegateRef = ref(db, 'flashbotDelegates/'+ selectedToken);
        const loadDelegate = async()=>{
            await get(delegateRef).then((data)=>{
                if (data.exists()) {
                    setDelegates(Object.values(data.val()))
                    console.log(data.val())
                } else {
                    const isRobot = ROBOTS.includes(selectedToken)
                    const isApe = APES.includes(selectedToken);
                    let newValues;
                    if (isApe) newValues = {"1": "", "2": "", "3": "", "4": ""}
                    else if (isRobot) newValues = {"1": ""}
                    else newValues = null
                    if(isRobot || isApe) {
                        set(delegateRef, newValues);
                        setDelegates(Object.values(newValues));
                    }
                }

            })
        }
        loadDelegate();
    },[selectedToken])
    return (
        <Container className="MintContainer">
            <br />
            <Dropdown>
            <Dropdown.Toggle className="btn-info btn-custom">
                Cyberbaby #{selectedToken}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{overflow: "hidden"}} >
                    {tokensOwned
                    .filter(token => ROBOTS.includes(token) || APES.includes(token))
                    .map((ele) => 
                        <Dropdown.Item  as="button" onClick={()=>{setSelectedToken(ele)}} eventKey={ele}>Cyberbaby #{ele}</Dropdown.Item>
                    )}
                    
            </Dropdown.Menu>
            </Dropdown>
            <br />
                    
            {delegates.map((dele, index) => 
                <Form.Group as={Row}>
                    <Form.Label column sm="auto">{`Delegate #${index+1}`}</Form.Label>
                    <Col>
                    <Form.Control id={`delegate-${index}`} defaultValue={dele} style={{width: "400px"}}/>
                    </Col>
                    <Col>
                            <Button onClick={()=>updateSingleDelegate(index)} size="sm" variant="secondary"> Save</Button>
                        </Col>
                </Form.Group>
            )}
        </Container>
    )
}