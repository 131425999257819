import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from "react-router-dom";
import './App.css';
import { BidBot, Home, Mint, NftBot, OsBot, RarityBot, Admin, Login } from './pages';
import { Container, } from 'react-bootstrap';
import { ToastContainer} from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FirebaseProvider } from './contexts/firebase';
import { AuthenticationProvider } from './contexts/authentication';
import { useAuthentication } from './hooks';
import { useAuthState } from "react-firebase-hooks/auth";
import { AlchemyProvider } from './contexts/alchemy';
import { OpenseaProvider } from './contexts/opensea';
import { Skeleton, Container as MUIContainer, ThemeProvider, CssBaseline, createTheme, Divider, AppBar} from '@mui/material'
import ResponsiveAppBar from './components/AppBar';

const RequireAccess = ({ children }: { children: JSX.Element }) => {
  const { isAdmin, isBabyOwner, isDelegate, auth } = useAuthentication()
  const [ user, loading, error ] = useAuthState(auth);
  let location = useLocation();
  if ((user && !isAdmin && !isBabyOwner && !isDelegate && !loading) || error) {
    return <Container className="App-body" ><h2>you're not a cyberbaby owner. buy one <a href="https://opensea.io/collection/cyberbabies" target="_blank" rel="noopener noreferrer">here</a></h2></Container>
  }
  if (( !user && !isAdmin && !isBabyOwner && !loading) || error) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (loading) {
    return <Skeleton />
  }

  return children;
}

export const App = ({status, keyringController}) => {
  // const classname = window.location.href.includes('/mint') ? 'App-skeleton' : 'App'
  return (
    <FirebaseProvider>
      <AuthenticationProvider>
        <AlchemyProvider>
          <OpenseaProvider>
            <ThemeProvider
                    theme={createTheme({
                      palette: {
                        mode: "dark",
                        primary: {
                          main: '#429690',
                        },
                        secondary: {
                          main: '#e8643a',
                        },
                        background: {
                          default: '#2f4f4f',
                          paper: '#2c6761',
                        },
                        success: {
                          main: '#76ff03',
                        },
                      },
                      typography : {
                        fontFamily : "Share Tech Mono"
                    }})}
                  >
              <CssBaseline />
              <ToastContainer pauseOnHover={false} pauseOnFocusLoss={false} position="top-left" theme="dark"/>
              <div className="App">
                  <Router>
                    <ResponsiveAppBar />
                    <Routes>
                      {/* <Route path="/nftbot" element={<RequireAccess><NftBot /></RequireAccess>} /> */}
                      {/* <Route path="/snipebot/*" element={<RequireAccess><OsBot /></RequireAccess>} /> */}
                      {/* <Route path="/bidbot" element={<BidBot keyringController={keyringController}/>}/> */}
                      {/* <Route path="/raritybot" element={<RequireAccess><RarityBot  /></RequireAccess>} /> */}
                      {/* <Route path="/mint" element={<Mint/> } /> */}
                      {/* <Route path="/admin" element={<Admin  />} /> */}
                      <Route path="/home" element={<Home />} />
                      {/* <Route path="/login" element={ <Login /> } /> */}
                      <Route path="/" element={<></>} /> 
                      
                    </Routes>
                  </Router>
                </div>
            </ThemeProvider>
          </OpenseaProvider>
        </AlchemyProvider>
      </AuthenticationProvider>
    </FirebaseProvider>
  );
}

export default App;
