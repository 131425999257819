import * as React from 'react';
import { createAlchemyWeb3, AlchemyWeb3 } from '@alch/alchemy-web3'

const alchemyMainnetURL = "https://eth-mainnet.alchemyapi.io/v2/lbS10YM6EB3uAj8OV1uhlvo-s5WsLqLc"
export type AlchemyContextType = {
    alchemyWeb3: AlchemyWeb3
}

interface AlchemyProviderProps {
    readonly children?: React.ReactNode;
  }

export const AlchemyContext = React.createContext<AlchemyContextType | null>(null);

export const AlchemyProvider: React.FC<AlchemyProviderProps> = (props) => {
    const { children } = props

    const alchemyWeb3 = createAlchemyWeb3(alchemyMainnetURL)

    return (
        <AlchemyContext.Provider value={{ alchemyWeb3}}>
            {children}
        </AlchemyContext.Provider>
    )
}