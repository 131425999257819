import { Auth, getAuth, signOut as firebaseSignOut , signInWithCustomToken } from "firebase/auth";
import { useFirebase } from "../hooks";
import * as React from 'react';
import { toHex } from "../utilities"; 
import Web3 from "web3";
import { useAuthState } from "react-firebase-hooks/auth";

export type AuthenticationContextType = {
    auth: Auth
    signInWithProvider: (a: any) => any;
    signOut: () => any;
    changeProvider: (a : any)=> any;
    signInState: SignInStateOptions;
    setSignInState: (a: any) => any;
    isAdmin: boolean;
    isBabyOwner: boolean;
    isDelegate: boolean;
    hasAccess: boolean
}

export type SignInStateOptions =
    "none"|
    "signedIn"|
    "providerSelected" |
    "accountRequest"|
    "nonceRequest"|
    "nonceSign"|
    "sendSignature"|
    "authenticateWithSignature"|
    "signedOut"


interface ProviderProps {
    readonly children?: React.ReactNode;
}

const pipeAsyncFunctions = (...fns) =>
arg => fns.reduce((p, f) => p.then(f), Promise.resolve(arg));

export const AuthenticationContext = React.createContext<AuthenticationContextType | null>(null);

export const AuthenticationProvider: React.FC<ProviderProps> = (props) => {
    const { children } = props
    const { auth } = useFirebase();
    const [user, loading, error] = useAuthState(auth);     
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [isBabyOwner, setIsBabyOwner] = React.useState(false);
    const [isDelegate, setIsDelegate] = React.useState(false);
    const [hasAccess, setHasAccess] = React.useState(false);
    const [ethereum, setEthereum] = React.useState(null);
    const [web3, setWeb3] = React.useState(null);
    const [signInState, setSignInState] = React.useState<SignInStateOptions>('none')
    const signOut = () => {
        setSignInState('signedOut')
        setIsAdmin(false)
        setIsBabyOwner(false)
        setHasAccess(false)
        return firebaseSignOut(auth);
    }
    const changeProvider = (prov) => {
        setEthereum(prov)
        const newWeb3 = new Web3(prov)
        setWeb3(newWeb3);
        setSignInState('providerSelected');
    }
    React.useEffect(()=> {
        const checkAdmin = async () => {
            await user.getIdTokenResult().then(value =>{
              if (value && value?.claims?.adminAccount) {
                setIsAdmin(true)
                setHasAccess(true)
              }
              if (value && value?.claims?.isBabyOwner && value.claims.isBabyOwner.toString() !== '0') {
                setIsBabyOwner(true)
                setHasAccess(true)
              }
              if (value && value?.claims?.isDelegate){
                setIsDelegate(true)
                setHasAccess(true);
              }
            });
        }
        if (user) {
            setSignInState('signedIn')
            checkAdmin();
        } else {
            setSignInState('signedOut')
            setIsAdmin(false)
        }
    }, [user])

    React.useEffect(()=> {
        const ethereum = (window as any).ethereum
        if (ethereum) {
            ethereum.on('accountsChanged', function (accounts) {
                signOut();
                
            });
            ethereum.on('chainChanged', (chainId) => {
                signOut()
            })
        }
    },[])

    const signInWithProvider = async (prov) => {
        let ethereum = prov

        return pipeAsyncFunctions(
            // Step 0 : get the provider in the page
            // async () => await detectEthereumProvider(),
            // Step 1: Request (limited) access to users ethereum account
            async (prov) => {
                if (!prov) {
                    return Promise.reject('Please install MetaMask')
                }
                setSignInState('accountRequest')
                return await ethereum.request({ method: 'eth_requestAccounts' })
                            .catch(e=> {return Promise.reject('could not load user address')});
              },
            // Step 2: Retrieve the current nonce for the requested address
            async () => {
                setSignInState("nonceRequest")

                return await fetch("https://us-central1-cyberbabies-4781d.cloudfunctions.net/getNonceToSign", {
                    method : "POST",
                    body: JSON.stringify({ address: ethereum.selectedAddress }),
                    headers: {
                        "content-type": "application/json"
                    }
                }).then(response => response.json())
                .catch(error => {
                    return Promise.reject("Couldn't get valid nonce for signature")
                })
            },
            // Step 3: Get the user to sign the nonce with their private key
        
            async (response) => {
                setSignInState("nonceSign")
                return await ethereum.request({
                method: 'personal_sign',
                params: [
                    `0x${toHex(response.message)}`,
                    ethereum.selectedAddress,
                ],
                }).catch(e=> {return Promise.reject('signature denied')})
            },
            
            // Step 4: If the signature is valid, retrieve a custom auth token for Firebase
            async (sig) => {
                setSignInState("sendSignature");
                return await fetch("https://us-central1-cyberbabies-4781d.cloudfunctions.net/verifySignedMessage", {
                    method : "POST",
                    body: JSON.stringify({ address: ethereum.selectedAddress, signature: sig }),
                    headers: {
                        "content-type": "application/json"
                    }
                }).then(response => response.json())
                .catch(error => Promise.reject("Couldn't get valid nonce for signature"))
            },
            // Step 5: Use the auth token to auth with Firebase
            async (response) => {
                setSignInState("authenticateWithSignature");
                return await signInWithCustomToken(auth, response.token).then(()=>setSignInState("signedIn"))
                .catch(e => Promise.reject('auth token not accepted'))
                
            })('start');
            
    }

    return (
        <AuthenticationContext.Provider value={{ auth, signInWithProvider, signOut, changeProvider, signInState, hasAccess, setSignInState, isBabyOwner, isAdmin, isDelegate}}>
            {children}
        </AuthenticationContext.Provider>
    )
}