import React, {useEffect, useState}  from 'react';
import { Container, Spinner, Button, Row, Col} from 'react-bootstrap';
import {createAlchemyWeb3} from '@alch/alchemy-web3';
import { useAlchemy } from '../hooks';

type CybbIdInfo = {
    id: number;
    image: string;
    allowance: number;
}

type CYBKState = {
    allowanceSaleActive: boolean;
    publicSaleActive: boolean;
    claimedSupply: number;
    maxSupply: number;
}

const CABI = require('../utilities/CyberKeys.json');
const CABIBabies = require('../utilities/CyberBabies.json');

declare let window: any;

const cyberKeys  = {
    name : "CyberKeys",
    address: "0xb6283b2E41843612CCE2A70a87Cf6eDb9f40aA8a",
}

const cyberKeysTesting = {
    name : "CyberKeys",
    address: "0x9a72FAe801004Ee127C97DEEC3b2166242b2bfb4",
}

const cyberBabies  = {
    name : "CyberBabies",
    address: "0x991A546A167cEb2a6a7C344C9D85269Ac03035D9",
}

const LoadingSpinner = (props) => {
    return (
        props.loading ? 
        <Spinner animation="border" role="status" size="sm" variant="info">
            <span className="visually-hidden"> Loading...</span>
        </Spinner> 
        : <></>
    )
}
const mintCost = 0.04
// const testWeb3 = createAlchemyWeb3('wss://eth-rinkeby.alchemyapi.io/v2/1PFuHewm69KMYQD13SJpdy8SwOQ1EyB3')

export const Mint = (props) => {
    // let web3;
    // let contract;
    // if (!window.location.host.includes('localhost')){
    //     web3 = props.web3
    //     contract = new web3.eth.Contract(CABI.abi, cyberKeys.address); 

    // }else {
    //     web3 = testWeb3
    //     contract = new web3.eth.Contract(CABI.abi, cyberKeysTesting.address); 

    // }
    const { alchemyWeb3: web3 } = useAlchemy();

    // const web3 = createAlchemyWeb3("https://eth-mainnet.alchemyapi.io/v2/lbS10YM6EB3uAj8OV1uhlvo-s5WsLqLc")
    const contract = new web3.eth.Contract(CABI.abi, cyberKeys.address);
    const [loading, setLoading] = useState(true);
    const [account, setAccount] = useState("");
    const [cybbIds, setCybbIds] = useState([]);
    const [cybbAllowances, setCybbAllowances] = useState([]);
    const [cybbImages, setCybbImages] = useState([]);
    const [inputCount, setInputCount] = useState(10);
    const [maxAllowed, setMaxAllowed] = useState(0);
    const [pendingTransaction, setPendingTransaction] = useState({} as any);
    const cybbContract = new web3.eth.Contract(CABIBabies.abi, cyberBabies.address);
    const [contractState, setContractState] = useState<CYBKState>({
        allowanceSaleActive: false,
        publicSaleActive: false,
        maxSupply: 2500,
        claimedSupply: 0,
    })

    const inputChange = () => {
        const newInput = (document.getElementById('mintInput') as HTMLInputElement).value;
        setInputCount(Number(newInput));
    }

    const submitMint = async () => {
        const totalValue = inputCount * mintCost;
        if (cybbIds.length > 1) {
            //multi Owner, but buying less than 1 allowance
            if (cybbAllowances.some((allowance) => inputCount <= allowance)) {
                const pickedIndex = cybbAllowances.indexOf(cybbAllowances.find((allowance) => inputCount <= allowance));
                const pickedCybbId = cybbIds[pickedIndex]
                const response = await contract.methods.claimAllowanceKeys(pickedCybbId, inputCount).send({from: account, value: web3.utils.toWei(`${totalValue}`)})
                setPendingTransaction(response);
            } 
            // multi Owner, buying more than any one allowance
            else {
                const response = await contract.methods.multiClaimAllowanceKeys(cybbIds, inputCount).send({from: account, value: web3.utils.toWei(`${totalValue}`)})
                setPendingTransaction(response);
            }
        }
        //single owner 
        else {
            const response = await contract.methods.claimAllowanceKeys(cybbIds[0], inputCount).send({from: account, value: web3.utils.toWei(`${totalValue}`)})
            setPendingTransaction(response);
        }
    }

    const submitMintPublic = async() =>{
        const totalValue = inputCount * mintCost;
        const response = await contract.methods.claimKeys(inputCount).send({from: account, value: web3.utils.toWei(`${totalValue}`)})
        setPendingTransaction(response);
    }

    useEffect(() => {
        const getCurrentWallet = async () => {
            try {
                const { ethereum } = window as any;
                const addressArray = await ethereum.request({ method: "eth_accounts"});
                if (addressArray.length > 0) {
                    setAccount(addressArray[0])
                    const ownedIds = await cybbContract.methods.whichBabyIsMine(addressArray[0]).call();
                    setCybbIds(ownedIds);
                    const cybbIdInf: CybbIdInfo[] = ownedIds.map( async ownedId => {
                        const info = await fetch(`babyInfo/${ownedId}.json`).then(res => res.json()) as any
                        return info.image;
                    })
                    const results = await Promise.all(cybbIdInf);
                    setCybbImages(results)
                                       
                }
            } catch (err) {
                console.log(err);
            }
        }
        getCurrentWallet();
    }, [])
    useEffect(() => {
        const loadContract = async () => {
            setLoading(true);
            if (window.ethereum && contract) {
                const ASaleState = await contract.methods.allowanceSaleIsActive().call();
                const PSaleState = await contract.methods.publicSaleIsActive().call();
                const tSupply = await contract.methods.claimedSupply().call()
                const maxSupply = await contract.methods.maxSupply().call();
                setContractState({
                    allowanceSaleActive: ASaleState,
                    publicSaleActive: PSaleState,
                    maxSupply: maxSupply,
                    claimedSupply: tSupply
                })
            }
            if (window.ethereum && contract && cybbIds.length > 0) {
                const totalAllowance = await contract.methods.getTotalAllowance(cybbIds).call();
                setMaxAllowed(totalAllowance);
                const allowanceBreakdown = cybbIds.map(async (cybbid) => {
                    const allowance = await contract.methods.allowance(cybbid).call();
                    return allowance
                })
                const results = await Promise.all(allowanceBreakdown)
                setCybbAllowances(results);
            }
            setLoading(false);
        }
        loadContract();
    }, [cybbIds])
    return (
        <div className="MintContainer" style={{marginTop: "5vh"}}>
            <Container className="mintContainers">
                <h3>
                    Claim your Keys
                </h3>      
            </Container>
            <Container className="keyContainer" style={{width:"70vh"}}>
            <img src="/SkeletonKey.gif" alt="" width="100%"></img>
            </Container>
            
            { (cybbIds.length > 0)
            ? 
                (contractState.publicSaleActive)
                ?
                <Container className="mintContainers">
                <div>
                    <h4> {inputCount} for {Number(parseFloat(String(mintCost * Number(inputCount))).toPrecision(2)).toLocaleString(undefined, { maximumFractionDigits:2} )} eth</h4>
                    <input id={"mintInput"}type="range" className="slider-mint my-4" max={25} min={1} step={1} defaultValue={6} onInput={(event)=> inputChange()}/>
                    <br />
                    <Button style={{marginTop:"10px"}} disabled={ !(contractState.maxSupply - contractState.claimedSupply > 0)} size="lg" variant="info" className="btn-custom" onClick={submitMintPublic}>
                        { (contractState.maxSupply - contractState.claimedSupply > 0) ? "Claim Keys" : "Temporarily out of stock" }
                    </Button>
                </div>
                </Container>
                :
                <Container className="mintContainers">
                    <div >
                        <h4> {inputCount} for {Number(parseFloat(String(mintCost * Number(inputCount))).toPrecision(2)).toLocaleString(undefined, { maximumFractionDigits:2} )} eth</h4>
                        <h6> max : {maxAllowed} </h6>
                        <input id={"mintInput"}type="range" className="slider-mint" max={maxAllowed} min={1} step={1} defaultValue={1} onInput={(event)=> inputChange()}/>
                        <br />
                        <Button style={{marginTop:"10px"}} size="lg" variant="info" className="btn-custom" disabled={!contractState.allowanceSaleActive} onClick={submitMint}>
                            {(contractState.publicSaleActive) ? "Claim Keys" : "Not live"}
                        </Button>
                    </div>
                </Container>
            :
            <Container style={{margin:"50px"}}>
                <h4> Cyberbaby not found in wallet</h4>
            </Container>
            }   
            <LoadingSpinner loading={loading} />

            <Container className="mintContainers">
            <Row className="justify-content-md-center">
                { (cybbIds.length > 0 && (!contractState.publicSaleActive)) ?
                
                <Col xs lg="4">
                    {cybbImages? cybbImages.map(image => {
                        return (
                            <>
                            <img height="50px" width="50px" src={image}></img>
                            &emsp; -&gt; &emsp;
                            
                            {cybbAllowances? cybbAllowances[cybbImages.indexOf(image)]: '0'} keys
                            <br />

                            </>
                            
                        )
                    }): <></>}
                    {/* <h5>{cybbAllowances ? cybbAllowances.map(allowance => {
                        return `${allowance} &emsp;&emsp;`
                    }): <></>}</h5> */}
                </Col>
                :<></>}
                <Col xs lg="4">
                    <h5>
                        Price per key : 0.04 E
                        <br/>
                        Keys remaining : {contractState.maxSupply - contractState.claimedSupply}
                        <br/>
                        Max available : {contractState.maxSupply}
                        <br/>
                        Sale Status : {contractState.publicSaleActive? 'live - fcfs' : contractState.allowanceSaleActive? "live - allowance": contractState.claimedSupply >= 2500 ? "sold out" : "not live"}
                        <br/>
                    </h5>
                </Col>
            </Row>
                
            </Container>
        </div> 
    )
}

