import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { Chip, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthentication } from '../hooks';
import { ListItemIcon } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import Blockies from 'react-blockies';
import { useAuthState } from 'react-firebase-hooks/auth';

const pages = [
  {name: "mint", href: '/nftbot'},
  {name: "snipe", href: '/snipebot/'},
  // {name: "bidBot", href: '/bidbot'},
  // {name: "rarity", href: '/raritybot'},
]
const chromeStoreUrl= "https://chrome.google.com/webstore/detail/cyberbabiesio-osbot/bbmnlfgkacpdoegbchkjlodbfjcdakib"

const blockySeed = Date.now().toString()

const ResponsiveAppBar = () => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { signOut, auth, isAdmin, isBabyOwner, hasAccess } = useAuthentication();
  const [user, , ] = useAuthState(auth);
  const navigate = useNavigate()
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const location = useLocation()
  return (
    <AppBar position="static" sx={{background:"transparent"}}>
      <Container maxWidth="xl">
        <Toolbar>
          {/* <Box
            component={Link}
            to="/"
            key="/"
          >
            <Stack direction="row"  sx={{alignItems: 'center', justifyContent: 'center'}} >

            
            <img alt="" style={{marginRight: "5px"}}src="/baby-robot.png" height="35" className="d-inline-block align-center" />{' '}
            <Typography variant="h5" className="useFont" color="white">Cyberbabies IO</Typography>
            </Stack>
          </Box> */}
          {/* <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' , mr: -10}}>
            {pages.map((page) => (
              <Button
                component={Link}
                to={page.href}
                key={page.href}
                state={{comingFrom: location.pathname}}
                size="large"
                color="secondary"
                variant={location.pathname.includes(page.href) ? 'outlined' : "text"}
                sx={{ my: 2, mx:4, color: 'white', display: 'block', textTransform: 'none', fontSize: "1.2em" }}
              >
                {page.name}
              </Button>
            ))}
          </Box> */}
          <Stack direction="row" spacing={1} sx={{justifyContent:'center', justifyItems: 'center', flexGrow: 1}} >
            {isAdmin && <Chip sx={{alignSelf:"center"}} label="admin"/>}
            {isBabyOwner && <Chip sx={{alignSelf:"center"}} label="owner"/>}
            <IconButton component='a' href="https://discord.gg/NrWZsBAjqY" target="_blank" rel="noopener noreferrer">
                <i className="bi bi-discord"></i>
            </IconButton>
            <IconButton component='a' href="https://opensea.io/collection/cyberbabies" target="_blank" rel="noopener noreferrer"> 
                <img style={{marginBottom:"3px"}} alt="" className="bi" src="/opensea.svg" height="22px" width="22px"></img>
            </IconButton>
            <IconButton component='a' href="https://etherscan.io/address/0x991A546A167cEb2a6a7C344C9D85269Ac03035D9" target="_blank" rel="noopener noreferrer"> 
                <img style={{marginBottom:"3px"}} alt="" className="bi" src="/etherscan-logo-light-circle.svg" height="22px" width="22px"></img>
            </IconButton> 
            {/* <IconButton component='a' href={chromeStoreUrl} target="_blank" rel="noopener noreferrer"> 
                <i className="bi bi-browser-chrome"></i>
            </IconButton> */}
{/*             
            <Box sx={{ flexGrow: 0}}>
                <Tooltip title={user?.uid ?? 'not connected'}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar>
                    <Blockies 
                        seed={user?.uid ?? blockySeed }
                        size={10}
                        scale={5}
                        className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault"
                    />
                    </Avatar>
                </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: '45px', minWidth:"150px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                    >
                        {/* <MenuItem onClick={()=>{navigate("/profile");handleCloseUserMenu()}}> */}
                        {/* <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        <Typography textAlign="center">Profile</Typography>
                        </MenuItem> */}
                        {/* { user ?
                            <MenuItem onClick={()=>{signOut();navigate("/");handleCloseUserMenu()}}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                        :
                            <MenuItem onClick={()=>{navigate("/login");handleCloseUserMenu()}}>
                            <ListItemIcon>
                                <LoginIcon />
                            </ListItemIcon>
                            <Typography textAlign="center">Login</Typography>
                            </MenuItem>
                        } */}
                        
                  
                {/* </Menu> */}
            {/* </Box> */} 
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
