import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { BidBotApp } from '../bots';
import { useAlchemy } from '../hooks';
const CABI = require('../utilities/CyberBabies.json');

const cyberBabies  = {
    name : "Cyber Babies",
    address: "0x991A546A167cEb2a6a7C344C9D85269Ac03035D9",
}

export const BidBot = (props) => {
    const { alchemyWeb3: web3 } = useAlchemy();
    const randoNumber = Math.floor(Math.random() * (501 - 1) + 1);
    const randoBabyUrl = `/babies/baby${randoNumber}.png`
    const [account, setAccount] = useState("");
    const [allowed, setAllowed] = useState(false);
    const contract = new web3.eth.Contract(CABI.abi, cyberBabies.address);   
    const verifyOwnership = async () => {
        const response = await contract.methods.balanceOf(account).call();
        if (Number(response) === 0) {
            setAllowed(false);
        } else {
            setAllowed(true);
        }
    }
    useEffect(() => {
        const getCurrentWallet = async () => {
            try {
                const { ethereum } = window as any;
                const addressArray = await ethereum.request({ method: "eth_accounts"});
                if (addressArray.length > 0) {
                    setAccount(addressArray[0])
                }
            } catch (err) {
                console.log(err);
            }
        }
        getCurrentWallet();


    }, []);
    useEffect(() => {
        if (account) {
            verifyOwnership();
        }
    }, [account]);
    return (
    //   <div className="Page-centered">
    //   <Row className="align-items-center">
    //       <Container className="mintContainers">
    //       <h1>
    //           For members only
    //       </h1>
    //       </Container>
    //     <Container>
    //       <div className="">
    //         <img alt="" src={randoBabyUrl} width="20%" height="45%" className="App-logo-spin App-logo d-inline-block align-top" />
    //       </div>
    //     </Container>
    //   </Row>
    //   <BidBotInfo />
    // </div> 
        <>
        {!allowed ? 
            <>
                <Container className="mintContainers" style={{paddingTop:"5vh"}}>
                    <h1>
                        {allowed ? "Welcome to the club !" : "For members only"}
                    </h1>
                </Container>
                <BidBotInfo />
            </>
            
            :<>
            <BidBotApp account={account} web3={web3} keyringController={props.keyringController}/>
            {/* <p>maintenance</p> */}
            {/* <BidBotInfo /> */}
            </>
        }
        </>
    )

}

export const BidBotInfo = (props) => {
    return (
      <Container>
      <Row>
          <Col>
              <Container className="FeatureListContainer">
                  <h2 > 
                       Features
                  </h2>
                  <p>
                        Currently being rebuilt
                      {/* ~ Put in bids in bulk on OpenSea collections<br />
                      ~ Select tokens by specific traits, IDs, or ranks<br/>
                      ~ Option to use a private key to sign all bids at once <br/>
                      ~ Custom code to circumvent the API limits <br /> */}
                  </p>
              </Container>
          </Col>
          <Col>
          <Container className="FeatureListContainer">
                  <h2 > 
                       Info
                  </h2>
                  <p>
                      Thank you for your patience
                      {/* ~ Available for all baby holders !<br />
                      ~ Best way to enter a collection for under floor prices<br/>
                      ~ Avoid the stress of failed sniping  <br/> */}
                  </p>
              </Container>
          </Col>
      </Row>
  </Container>
    )
  }