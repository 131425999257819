import React from 'react';
import {Row, Container} from 'react-bootstrap';
import { BidBotInfo, NftBotInfo, OsBotInfo, RarityBotInfo } from '.';


export const Home = (props) => {
  const randoNumber = Math.floor(Math.random() * (501 - 1) + 1);
  const randoBabyUrl = `pendingBaby.png`
  return (
    <>

        <img alt="" src={randoBabyUrl} width="20%" height="45%" className="App-logo-spin App-logo d-inline-block align-top" />
    </>
  )
}